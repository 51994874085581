// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//$font-size: 1.4rem;




/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$body-text: 'Open Sans', Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$text-display: 'Libertad', Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$title-text: 'Libertad', Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$text-button: 'Libertad', Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;





/// Copy text color
/// @type Color
$text-color: rgb(92, 92, 92) !default;
$title-color: rgb(45,41,38) !default;
$lite-grey: #FBFBFD;

/// Main brand color
/// @type Color
$lite-blue: rgb(18, 131, 177) !default;
$bold-blue: rgb(8, 110, 151) !default;
$red-brand: rgb(228, 0, 43) !default;
$brand-color: rgb(228, 0, 43) !default;
$brand-color-bold: rgb(9, 193, 230) !default;
$accent-color: rgb(248,103,35) !default;
$accent-lite: rgb(241,131,62) !default;
$brand-lite: rgb(166,218,215) !default;
$brand-bg: rgb(255,255,255) !default;
$clean: rgb(255, 255, 255) !default;

/// Light grey
/// @type Color
$light-grey: rgb(250, 250, 250) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(224, 224, 224) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(51, 51, 51) !default;


/// typograph



/// Container's maximum width
/// @type Length
$max-width: 1170px !default;


$border-color: #e0e0e0;
$green : #00CC71;
$dark-brand : #2D2926;
