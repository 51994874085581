// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.top-cities-entry {
  .page-title {
    margin-top: 35px;
  }
  margin-top: 55px;
}

.top-detail {
  margin-top: 55px;
}

.side-link .mdi {
  margin-right: 4px;
}

.side-list {
  h3 {
    margin-top: 0;
    color: #333;
  }
  margin-bottom: 20px;
}

.inner-side h3 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.inner-title h3, h3, h2, h1, h4, h5 {
  color: #333;
}

.state-proportion h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
}

.smt-media-list {}

.smt-list-item {
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative;
}

.smt-list-entry {
  display: table-cell;
  vertical-align: middle;
  &.small {
    width: 25%;
  }
}

.side-by-side {
  display: block;
  &.for-half > .side-block {
    display: inline-block;
    width: 48%;
    vertical-align: top;
  }
}

.chart-icon i {
  font-size: 110px;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.state-proportion-text h4, .chart-icon i {
  color: #E4002B;
}

.state-proportion-text h4 {
  text-transform: capitalize;
  margin-top: 0;
}

.side-block h3 {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 20px;
  border-radius: 35px;
}

.line-divider {
  height: 1px;
  display: block;
  background: rgba(0, 0, 0, 0.1);
  margin: 5px 0;
}

.entry-has-table {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.ted-table {
  th {
    text-transform: uppercase;
    color: #333;
  }
  tr:nth-of-type(1) {
    background: #f9f9f9;
    border: none;
  }
  p {
    margin-bottom: 0;
  }
  em {
    font-style: normal;
    display: block;
  }
}

.republic {
  color: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: inline-block;
  text-align: center;
  line-height: 28px;
}

.democrat {
  color: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  background: #086E97;
}

.republic {
  background: #E4002B;
}

.text-has-icon {
  display: inline-block;
}

/* HONEPAGE style ----------------------------- */

.top-nav {
  border-top: 2px solid #E4002B;
}

.main-nav {
  .nav.navbar-nav {
    float: right;
  }
  .navbar-brand > img {
    height: 55px;
  }
  .navbar-nav > li {
    font-size: 16px;
    > a {
      padding: 0 20px;
      font-size: 1.6rem;

      display: block;


      &:hover, &:focus {
        background: rgba(0, 0, 0, 0.055);
        color: #086E97;
      }
    }
  }
}

.main-header .navbar-default .navbar-nav > .active > a {
  &:hover, &:focus {

    color: #086E97;
  }
}

.main-nav .navbar-nav > li > a:focus {
  outline: none;
}

/*.main-header .navbar-default .navbar-nav > .active > a {
  color: #E4002B;
  background: transparent;
}*/

.navbar-nav > a.active {
  color: #E4002B;
  background: transparent;
}



.search-container {
  background: $lite-grey;

}

.search-form {
  position: relative;
  padding: 8px 0;
  input[type="text"] {
    background: transparent;
    border-radius: 0;
    height: 48px;
    width: 88%;
    color: #222;
    border: 1px solid #f0f0f0;
    &:focus {
      background: rgba(255, 255, 255, 1);
      box-shadow: none;
      outline: none;
      color: #222;
      border: 1px solid $light-grey;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
  .btn.btn-default {
    background: transparent;
    border: 1px solid #E4002B;
    border-radius: 2px;
    position: absolute;
    top: 10px;
    right: 0;
    color: #E4002B;
    padding: 12px 30px;
    &:hover {
      background: #E4002B;
      color: #fff;
      border: 1px solid #E4002B;
    }
  }
}

.feature {
  height: 640px;
  background: #1283B1 url("../images/top-bg.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  /*background: #E4002B;
  background: -webkit-linear-gradient(to top, #E4002B , #CF0026);
  background: linear-gradient(to top, #E4002B , #CF0026); */
}

.main-tagline {
  display: flex;
  -ms-display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  height: 650px;
  max-width: 75%;
  margin: 0 auto;
}

.tagline-content {
  align-self: center;
  color: #fff;
  text-align: center;
  h1 {
    font-size: 60px;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 35px;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
    color: #f9f9f9;
    font-weight: 600;
    font-family: 'open sans', sans-serif;
    max-width: 85%;
    margin: 20px auto 35px;
  }
}

.left-tagline {
  display: flex;
  flex-flow: column wrap;
  height: 650px;
  max-width: 45%;
  align-content: center;
  justify-content: center;
}

.left-tag-content {
  align-self: center;
  h1 {
    color: #fff;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
    font-family: 'open sans', sans-serif;
    font-weight: normal;
    color: #e0e0e0;
  }
  .btn.btn-default {
    background: #E4002B;
    color: #fff;
    border-color: #E4002B;
    text-transform: capitalize;
    margin-top: 35px;
  }
}

.center-tagline {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.page-tagline {
  align-self: center;
}

.feature-content {
  display: flex;
  flex-flow: column wrap;
  align-content: stretch;
  justify-content: space-around;
  padding: 20px;

  text-align: center;
  &:hover {
    background: rgba(0,0,0,.035);
  }
}

.top-feature, .bottom-feature {
  align-self: center;
}

.feature-content i {
  font-size: 75px;
  color: #2D2926;
}

.bottom-feature {
  h3 {
    text-align: center;

    font-weight: bold;
    color: #333;
    margin: 20px 0;
    font-size: 18px;

  }

}

.insider {
  background: #1283B1;
}

.big-search {
  color: #fff;
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  input {
    height: 55px;
    border: 1px solid rgba(255, 255, 2555, 0.5);
    border-radius: 0;
    background: transparent;
    color: rgba(255, 255, 255, 1);
    &:focus {
      color: rgba(0, 0, 0, 0.8);
      background: rgba(255, 255, 255, 0.4);
    }
  }
  .btn.btn-primary {
    background: #064A67;
    color: #fff;
    text-transform: capitalize;
    border-radius: 3px;
    border: 1px solid #064A67;
    box-shadow: 0 2px 8px rgba(0,0,0,.2);
    padding: 10px 40px;
    margin-top: 10px;

    &:hover {
      background: $bold-blue;
      border-color: $bold-blue;
      box-shadow: 0 8px 18px rgba(0,0,0,.2);
    }
  }
}

.feat-insider {
  background: $lite-grey;
}

.two-side {
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative;
}

.side-element {
  display: table-cell;
  vertical-align: middle;
  &.half {
    width: 48%;
  }
}

.feat-short {
  margin: 55px auto 75px;
}

.feat-insider .nav-tabs {
  border: none;
  > li {
    &.active > a {
      border: none;
      background-color: transparent;
      &:hover, &:focus {
        border: none;
        background-color: transparent;
      }
    }
    > a {
      border: none;
      background-color: transparent;
      &:hover, &:focus {
        border: none;
        background-color: transparent;
      }
    }
  }
}

.graphic-content {
  padding: 30px;
}

.feat-insider .nav-tabs {
  li {
    min-width: 20%;
    text-align: center;
    font-weight: 600;
  }
  > li {
    > a {
      border: none;
      border-radius: 0;
      margin-right: 0;
      padding: 20px 10px;
      background: rgba(0, 0, 0, 0.025);
      color: rgba(0, 0, 0, 0.3);
      position: relative;
    }
    &.active > a {
      color: #E4002B;
    }
  }
}

.home-action {
  border: 1px solid #076C94;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.10);
  border-radius: 4px;
  padding: 20px;
  display: block;
  margin: 20px auto;
  background: $bold-blue;
  color: $clean;
}

.action-description {
  display: inline-block;
  font-size: 18px;
  line-height: 44px;
  color: $title-color;
  font-weight: 600;
}

.action-button {
  display: inline-block;
  float: right;
}

.action .btn.btn-default {
  background: #086E97;
  color: #fff;
  border-radius: 2px;
  line-height: 34px;
  float: right;
}

.action .btn.btn-default:hover {
  background: rgba(0,0,0,.2);
  color: $clean;
  border-color: rgba(0,0,0,.3);
}

.people-search-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.people-thumb {
  align-content: center;
  border: 2px solid transparent;
  width: 10%;
  position: relative;
  cursor: pointer;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  overflow: hidden;
  &:hover {
    border: 2px solid #086E97;
  }
}



.thumb-overlay {
  background: rgba(228, 0, 43, .95);
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;

  transform: translateY(100%);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;

  h3 {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: bold;
    text-align: center;
  }

  i {
    font-size: 36px;
    text-align: center;
    display: block;
    margin: 20px auto 0;
    color: #fff;
  }
}

.people-thumb:hover .thumb-overlay {
  display: block;
  transform: translateY(0);

}

.mid-section img {
  margin: 35px auto;
}


.news-title {
  position: relative;

  h3 {
    font-size: 18px;
    color: $title-color;
  }

  &:after {
    height: 4px;
    background: $red-brand;
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
  }

}

.author-thumb {
  border-radius: 50%;
  display: block;
  background: $clean;

  img {
    width: 60px;
    height: 60px;
    padding: 2px;
    float: left;
  }
}

.author p {
  margin-bottom: 0;
  text-transform: capitalize;
  padding-left: 70px;
}

.small-list {
  display: block;
  margin-bottom: 20px;

  .news-list {
    float: left;
    width: 47%;

    &:nth-of-type(1) {
      margin-right: 6%;
    }
  }

  h3 {
    font-size: 16px;
    margin: 0;
    font-weight: bold;
  }
}

.news-list {
  h3 {
    font-size: 16px;
    margin: 0;
    font-weight: bold;
  }
}


.last-profile {
  .center-tagline {
    margin-bottom: 0;
    background: $light-grey;
    padding: 30px 0;

    h3 {
      color: $red-brand;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }

    h4 {
      color: $text-color;
      font-weight: bold;
      max-width: 100%;
    }
  }
}



.pg-action {
  background: $bold-blue;
  color: #fff;
  position: relative;
  &:before {
    content: '';
    background: rgba(0,0,0,.025);
    height: 100%;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }
}

.bottom-offer,
.bottom-button {
  padding: 20px;
}

.bottom-button {
  h3 {
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .btn-outline {
    margin: 20px 0;
  }
}

.main-title {
  h3 {
    text-transform: capitalize;
    background: transparent;
    padding: 0;
    color: $red-brand;
    margin-bottom: 0;
  }
}

.side-block {
  .section-divider {
    margin: 30px 0;
  }
}

.bottom-section {
  background: $clean;
}

.feat-insider {
  padding-bottom: 30px;
}

.side-block {
  padding-top: 35px;
}

.latest-update {
  border-top: 1px solid #f3f3f3;
  .center-tagline {
    margin: 35px auto;
  }
}


.feat-insider .nav-tabs > li i {
  display: block;
  font-size: 36px;
  text-align: center;
  margin: 0 auto;
}

.feat-insider .nav-tabs > li {
  font-size: 14px;
}

.feat-description {
  h3 {
    color: $red-brand;
    text-transform: capitalize;
    font-weight: bold;
  }
}

.feat-short {
  .btn {
    &.btn-primary {
      background: $clean;
      border: 1px solid $bold-blue;
      color: $bold-blue;
      border-radius: 3px;
      text-align: center;
      text-transform: capitalize;
    }
    &:hover {
      background: $bold-blue;
      color: $clean;
      border-color: $bold-blue;
    }
  }
}



.main-nav .navbar-nav > li > a:hover,
.main-nav .navbar-nav > li > a:focus{
  background: transparent;
}

.bottom-feature h3 {
  margin: 20px 0 0;
}

.feat-insider .nav-tabs > li > a,
.feat-insider .nav-tabs > li.active {
  position: relative;
}

.feat-insider .nav-tabs > li.active > a:after {
  content:'';
  height: 2px;
  background: $red-brand;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.feat-short .btn.btn-primary {
  margin-right: 20px;
  min-width: 90px;
}

.main-title h4 {
  font-size: 16px;
  margin-bottom: 20px;
}


.center-tagline {
  h3 {
    letter-spacing: .2px;
    color:$red-brand;
    margin-bottom: 35px;
  }

  h4 {
   font-family: $body-text;
    font-weight: normal;
    color:#2D2926;
    font-size: 18px;
    max-width: 75%;
    margin: 0 auto;
    line-height: 1.3;
  }
}

.our-partners {
  background: #f3f3f3;
  padding: 10px;

  .item {
    height: 103px;
    text-align: center;
    img {
      margin: 0 auto;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}

.partner-title {
  background: #f3f3f3;
  border-bottom: 1px solid #e0e0e0;
  font-size: 2.4rem;
  padding: 20px 0;
  margin: 0;
  text-align: center;
}

.business-feature {
  margin: 35px auto;

  .btn.btn-primary {
    line-height: 36px;
    padding: 5px 30px;
    margin-top: 30px;
  }
}

.mid-section {
  .page-title {
    text-transform: capitalize;
  }
}

.center-tagline {
  h3 {
    margin-bottom: 20px;
  }
}

.feat-icon {
  display: inline-block;
  float: left;
  font-size: 18px;
  margin-right: 8px;
}

.feat-overview {
  background: $clean;
  padding: 20px;

}

.feat-insider .nav-tabs > li.active > a {
  background: $clean;

}

.graphic-content {
  padding: 0;
}

.side-block h3 {
  background: transparent;
  border-radius: 0;
}

.feat-box {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;

  &.third-of-one {
    .feat-box-item {
      width: 33.3%;
    }
  }

  &.half-of-one {
    .feat-box-item {
      width: 50%;
    }
  }

  .feat-box-item {
    display: table-cell;
    vertical-align: middle;
  }
}

.feat-entry {
  p {
    padding-left: 26px;
  }
}

.side-feat {
  border: 1px solid #f0f0f0;
  padding: 20px;
  position: relative;

  &:after {
    content:'';
    height: 4px;
    background: $red-brand;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &:before {
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: $red-brand transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 30px;
  }
  h3 {
    color: #2D2926;
    margin: 0 0 30px;
  }
}

.news-list {
  h3 {
    margin: 10px 0;
  }
}

.author {

  padding: 20px;
}

.overlay-show {
  .btn {
    &.btn-default {
      background: rgba(0, 0, 0, .3);
      color: $clean;
      border-color:  rgba(0, 0, 0, .4);

      &:hover {
        background: $clean;
        border-color: $clean;
      }
    }
  }
}

.news-list {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 2px 0 rgba(0,0,0,.12);
}

.short-description {
  background: $clean;
  padding: 0 20px;
}

.top-feature {
  margin-top: 20px;
}

.our-partners {
  padding: 20px 0;
}

.list-bordered {
  li {

    margin-bottom: 10px;
   &:last-child {
     border: none;
     margin-bottom: 0;
   }
  }
}

.center-tagline h4 {
  line-height: inherit;
  font-size: 16px;
}

#smtChart {
  margin: 0 auto;
  width: 250px !important;
  height: 250px !important;
}

.chart-holder {
  width: 250px;
  height: 250px;
}

.state-wide {
  p {
    margin-bottom: 0;
  }

}

.election-trend {
  margin-top: 20px;
}

.election-geo {
  background: #086E97;
  color: #fff;
  h3 {
    color: $clean;
  }

  h4 {
    color: $clean;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,.2);
    max-width: 100%;
  }

  .center-tagline {
    margin: 35px auto;
  }
}

.geo-content {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;
  max-width: 740px;
  margin: 0 auto 55px;



  .geo-list {
    display: table-cell;
    width: 33.3%;
    vertical-align: top;
  }
}

.press-content {
  background: rgba(0,0,0,.1);
  padding: 20px 0;
}


#mapDiv {
  height: 480px !important;
  overflow: hidden;
}
