// -----------------------------------------------------------------------------
// This file contains  basic styles.
// -----------------------------------------------------------------------------

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 0;
  margin: 0;
}

body {
    background: $brand-bg;
}

/* main wrap ----------------------- */
html, body {
    height: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
height: 100%;
}

.main-header,
.main-footer {
  flex: none;
}

.main-content {
  flex: 1 0 auto;
  width: 100%;
}
.main-content::after {
  content: '\00a0';
  display: block;
  height: 0;
  visibility: hidden;
}

.smt-list {
  display: flex;

  &.horizontal,
  &.quarter,
  &.third,
  &.half {
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: stretch;
  }

  &.half {
    .smt-half {
      width: 50%;
      margin: 0;
      align-self: stretch;

      &.has-border {
        border-left: 1px solid #f3f3f3;
        border-right: 1px solid #f3f3f3;
        padding: 0 20px;
      }
    }
  }

  &.third {
    .smt-third {
      width: 31.3%;
      margin: 0;
      align-self: stretch;

      &.has-border {
        border-left: 1px solid #f3f3f3;
        border-right: 1px solid #f3f3f3;
        padding: 0 20px;
      }
    }
  }

  &.quarter {
    .smt-quarter {
      width: 23%;
      margin: 1%;
      align-self: stretch;

      &.has-border {
        border-left: 1px solid #f3f3f3;
        border-right: 1px solid #f3f3f3;
        padding: 0 20px;
      }

    }
  }

  .smtlist-item {
    align-self: stretch;


    &.first-line {
      min-width: 10%;
    }

    &.second-line {
      min-width: 55%;
    }

  }

  .smd-item {
    align-self: center;
  }
}



.ted-table {
  margin: 1em 0;
  width: 100%;
}
.ted-table tr {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.ted-table th {
  display: none;
}
.ted-table td {
  display: block;
}
.ted-table td:first-child {
  padding-top: 10px;
}
.ted-table td:last-child {
  padding-bottom: 10px;
}
.ted-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block;
}
@media (min-width: 480px) {
  .ted-table td:before {
    display: none;
  }
}
.ted-table th, .ted-table td {
  text-align: left;
}
@media (min-width: 480px) {
  .ted-table th, .ted-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .ted-table th:first-child, .ted-table td:first-child {
    padding-left: 0;
  }
  .ted-table th:last-child, .ted-table td:last-child {
    padding-right: 0;
  }
}



.ted-table {
  overflow: hidden;
}
.ted-table tr {
  border-color: #f3f3f3;
}
.ted-table th, .ted-table td {
  margin: .5em 1em;
}
@media (min-width: 480px) {
  .ted-table th, .ted-table td {
    padding: 1em !important;
  }
}
.ted-table th, .ted-table td:before {
  color: $red-brand;
}




.btn.btn-default {
  background: $bold-blue;
  color: $clean;
  text-transform: capitalize;
  border-radius: 3px;

  &:hover {
    background: $lite-blue;
    border-color: $lite-blue;
  }

  &:focus {
    outline: none;
  }
}


.main-footer {
  background: $title-color;
  color: #f9f9f9;

  p {
    margin-bottom: 0;
    line-height: 35px;
  }
}





.center-tagline {
  margin: 35px auto 55px;
  text-transform: capitalize;


  h3 {
    font-size: 3.5rem;

    color: #333;
  }

  h4 {
    font-size: 24px;
    color: $text-color;
    font-weight: bold;
  }
}


.main-title {
  color: $title-color;

  h3 {
    font-size: 2.4rem;
    margin-top: 0;
    text-transform: capitalize;
    background: transparent;
  }

  h4 {
    font-size: 18px;
    font-family: $body-text;
    font-weight: normal;
  }


}

.section-divider {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background: #f0f0f0;

  &.clean {
    background: transparent;
  }


}

.tiled-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex: 1;
  align-items: stretch;
}

.tiled-items {
  align-self: stretch;
  width: 31.3%;
  margin: 1%;
}

.short-description {
  width: auto;
  height: 55px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin: 20px auto;
  position: relative;


}

.category-title {
  color: $brand-color;
  position: relative;

  h3 {
    color: $red-brand;
  }

  &:after {
    content:'';
    height: 4px;
    width: 50%;
    background: $red-brand;
    position: absolute;
    top: 50%;
    right: 0;
  }
}


.btn {
  &.btn-outline {
    border: 1px solid $clean;
    color: $clean;
    background: transparent;
    border-radius: 55px;
    text-transform: capitalize;
    font-family: $body-text;
    font-weight: normal;

    &:hover {
      background: $clean;
      color: $bold-blue;
      border-color: $bold-blue;
    }
  }
}

.scrollToTop {
  text-align: center;
  text-decoration: none;
  width: 34px;
  height: 34px;
  padding: 10px;
  text-align: center;
  background: rgba(228,0,43, .7);
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 48px;
  right: 20px;
  display: none;
  border-radius: 50%;
}

.scrollToTop:hover {
  text-decoration: none;
  background: rgbargba(45,41,38, .8);
  color: #fff;
}

.scrollToTop i {
  vertical-align: middle;
  display: block;
  line-height: 10px;
}


.home-overlay {
  position: relative;
  display: block;
  overflow: hidden;
  color: #fff;


}

.home-overlay img {
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    transform: scale(1, 1);
    position: relative;
}

.overlay-show {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(228,0,43, .95);
  color: #fff;
  transform: translateY(100%);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;

  h4 {
    color: #fff;
    line-height: 55px;
    margin: 0;
    background: rgba(228,0,43,.1);
    text-align: center;
    display: block;
    font-weight: 300;
    font-family: $body-text;
    letter-spacing: 1px;
  }
}


.home-overlay:hover {
  img {
    transform: scale(1.3, 1.3);
  }
}

.home-overlay:hover .overlay-show {
  transform: translateY(0);
}

.split-item {
  display: table-cell;
  vertical-align: top;
}



.split-form {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;
  margin: 15px 0;

  &.two-third {
    .split-item {
      width: 69%;

      &.short {
        width: 31%;
      }
    }






  }

  &.fifty-fifty {
    .split-item {
      width: 50%;
    }
  }

  &.third-width {
    .split-item {
      width: 29.3%;
    }
  }

  &.fourth-width {
    .split-item {
      width: 25%;
    }
  }
}

.split-haspadding {
  padding: 0 5%;
}

.dropdown-menu {
  box-shadow: 5px 5px rgba(102,102,102,.1);
  border-radius: 0;
  border: none;
  background: #fafafa;
  margin: 0;
  padding: 0;
}

.breadcrumb {
  li {
    display: inline-block;
    a {
      display: block;
    }
  }
}

.highlight-title {
  h4 {
    font-size: 16px;
    text-transform: uppercase;
    margin: 30px 0 0;
    font-weight: bold;
    color: $text-color;
  }

  h3 {
    color: $red-brand;
    font-size: 35px;
    color: #e4002b;
    margin-top: 4px;
    text-transform: uppercase;
  }

}



.circliful {
  .outer {
    fill: transparent;
    stroke: #333;
    stroke-width: 19.8;
    stroke-dasharray: 534;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    /* firefox bug fix - won't rotate at 90deg angles */
    -moz-transform: rotate(-89deg) translateX(-190px);
  }
  .inner {
    fill: transparent;
    stroke: orange;
    stroke-width: 20;
    stroke-dasharray: 534;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    /* firefox bug fix - won't rotate at 90deg angles */
    -moz-transform: rotate(-89deg) translateX(-190px);
    stroke-dashoffset: 0;
  }
  overflow: visible !important;
}

/* full circle 25 empty 534 */

.svg-container {
  width: 100%;
  margin: 0 auto;
  overflow: visible;
}

svg .icon {
  font-family: FontAwesome;
}

.box-center {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  flex: 1;

  .box-entry {
    align-self: center;
    text-align: center;

    h3 {
      margin: 35px auto 10px;
    }

    h4 {
      color: #e0e0e0;
      font-weight: 300;
      font-family: $body-text;
      margin-bottom: 20px;
    }

    .btn.btn-primary {
      margin: 0 auto 35px;
      background: rgba(0,0,0, .1);
      border-color: rgba(0,0,0, .15);
      border-radius: 2px;
      text-transform: capitalize;
      line-height: 36px;
      padding: 0 30px;

      &:hover {
        background: rgba(255,255,255, .95);
        color: $red-brand;
        border-color: rgba(0,0,0,.25);
      }
    }
  }
}


.more-padding {
  padding: 55px 0;
}

.feat-entry {
  h5 {
    text-transform: uppercase;
    letter-spacing: .2px;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.stats-legendz {
  background: transparent;
  border-radius: 2px;
  text-align: center;
  display: block;
  line-height: 37px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  border: 1px solid #e0e0e0;
}

.canvasjs-chart-credit {
  display: none !important;
}

.has-border {
  border-right: 1px solid #f0f0f0;
}

.list-content {

    margin-bottom: 20px;

  h4 {
    margin-bottom: 0;
    font-weight: bold;
  }
}

.state-wide {
  .section-divider {
    margin: 0 auto;
  }
}

.form-divider {
  display: block;
  margin: 15px 0;
}

.dual-box {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;

  .box-left,
  .box-right {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
  }
}

.personal-badge {
  background: rgba(0,0,0,.2);
  padding: 0 10px;
  padding: 5px 15px;
  display: inline-block;

  i {
   vertical-align: middle;
    margin-right: 6px;
  }

  h5 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
  }
}


.content-description {
  overflow: hidden;
  position: relative;

  &.medium-text {
    &.smt-more {
      max-height: 140px;
    }
  }

  &.small-text {
    &.smt-more {
      max-height: 80px;
    }
  }

  .btn.btn-more {
    color: $bold-blue;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    padding: 10px 20px;
    margin: 0;
    right: 0;

    &:hover {
      color: $red-brand;
      outline: none;
    }

    &:focus {
      outline: none;
    }
  }

}

.smt-more {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.5);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(0%, rgba(255,255,255,0.8)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
    width: 100%;
    text-align: right;
    padding: 10px;
    color: $bold-blue;
    height: 44px;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}

.btn-less {
  display: none;
  background: transparent;
  border-radius: none;
  color: $bold-blue;
  padding: 0;
  text-transform: uppercase;
}


.smt-info {
  background: #f5f5f5;
  padding: 15px;
  position: relative;

  &:after {
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #f5f5f5 transparent;
    position: absolute;
    left: 15px;
    top: -8px;
  }
}

.btn {
  &.btn-map {
    display: block;
    background: rgba(0, 0, 0, .7);
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    line-height: 34px;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    z-index: 1;

    &:hover {
      background: rgba(0, 0, 0, 1);
      color: $red-brand;
    }
  }
}

.btn-map2 {
  background: rgba(0,0,0,.1);
  color: $clean;
  border-color: rgba(0,0,0,.1);

  &:hover {
    color: #333;
    background: rgba(255,255,255,.3);
  }
}
