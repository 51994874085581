// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
/* FONTFACE -------------------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans.eot');
  src: local('Open Sans'), local('OpenSans'),
  url('../fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans.woff2') format('woff2'),
  url('../fonts/OpenSans.woff') format('woff'),
  url('../fonts/OpenSans.ttf') format('truetype'),
  url('../fonts/OpenSans.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Semibold.eot');
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Semibold.woff2') format('woff2'),
  url('../fonts/OpenSans-Semibold.woff') format('woff'),
  url('../fonts/OpenSans-Semibold.ttf') format('truetype'),
  url('../fonts/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Light.woff2') format('woff2'),
  url('../fonts/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans-Light.ttf') format('truetype'),
  url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Libertad';
  src: url('../fonts/Libertad-ExtraBold.eot');
  src: local('Libertad ExtraBold'), local('Libertad-ExtraBold'),
  url('../fonts/Libertad-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Libertad-ExtraBold.woff2') format('woff2'),
  url('../fonts/Libertad-ExtraBold.woff') format('woff'),
  url('../fonts/Libertad-ExtraBold.ttf') format('truetype'),
  url('../fonts/Libertad-ExtraBold.svg#Libertad-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Libertad';
  src: url('../fonts/Libertad-Bold.eot');
  src: local('Libertad Bold'), local('Libertad-Bold'),
  url('../fonts/Libertad-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Libertad-Bold.woff2') format('woff2'),
  url('../fonts/Libertad-Bold.woff') format('woff'),
  url('../fonts/Libertad-Bold.ttf') format('truetype'),
  url('../fonts/Libertad-Bold.svg#Libertad-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: "picon";
  src: url("../fonts/pgicon.eot");
  src: url("../fonts/pgicon.eot?#iefix") format("embedded-opentype"),
  url("../fonts/pgicon.woff") format("woff"),
  url("../fonts/pgicon.ttf") format("truetype"),
  url("../fonts/pgicon.svg#picon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "picon";
    src: url("../fonts/pgicon.svg#picon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "picon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: "pgicon";
      src: url("../fonts/pgicon.svg#picon") format("svg");
    }
  }

  [class^="picon-"]:before, [class*=" picon-"]:before,
  [class^="picon-"]:after, [class*=" picon-"]:after {
    font-family: picon;
    font-size: inherit;
    font-style: normal;
  }

  .picon-add:before { content: "\f100"; }
  .picon-add-circular-button:before { content: "\f101"; }
  .picon-arrows:before { content: "\f102"; }
  .picon-arrows-1:before { content: "\f103"; }
  .picon-arrows-2:before { content: "\f104"; }
  .picon-back:before { content: "\f105"; }
  .picon-bar-chart:before { content: "\f106"; }
  .picon-bar-chart-1:before { content: "\f107"; }
  .picon-business:before { content: "\f108"; }
  .picon-business-1:before { content: "\f109"; }
  .picon-capitol:before { content: "\f10a"; }
  .picon-capitol-1:before { content: "\f10b"; }
  .picon-city-hall-major:before { content: "\f10c"; }
  .picon-cityscape:before { content: "\f10d"; }
  .picon-connection:before { content: "\f10e"; }
  .picon-correct:before { content: "\f10f"; }
  .picon-democrat-party:before { content: "\f110"; }
  .picon-donkey-americal-political-symbol-of-democrats:before { content: "\f111"; }
  .picon-download:before { content: "\f112"; }
  .picon-educational-graphic:before { content: "\f113"; }
  .picon-elephant-republican-symbol:before { content: "\f114"; }
  .picon-graphic:before { content: "\f115"; }
  .picon-graphic-1:before { content: "\f116"; }
  .picon-graphic-2:before { content: "\f117"; }
  .picon-graphic-3:before { content: "\f118"; }
  .picon-hotel:before { content: "\f119"; }
  .picon-interface:before { content: "\f11a"; }
  .picon-list:before { content: "\f11b"; }
  .picon-menu:before { content: "\f11c"; }
  .picon-microphone:before { content: "\f11d"; }
  .picon-minus:before { content: "\f11e"; }
  .picon-minus-sign-in-a-circle:before { content: "\f11f"; }
  .picon-navigation:before { content: "\f120"; }
  .picon-newspaper:before { content: "\f121"; }
  .picon-next:before { content: "\f122"; }
  .picon-office-block:before { content: "\f123"; }
  .picon-people:before { content: "\f124"; }
  .picon-people-1:before { content: "\f125"; }
  .picon-people-2:before { content: "\f126"; }
  .picon-people-3:before { content: "\f127"; }
  .picon-play-button:before { content: "\f128"; }
  .picon-reload:before { content: "\f129"; }
  .picon-republican-party:before { content: "\f12a"; }
  .picon-right-arrow:before { content: "\f12b"; }
  .picon-search:before { content: "\f12c"; }
  .picon-send:before { content: "\f12d"; }
  .picon-social:before { content: "\f12e"; }
  .picon-success:before { content: "\f12f"; }
  .picon-technology:before { content: "\f130"; }
  .picon-tool:before { content: "\f131"; }
  .picon-tool-1:before { content: "\f132"; }
  .picon-tool-2:before { content: "\f133"; }
  .picon-vote-badge-for-political-elections:before { content: "\f134"; }
  .picon-voting-day:before { content: "\f135"; }
  .picon-woman-in-politics:before { content: "\f136"; }
