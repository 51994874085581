// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.cities-inner {
  background: $title-color;
  color: #f9f9f9;
  text-transform: capitalize;
}

.top-cities-entry {
  text-transform: capitalize;

  a {
    color: #f9f9f9;
  }

  h3 {
    color: $clean;
    text-transform: uppercase;
    margin-top: 0;
  }

  ul {
    text-transform: uppercase;
  }

}

.top-country {
  li {
    display: inline-block;
    border-right: 1px solid #e0e0e0;
    padding-right: 10px;

    &:last-child {
      border-right: none;
      padding-left: 10px;
    }

    a {
      display: block;
    }
  }
}

.count-date {
  text-transform: capitalize;
  background: rgba(0,0,0,.1);
  display: block;
  padding: 10px;
  margin: 10px 0;

  li {
    border-right: none;
    display: inline-block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    sup {
      text-transform: uppercase;
      font-size: 10px;

    }

    p {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}

.top-social {
  font-size: 24px;
  margin-bottom: 10px;
  display: block;
}

.top-detail {
  margin-top: 28px;
  a {
    color: #fff;

    &:hover {
      color: $red-brand;
    }
  }
}

.data-list {
  h6 {
    font-family: $body-text;
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 15px;
    color: $red-brand;
    font-weight: bold;
    margin: 0;
    float: left;
    max-width: 33%;

  }

  h6,
  p {

  }

  p {
    font-weight: normal;
    margin-bottom: 0;
    padding-left: 33%;

  }
}

.country-map {
  padding: 20px;
  position: relative;
}

.top-country {
  margin: 20px 0;
}

.city-official {
  margin: 10px 0 55px;

  .smt-list {
    .smd-item {
      width: 22%;
      margin: 1%;
      text-align: center;
      background: #fafafa;
      box-shadow: none;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

      &:hover {
        background: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }
    }
  }

}

.city-person {
  display: block;
  text-transform: capitalize;
  position: relative;

  &.has-blue {
    img {
      border: 2px solid $bold-blue;
    }
  }

  &.has-red {
    img {
      border: 2px solid $red-brand;
    }
  }

  .person-badge {
    position: absolute;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: #fff;
    right: -15px;
    bottom: -15px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 35px;
  }

  h4 {
    text-transform: uppercase;
    color: $title-color;
  }

  a {
    display: block;
  }

}

.has-blue {
  background: $bold-blue;
}

.has-red {
  background: $red-brand;
}

.person-detail {
  text-align: center;
  text-transform: capitalize;
  padding-bottom: 20px;

  h4 {
    text-transform: uppercase;
    height: 48px;
    overflow: hidden;
  }

  a {
    display: block;
  }
}

.city-person {
  &.has-blue {
    .person-badge {
      background: $bold-blue;
    }
  }

  &.has-red {
    .person-badge {
      background: $red-brand;
    }
  }
}

.bottom-stats {
  border-top: 1px solid #e0e0e0;
}

.inner-title {

  h3 {
    color: $brand-color;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 24px;
  }

  h5 {
    font-family: $body-text;
    font-weight: normal;
    text-transform: capitalize;
    margin-top: 0;
  }

}

.bottom-tabs {
  background: #fafafa;
}

.icon-list {
  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    margin-right: 5px;
  }

  p {
    display: inline-block;
  }
}

.bottom-tabs {
  .nav-tabs > li > a {
    font-size: 14px;
    font-family: $title-text;
    text-transform: uppercase;
    padding: 14px 30px;
  }

  .tab-entry {
    padding: 20px;
    background: $clean;
    border-bottom: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.10);
  }

}

.side-list {
  &.clean {
    li {
      border: none;
    }
  }
}

.bottom-tabs {
  .nav-tabs {
    margin: 35px auto 0;
  }
}

.note-section {
  margin: 35px auto 55px;
  h3 {
    margin-bottom: 30px;
  }
}

.dropdown-menu > li > a {
  color: $bold-blue !important;
  padding: 12px !important;
  &:hover {
    background: $clean;
    color: $text-color;
  }
}


// login page
.login-page {
  &.fit-content {

    background: #FBFBFD;

    .login-bottom {
      background: rgba(0,0,0,.025);
      padding: 15px;

      h3 {

        font-weight: normal;
        letter-spacing: .5px;
      }
    }

    .login-content {
      width: 100%;
      background: transparent;
    }

    .login-form,
    .user-choose {
      width: 26%;
    }

    .login-left, .login-right {
      width: 49%;
    }

    .login-left a:hover,
    .login-right a:hover {
      background: $bold-blue;
      color: white;
      border-color: $bold-blue;
    }


  }
}

// cities page
.cities-inner {
  background: rgba(254, 0, 47, 1);
  background: -moz-linear-gradient(top, rgba(254, 0, 47, 1) 0%, rgba(227, 0, 45, 1) 44%, rgba(228, 0, 42, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254, 0, 47, 1)), color-stop(44%, rgba(227, 0, 45, 1)), color-stop(100%, rgba(228, 0, 42, 1)));
  background: -webkit-linear-gradient(top, rgba(254, 0, 47, 1) 0%, rgba(227, 0, 45, 1) 44%, rgba(228, 0, 42, 1) 100%);
  background: -o-linear-gradient(top, rgba(254, 0, 47, 1) 0%, rgba(227, 0, 45, 1) 44%, rgba(228, 0, 42, 1) 100%);
  background: -ms-linear-gradient(top, rgba(254, 0, 47, 1) 0%, rgba(227, 0, 45, 1) 44%, rgba(228, 0, 42, 1) 100%);
  background: linear-gradient(to bottom, rgba(254, 0, 47, 1) 0%, rgba(227, 0, 45, 1) 44%, rgba(228, 0, 42, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe002f', endColorstr='#e4002a', GradientType=0 );
  padding: 20px 0;
  h3 {
    margin: 0;
  }
}

.inner-title.non-subtitle h3 {
  line-height: 55px;
}

.display.dataTable {
  font-size: inherit;
}

.table-head i {
  display: inline-block;
  vertical-align: middle;
}

.feed-grid {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  margin: 10px 0;
}

.feed-item {
  align-self: stretch;
  width: 31.3%;
  margin: 1%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  &:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12), 0px 1px 6px rgba(0, 0, 0, 0.12);
    border: 1px solid transparent;
  }
  img {
    margin-bottom: 10px;
  }
}

.top-feed h3 {
  font-size: 18px;
  line-height: initial;
  font-weight: bold;
  display: block;
  margin: 0;
}

.feed-date {
  font-size: 12px;
  color: #808080;
  margin-bottom: 10px;
  i {
    display: inline-block;
    vertical-align: middle;
  }
}

.feed-text p {
  display: -webkit-box;
  max-width: 100%;
  height: 285px;
  margin: 0 auto;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.act-feed {
  .right-inner {
    margin: 20px auto;
  }
  .side-media {
    padding: 0 15px;
    p {
      margin-bottom: 0;
    }
  }
  .media-listing li {
    margin: 0 0 15px 0;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 15px;
    &:last-child {
      border-bottom: none;
    }
  }
}


// features
.top-inner {
  background: #fafafa;
  padding: 70px 0 0;
  height: 115px;

  .breadcrumb {
    background: transparent;
    margin: 0;
    padding: 8px 0;
  }

  .btn.btn-red {
    float: right;
  }

}

.content-title {
  text-align: center;
  position: relative;

  h1{
    margin-top: 35px;
    color: $red-brand;
  }

  h3 {
    color: $dark-brand;
    font-family: $body-text;
    font-weight: normal;
    text-transform: capitalize;
    max-width: 65%;
    margin: 0 auto;
    position: relative;

    &:after {
      background: $red-brand;
      content: '';
      height: 4px;
      width: 15%;
      bottom: 0;
      left: 0;
      right: 0;
      bottom: -90px;
      position: absolute;
      top: 0;
      margin: auto;
    }
  }

}

.red-block {
  background: $red-brand;
  color: $clean;
}

.first-section {
  margin-top: 90px;
}

.first-section {
   background: url("../../images/desktop-preview.jpg") no-repeat top left;
   display: block;
   height: 610px;
 }

.third-section {
  background: url("../../images/app-preview2.jpg") no-repeat top left;
  display: block;
  height: 610px;
}

.second-section {
  background: url("../../images/app-preview.jpg") no-repeat top right;
  display: block;
  height: 461px;
}

.fourth-section {
  background: #fafafa;
}

.bottom-inner {
  background: $red-brand;
  color: #fff;
  position: relative;
  position: relative;

  h3 {
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 1.2;
    text-transform: capitalize;
    font-weight: bold;

    span {
      font-weight: normal;
    }

  }
}

.big-title {
  .btn.btn-primary {
    line-height: 34px;
    padding: 0 15px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0 2px 2px;
  }

  input[type=text],
  input[type=email] {
    position: relative;
    height: 36px;
    border-radius: 2px;
    background: rgba(255, 255, 255, .85);

    &:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: none;
    }

    &:focus {
      outline: none;
      background: rgba(255 ,255 , 255 , 1);
      box-shadow: none;
    }
  }
}

.price-option {
  text-align: center;
  padding: 10px 0 35px;

  h4 {
    text-transform: uppercase;
    font-weight: bold;
    color: #333;
    margin-top: 0;
  }

  .price-description {
    text-transform: capitalize;
  }


  .smtlist-item {
    width: 23%;
    margin: 1%;
    background: #fafafa;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 2px 0 rgba(0,0,0,.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    cursor: pointer;

    &:hover {
      background: $clean;
      box-shadow: 0 8px 16px rgba(0,0,0,0.15), 0 4px 4px rgba(0,0,0,0.12);
    }
  }
}

.price-box {
  position: relative;
  padding: 20px;
  transition: background, box-shadow 0.3s cubic-bezier(.25,.8,.25,1);

  .btn.btn-primary {
    margin: 20px 0;
    text-transform: capitalize;
    border-color: $bold-blue;
    color: $clean;

    &:hover {
      background: $bold-blue;
      color: $bold-blue;
    }
  }

  i {
    font-size: 55px;
    color: inherit;
    line-height: 90px;
  }

  .plan-checked {
    display: none;
  }

  .mark-icon {
    border-radius: 50%;
    border: 2px solid $red-brand;
    color: $red-brand;
    width: 90px;
    height: 90px;
    margin: 0 auto 20px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }

  &.has-picked {
    background: rgba(0, 0, 0, 0.27);
    box-shadow: none;
    height: 100%;

    .btn.btn-primary {
      display: none;

    }

    &:hover {
      box-shadow: none;
    }

    .mark-icon {
      background: $green;
      color: $clean;
      border-color: rgba(255, 255, 255, .7);

      > i {
        color: $clean;
      }

      .packed-icon {
        display: none;
      }

      .plan-checked {
        display: block;
      }

    }


  }

}

.price-number {
  font-size: 28px;
  line-height: 85px;

  sup {
    font-size: 55%;
    top: -10px;
    left: 6px;
  }

}


.feature-plan {
  background: #f9f9f9;
  padding: 0 0 35px;
}

.plan-description {
  background: $clean;
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 2px 0 rgba(0,0,0,.12);

  .smtlist-item {
    min-height: 48px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
  }

  .smt-list {
    cursor: pointer;

    &:nth-of-type(2n+2) {
      background: #fafafa;
      border-top: 1px solid #f5f5f5;
      border-bottom: 1px solid #f5f5f5;
      box-shadow: none;
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }

    &:hover {
      background: $clean;
      color: $bold-blue;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.022), 0px 1px 2px rgba(0, 0, 0, 0.1);
    }

  }

}

// contact

.contact-form {
  margin: 35px auto 90px;
}

.map-container {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 480px;
}

.office-box {
  background: #fafafa;
  padding: 20px;
  border-radius: 2px;
  border: 1px solid #f3f3f3;

  i {
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}



.bottom.inner {
  height: 480px;
}

.middle-inner {
  .smt-list {
    max-width: 75%;
    margin: 90px auto 35px;
  }

  .panel-title {
    font-size: 14px;
    font-weight: bold;
  }

  .panel-default > .panel-heading {
    color: $lite-blue;
    background-color: #fff;
    border-color: #f3f3f3;
    border-radius: 2px;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    box-shadow: none;

    &:hover {
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 2px 0 rgba(0,0,0,.12);
      color: $bold-blue;
    }
  }

  .panel-default {
    border-color: #f9f9f9;
  }

  .collapse.in {
    background: #f9f9f9;
  }

  .smt-quarter {
    text-align: center;

    h3 {
      color: #086e97;
    }

    .box-border {
      margin: 0 auto;
      text-align: center;
    }


  }
}

.middle-inner {
  .smt-quarter {
    text-align: center;
  }
}

.box-icon i {
  font-size: 55px;
  text-align: center;
  display: block;
}

.faq-section {
  margin-bottom: 90px;
}

.help-icon {
   i {
     font-size: 55px;
     text-align: center;
     display: block;
     color: #ccc;
     margin-top: 35px;
   }

  h1 {
    margin-top: 0;
  }

}

.help-index {
  h6 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &.smt-list {
    margin: 1%;
    background: #fafafa;
    border: 1px solid transparent;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 2px 0 rgba(0,0,0,.12);

    &:hover {
      background: $clean;
      border: 1px solid #f3f3f3;
      box-shadow: none;
    }
  }
}

.middle-inner .help-index .smt-list {
  margin: 1%;
  max-width: 100%;
}

.help-index .smt-quarter {
  text-align: center;
}

// about page
.top-overview {
  h3,
  figure {
    margin-top: 30px;
  }
}

.company-point {
  background: $bold-blue;
  color: #fff;
}

.grid-custom .smt-third {
  margin: 0;
}

.custom-line {
  h2,
  h3 {
    color: $clean;
    text-align: center;
  }

  h3 {
    font-weight: normal;
    letter-spacing: .5px;
    margin-bottom: 35px;
  }

  h2 {
    font-size: 37px;
    font-weight: bold;
    margin: 35px auto 10px;
  }

  .smt-list {
    max-width: 100%;
    margin: 20px auto;
  }
}

.company-misson {
  background: #15a9e6;
  position: relative;
  color: #fff;

  .smt-list {
    max-width: 100%;
    margin: 0 auto;

    .smt-half {

    }
  }
}

.company-misson {
  h3 {
    text-align: center;
    color: #fff;
    max-width: 84%;
    font-weight: bold;
    letter-spacing: .2px;
    font-size: 24px;
    text-transform: capitalize;
    margin: 30px auto 35px;
    line-height: 1.3;
  }

  img {
    margin: 0 auto 35px;
  }

  .awward {
    h3 {
      font-size: 28px;
      letter-spacing: .5px;
    }
    display: block;
    border: 1px solid rgba(0,0,0,.1);
    padding: 20px;
    background: rgba(0,0,0,.045);
    margin: 30px auto 0;
    color: #333;
  }
}


// dahsboard update
.bottom-stats {
  .ted-table th, .ted-table td {
    padding: 6px !important;
  }

  .ted-table th {
    color: #fff;
  }


  .ted-table tr {
    background: #f9f9f9;

    &:nth-of-type(1) {
      background: $red-brand;
      color: #fff;
    }
  }

}

.pie-legend {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pie-legend span {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  margin-right: 16px;
  margin-bottom: -2px;
}
.pie-legend li {
  margin-bottom: 10px;
}

.cities-inner {
  padding: 60px 0 0;
}

.smt-form {
  margin-bottom:  30px;
}

.pg-front {
  margin-bottom: 55px;
}



.people-detail {
  padding: 35px 0;

  .person-badge {
    text-align: center;
    color: #fff;
    right: -10px;
    bottom: -10px;
  }
}

.people-detail img {
  width: 100%;
}

.people-description {
  h3,
  h4,
  h5 {
    color: $clean;
  }

  h3 {
    margin: 10px 0 0;
  }

  h4,
  h5 {
    font-weight: bold;
  }

}

.people-highlight {
  border: 1px solid rgba(0,0,0,.15);
  padding: 10px 0;
  margin: 0 0 10px;
}

.people-ext {
  background: rgba(0,0,0,.1);
  border-radius: 4px;
  padding: 20px;

  a {
    color: $clean;
  }

  li {
    display: block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.left-description,
.right-description {
  display: inline-block;
}

.left-description {
  text-transform: uppercase;
  margin-right: 10px;
  min-width: 70px;

}

.right-description {
  text-transform: capitalize;
}

.social-list {
  li {
    display: inline-block;
    margin-right: 0;
  }
}

.people-text {
  padding: 20px 0;
}

.people-related {
  border-left: 1px solid #f3f3f3;
  display: block;
  padding: 0 20px;
  margin: 0 15px;
}

.title-has-icon {
  margin-bottom: 20px;
  h3 {
    margin: 0 0 5px 0;
    display: inline-block;
    color: $red-brand;
  }

  .btn.btn-default {
    background: #f9f9f9;
    border: 1px solid #f0f0f0;
    border-radius: 35px;
    padding:0 15px;
    margin: 0;
    float: right;
    color: $bold-blue;
    font-size: 14px;

    &:hover {
      background: $bold-blue;
      color: $clean;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }
}


.horizontal-info {
  .city-person {
    width: 28%;
    float: left;
    display: inline-block;
    margin-right: 15px;
  }

  .person-badge {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    right: -10px;
    bottom: -5px;
    line-height: 25px;
    text-align: center;
  }
}

.person-badge {
  text-align: center;
}

.info-text {
  p + p {
    text-indent: 0;
  }
}

.top-cities-entry {
  margin-top: 0;
}

.detail-city {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;


  .top-detail,
  .country-map {
    min-width: 50%;
    align-self: stretch;
  }

  .country-map {
    position: relative;
    padding: 0;
  }

}

.data-list h6,
.detail-city h6 {
  color: #fff;
}

.detail-city {
  &.block {
    display: block;
  }
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  line-height: 35px;
  padding: 0 10px;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  bottom: 0;
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 0 10px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0 4px;
}
.dataTables_paginate.paging_simple_numbers .pagination,
div.dataTables_wrapper div.dataTables_info {
  font-size: 12px;
}

div.dataTables_wrapper div.dataTables_info {
  margin-top: 0;
}

.dashboard-content {
  margin-bottom: 55px;
}


.top-cities-entry a:hover,
.top-detail a:hover {
  color: #2D2926;
}

.city-official .smd-item {
  align-self: flex-start;
}

.tab-entry {
  .side-block {
    padding-top: 0;
  }
}

.title-has-icon i {
  line-height: 28px;
}

.infeature-content .fourth-section {
  margin-bottom: 30px;
}

.plan-description {
  padding: 0;
}

.infeature-content .fourth-section {
  padding-bottom: 30px;
  margin-bottom: 0;
}

.price-box .btn.btn-primary:hover {
  color: #fff;
}
