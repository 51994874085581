// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.inner-side {
  background: #fafafa;
  padding: 20px;
  margin: 25px 0 55px;

  h3 {
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0,0,0,.3);
    margin-bottom: 0;
    display: block;
    padding-bottom: 10px;
  }

}

.side-link {
  h4 {
    font-family: $body-text;
    font-weight: 600;
    font-size: 14px;
    margin-top: 0;
    color: $title-color;
    span {
      font-family: $body-text;
      font-weight: normal;
    }
  }

  .mdi {
    margin-right: 10px;
  }


}

.side-list {
  li {
    border-bottom: 1px solid rgba(0,0,0,.025);
    padding-bottom: 10px;
    padding-top: 10px;

    &:last-child {
      padding-top: 0;
      border-bottom: none;
    }
  }
}

.side-box h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: $title-color;
  margin-top: 0;
}

.number-box {
  font-size: 34px;
  padding: 15px;
  display: block;
}

.right-box {
  margin-bottom: 15px;
  background: $lite-grey;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 2px 0 rgba(0,0,0,.12);
  h3 {
    padding: 10px 20px;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 0;
    color: $red-brand;
    font-size: 18px;
    background: rgba(0,0,0,.015);
    display: block;
    position: relative;
  }
}

.side-updated {
  li {
    display: block;
    border-bottom: 1px solid #f3f3f3;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;

    &:hover {
      background: $clean;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
.people-place {
  padding: 10px 15px;
  display: block;
  p {
    display: block;
    margin-bottom: 10px;
  }

  i {
    font-size: inherit;
    display: inline-block;
    vertical-align: middle;
  }



}

.people-place p + p {
  text-indent: inherit;
  margin-bottom: 0;
}
