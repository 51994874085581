// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.main-footer {

  p {
    line-height: 18px;
    font-size: 12px;

  }

  a {
    color: #999;

    &:hover {
      color: $lite-blue;
    }
  }

  h3 {
    color: $clean;
    font-weight: normal;
    letter-spacing: .5px;
    margin: 20px 0 10px;
    font-size: 18px;

  }

  .footer-entry {
    margin-bottom: 30px;
  }


}

.copyright {
  background: rgba(0,0,0,.085);


  p {
    line-height: 35px;
    text-align: center;
    margin-bottom: 0;
  }
}
