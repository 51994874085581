// $Dropcap
// -------------------------------------//

.typl8-drop-cap {
  @include typl8-dropcap(
    $typl8-dropcap-float-position,
    $typl8-dropcap-font-size,
    $typl8-dropcap-font-family,
    $typl8-dropcap-txt-indent,
    $typl8-dropcap-margin,
    $typl8-dropcap-padding,
    $typl8-dropcap-color,
    $typl8-dropcap-line-height,
    $typl8-dropcap-bg
  );
}


p + .typl8-drop-cap {
  text-indent: 0;
  margin-top: 0;
}




.typl8-lining {
  @include typl8-definition-list-style(lining);
}


.typl8-dictionary-style {
  @include typl8-definition-list-style(dictionary-style);
}




.typl8-pull-quote {
  @include typl8-pull-quotes(
    $typl8-pull-quote-fontsize,
    $typl8-pull-quote-opacity,
    $typl8-pull-quote-color
  );
}


