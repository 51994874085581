

@function typl8-context-calc($scale, $base, $value) {
  @return ($scale/$base) + $value;
}




@function typl8-measure-margin($scale, $measure, $value) {
  $pixelValue: $scale/$measure;
  $remValue: $pixelValue * $typl8-font-base;

  @if $value == rem {
    @return $pixelValue + $value;
  } @else if $value == em {
    @return ($remValue/$scale) + $value;
  } @else {
    @return $remValue * 1px;
  }
}
