@charset "UTF-8";
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), local("Open Sans"), local("Open Sans"), local("Open Sans");
  unicode-range: U+0026; }

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans");
  unicode-range: U+270C; }

.typl8-tera, .typl8-giga, .typl8-mega, h1, .typl8-alpha, h2, .typl8-beta, h3, .typl8-gamma, h4, .typl8-delta, h5, .typl8-epsilon, .typl8-zeta, h6 {
  text-rendering: optimizeLegibility;
  line-height: 1;
  margin-top: 0;
  color: #333; }

blockquote + figcaption cite {
  display: block;
  font-size: inherit;
  text-align: right; }

body {
  word-wrap: break-word; }

pre code {
  word-wrap: normal; }

html {
  font: normal 112.5%/1.65 serif; }

body {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #5c5c5c; }

.typl8-tera {
  font-size: 7.42857em;
  margin-bottom: 4.50216rem; }

.typl8-giga {
  font-size: 5.71429em;
  margin-bottom: 3.4632rem; }

.typl8-mega, h1 {
  font-size: 4.57143em;
  margin-bottom: 2.77056rem; }

.typl8-alpha, h2 {
  font-size: 3.80952em;
  margin-bottom: 2.3088rem; }

.typl8-beta, h3 {
  font-size: 3.04762em;
  margin-bottom: 1.84704rem; }

.typl8-gamma, h4 {
  font-size: 2.28571em;
  margin-bottom: 1.38528rem; }

.typl8-delta, h5 {
  font-size: 1.52381em;
  margin-bottom: 0.92352rem; }

.typl8-epsilon {
  font-size: 1.33333em;
  margin-bottom: 0.80808rem; }

.typl8-zeta, h6 {
  font-size: 1.14286em;
  margin-bottom: 0.69264rem; }

p {
  margin: auto auto 1.5rem; }
  p + p {
    text-indent: 1.5rem;
    margin-top: -1.5rem; }

small {
  font-size: 65%; }

input,
abbr,
acronym,
blockquote,
code,
kbd,
q,
samp,
var {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

pre {
  white-space: pre; }
  pre code {
    white-space: -moz-pre-wrap;
    white-space: pre-wrap; }

code {
  white-space: pre;
  font-family: monospace; }

abbr {
  -webkit-font-variant: small-caps;
  -moz-font-variant: small-caps;
  -ms-font-variant: small-caps;
  font-variant: small-caps;
  font-weight: 600;
  text-transform: lowercase;
  color: gray; }
  abbr[title]:hover {
    cursor: help; }

.typl8-drop-cap:first-letter {
  float: left;
  margin: 10px 10px 0 0;
  padding: 0 20px;
  font-size: 4em;
  font-family: inherit;
  line-height: 1;
  text-indent: 0;
  background: transparent;
  color: inherit; }

p + .typl8-drop-cap {
  text-indent: 0;
  margin-top: 0; }

.typl8-lining dt,
.typl8-lining dd {
  display: inline;
  margin: 0; }

.typl8-lining dt + dt:before,
.typl8-lining dd + dt:before {
  content: "\A";
  white-space: pre; }

.typl8-lining dd + dd:before {
  content: ", "; }

.typl8-lining dd:before {
  content: ": ";
  margin-left: -0.2rem; }

.typl8-dictionary-style dt {
  display: inline;
  counter-reset: definitions; }
  .typl8-dictionary-style dt + dt:before {
    content: ", ";
    margin-left: -0.2rem; }

.typl8-dictionary-style dd {
  display: block;
  counter-increment: definitions; }
  .typl8-dictionary-style dd:before {
    content: counter(definitions, decimal) ". "; }

.typl8-pull-quote {
  position: relative;
  padding: 1em; }
  .typl8-pull-quote:before, .typl8-pull-quote:after {
    height: 1em;
    opacity: 0.5;
    position: absolute;
    font-size: 4em;
    color: #dc976e; }
  .typl8-pull-quote:before {
    content: '“';
    top: 0;
    left: 0; }
  .typl8-pull-quote:after {
    content: '”';
    bottom: 0;
    right: 0; }

/* FONTFACE -------------------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans.eot");
  src: local("Open Sans"), local("OpenSans"), url("../fonts/OpenSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans.woff2") format("woff2"), url("../fonts/OpenSans.woff") format("woff"), url("../fonts/OpenSans.ttf") format("truetype"), url("../fonts/OpenSans.svg#OpenSans") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Semibold.eot");
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("../fonts/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Semibold.woff2") format("woff2"), url("../fonts/OpenSans-Semibold.woff") format("woff"), url("../fonts/OpenSans-Semibold.ttf") format("truetype"), url("../fonts/OpenSans-Semibold.svg#OpenSans-Semibold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Light.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.woff2") format("woff2"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype"), url("../fonts/OpenSans-Light.svg#OpenSans-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libertad';
  src: url("../fonts/Libertad-ExtraBold.eot");
  src: local("Libertad ExtraBold"), local("Libertad-ExtraBold"), url("../fonts/Libertad-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Libertad-ExtraBold.woff2") format("woff2"), url("../fonts/Libertad-ExtraBold.woff") format("woff"), url("../fonts/Libertad-ExtraBold.ttf") format("truetype"), url("../fonts/Libertad-ExtraBold.svg#Libertad-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Libertad';
  src: url("../fonts/Libertad-Bold.eot");
  src: local("Libertad Bold"), local("Libertad-Bold"), url("../fonts/Libertad-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Libertad-Bold.woff2") format("woff2"), url("../fonts/Libertad-Bold.woff") format("woff"), url("../fonts/Libertad-Bold.ttf") format("truetype"), url("../fonts/Libertad-Bold.svg#Libertad-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "picon";
  src: url("../fonts/pgicon.eot");
  src: url("../fonts/pgicon.eot?#iefix") format("embedded-opentype"), url("../fonts/pgicon.woff") format("woff"), url("../fonts/pgicon.ttf") format("truetype"), url("../fonts/pgicon.svg#picon") format("svg");
  font-weight: normal;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "picon";
    src: url("../fonts/pgicon.svg#picon") format("svg"); } }

.fi:before {
  display: inline-block;
  font-family: "picon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "pgicon";
    src: url("../fonts/pgicon.svg#picon") format("svg"); } }

[class^="picon-"]:before, [class*=" picon-"]:before,
[class^="picon-"]:after, [class*=" picon-"]:after {
  font-family: picon;
  font-size: inherit;
  font-style: normal; }

.picon-add:before {
  content: "\f100"; }

.picon-add-circular-button:before {
  content: "\f101"; }

.picon-arrows:before {
  content: "\f102"; }

.picon-arrows-1:before {
  content: "\f103"; }

.picon-arrows-2:before {
  content: "\f104"; }

.picon-back:before {
  content: "\f105"; }

.picon-bar-chart:before {
  content: "\f106"; }

.picon-bar-chart-1:before {
  content: "\f107"; }

.picon-business:before {
  content: "\f108"; }

.picon-business-1:before {
  content: "\f109"; }

.picon-capitol:before {
  content: "\f10a"; }

.picon-capitol-1:before {
  content: "\f10b"; }

.picon-city-hall-major:before {
  content: "\f10c"; }

.picon-cityscape:before {
  content: "\f10d"; }

.picon-connection:before {
  content: "\f10e"; }

.picon-correct:before {
  content: "\f10f"; }

.picon-democrat-party:before {
  content: "\f110"; }

.picon-donkey-americal-political-symbol-of-democrats:before {
  content: "\f111"; }

.picon-download:before {
  content: "\f112"; }

.picon-educational-graphic:before {
  content: "\f113"; }

.picon-elephant-republican-symbol:before {
  content: "\f114"; }

.picon-graphic:before {
  content: "\f115"; }

.picon-graphic-1:before {
  content: "\f116"; }

.picon-graphic-2:before {
  content: "\f117"; }

.picon-graphic-3:before {
  content: "\f118"; }

.picon-hotel:before {
  content: "\f119"; }

.picon-interface:before {
  content: "\f11a"; }

.picon-list:before {
  content: "\f11b"; }

.picon-menu:before {
  content: "\f11c"; }

.picon-microphone:before {
  content: "\f11d"; }

.picon-minus:before {
  content: "\f11e"; }

.picon-minus-sign-in-a-circle:before {
  content: "\f11f"; }

.picon-navigation:before {
  content: "\f120"; }

.picon-newspaper:before {
  content: "\f121"; }

.picon-next:before {
  content: "\f122"; }

.picon-office-block:before {
  content: "\f123"; }

.picon-people:before {
  content: "\f124"; }

.picon-people-1:before {
  content: "\f125"; }

.picon-people-2:before {
  content: "\f126"; }

.picon-people-3:before {
  content: "\f127"; }

.picon-play-button:before {
  content: "\f128"; }

.picon-reload:before {
  content: "\f129"; }

.picon-republican-party:before {
  content: "\f12a"; }

.picon-right-arrow:before {
  content: "\f12b"; }

.picon-search:before {
  content: "\f12c"; }

.picon-send:before {
  content: "\f12d"; }

.picon-social:before {
  content: "\f12e"; }

.picon-success:before {
  content: "\f12f"; }

.picon-technology:before {
  content: "\f130"; }

.picon-tool:before {
  content: "\f131"; }

.picon-tool-1:before {
  content: "\f132"; }

.picon-tool-2:before {
  content: "\f133"; }

.picon-vote-badge-for-political-elections:before {
  content: "\f134"; }

.picon-voting-day:before {
  content: "\f135"; }

.picon-woman-in-politics:before {
  content: "\f136"; }

/**
 * Basic typography style for copy text
 */
html {
  text-rendering: optimizeLegibility;
  font-size: 62.5%; }

body {
  font: normal 1.4rem/24px "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #5c5c5c; }

p strong {
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 600; }

a {
  color: #086e97; }

a:hover,
a:focus {
  color: #1283b1; }

a:focus,
.btn:focus,
button:focus {
  outline: none; }

a,
a:hover,
a:focus {
  text-decoration: none; }

a,
.btn,
.btn-default {
  -webkit-transition-property: color, background;
  -webkit-transition-duration: .1s, .1s;
  -webkit-transition-timing-function: linear, ease-in;
  -moz-transition-property: color, background;
  -moz-transition-duration: .1s, .1s;
  -moz-transition-timing-function: linear, ease-in;
  transition-property: color, background;
  transition-duration: .1s, .1s;
  transition-timing-function: linear, ease-in; }

.page-title {
  color: #2d2926;
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif; }

.big-display {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 11.2rem;
  line-height: 11.2rem; }

.medium-display {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 5.6rem;
  line-height: 5.6rem; }

.display {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 4.5rem;
  line-height: 4.8rem; }

.headline,
h1 {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 3.4rem;
  line-height: 4rem; }

.title,
h2 {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  line-height: 3.2rem; }

.sub-title,
h3 {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 2rem;
  line-height: 2.8rem; }

h4 {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem; }

h5 {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 2.4rem; }

h6 {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.8rem; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: inherit; }

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span,
.h1 span,
.h2 span,
.h3 span,
.h4 span,
.h5 span,
.h6 span {
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: inherit; }

input,
button,
select,
textarea,
input[type=password],
input[type=text],
input[type=date],
input[type=email],
input[type=number] {
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: inherit;
  line-height: inherit; }

input,
button,
select,
textarea,
body {
  -webkit-font-smoothing: antialiased; }

input,
select,
textarea {
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300; }

html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

ul,
ol {
  padding-left: 0;
  margin: 0; }

body {
  background: white; }

/* main wrap ----------------------- */
html, body {
  height: 100%; }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%; }

.main-header,
.main-footer {
  flex: none; }

.main-content {
  flex: 1 0 auto;
  width: 100%; }

.main-content::after {
  content: '\00a0';
  display: block;
  height: 0;
  visibility: hidden; }

.smt-list {
  display: flex; }
  .smt-list.horizontal, .smt-list.quarter, .smt-list.third, .smt-list.half {
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: stretch; }
  .smt-list.half .smt-half {
    width: 50%;
    margin: 0;
    align-self: stretch; }
    .smt-list.half .smt-half.has-border {
      border-left: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
      padding: 0 20px; }
  .smt-list.third .smt-third {
    width: 31.3%;
    margin: 0;
    align-self: stretch; }
    .smt-list.third .smt-third.has-border {
      border-left: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
      padding: 0 20px; }
  .smt-list.quarter .smt-quarter {
    width: 23%;
    margin: 1%;
    align-self: stretch; }
    .smt-list.quarter .smt-quarter.has-border {
      border-left: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
      padding: 0 20px; }
  .smt-list .smtlist-item {
    align-self: stretch; }
    .smt-list .smtlist-item.first-line {
      min-width: 10%; }
    .smt-list .smtlist-item.second-line {
      min-width: 55%; }
  .smt-list .smd-item {
    align-self: center; }

.ted-table {
  margin: 1em 0;
  width: 100%; }

.ted-table tr {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0; }

.ted-table th {
  display: none; }

.ted-table td {
  display: block; }

.ted-table td:first-child {
  padding-top: 10px; }

.ted-table td:last-child {
  padding-bottom: 10px; }

.ted-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block; }

@media (min-width: 480px) {
  .ted-table td:before {
    display: none; } }

.ted-table th, .ted-table td {
  text-align: left; }

@media (min-width: 480px) {
  .ted-table th, .ted-table td {
    display: table-cell;
    padding: .25em .5em; }
  .ted-table th:first-child, .ted-table td:first-child {
    padding-left: 0; }
  .ted-table th:last-child, .ted-table td:last-child {
    padding-right: 0; } }

.ted-table {
  overflow: hidden; }

.ted-table tr {
  border-color: #f3f3f3; }

.ted-table th, .ted-table td {
  margin: .5em 1em; }

@media (min-width: 480px) {
  .ted-table th, .ted-table td {
    padding: 1em !important; } }

.ted-table th, .ted-table td:before {
  color: #e4002b; }

.btn.btn-default {
  background: #086e97;
  color: white;
  text-transform: capitalize;
  border-radius: 3px; }
  .btn.btn-default:hover {
    background: #1283b1;
    border-color: #1283b1; }
  .btn.btn-default:focus {
    outline: none; }

.main-footer {
  background: #2d2926;
  color: #f9f9f9; }
  .main-footer p {
    margin-bottom: 0;
    line-height: 35px; }

.center-tagline {
  margin: 35px auto 55px;
  text-transform: capitalize; }
  .center-tagline h3 {
    font-size: 3.5rem;
    color: #333; }
  .center-tagline h4 {
    font-size: 24px;
    color: #5c5c5c;
    font-weight: bold; }

.main-title {
  color: #2d2926; }
  .main-title h3 {
    font-size: 2.4rem;
    margin-top: 0;
    text-transform: capitalize;
    background: transparent; }
  .main-title h4 {
    font-size: 18px;
    font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal; }

.section-divider {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background: #f0f0f0; }
  .section-divider.clean {
    background: transparent; }

.tiled-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex: 1;
  align-items: stretch; }

.tiled-items {
  align-self: stretch;
  width: 31.3%;
  margin: 1%; }

.short-description {
  width: auto;
  height: 55px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin: 20px auto;
  position: relative; }

.category-title {
  color: #e4002b;
  position: relative; }
  .category-title h3 {
    color: #e4002b; }
  .category-title:after {
    content: '';
    height: 4px;
    width: 50%;
    background: #e4002b;
    position: absolute;
    top: 50%;
    right: 0; }

.btn.btn-outline {
  border: 1px solid white;
  color: white;
  background: transparent;
  border-radius: 55px;
  text-transform: capitalize;
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal; }
  .btn.btn-outline:hover {
    background: white;
    color: #086e97;
    border-color: #086e97; }

.scrollToTop {
  text-align: center;
  text-decoration: none;
  width: 34px;
  height: 34px;
  padding: 10px;
  text-align: center;
  background: rgba(228, 0, 43, 0.7);
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 48px;
  right: 20px;
  display: none;
  border-radius: 50%; }

.scrollToTop:hover {
  text-decoration: none;
  background: rgbargba(45, 41, 38, 0.8);
  color: #fff; }

.scrollToTop i {
  vertical-align: middle;
  display: block;
  line-height: 10px; }

.home-overlay {
  position: relative;
  display: block;
  overflow: hidden;
  color: #fff; }

.home-overlay img {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  transform: scale(1, 1);
  position: relative; }

.overlay-show {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(228, 0, 43, 0.95);
  color: #fff;
  transform: translateY(100%);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }
  .overlay-show h4 {
    color: #fff;
    line-height: 55px;
    margin: 0;
    background: rgba(228, 0, 43, 0.1);
    text-align: center;
    display: block;
    font-weight: 300;
    font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
    letter-spacing: 1px; }

.home-overlay:hover img {
  transform: scale(1.3, 1.3); }

.home-overlay:hover .overlay-show {
  transform: translateY(0); }

.split-item {
  display: table-cell;
  vertical-align: top; }

.split-form {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;
  margin: 15px 0; }
  .split-form.two-third .split-item {
    width: 69%; }
    .split-form.two-third .split-item.short {
      width: 31%; }
  .split-form.fifty-fifty .split-item {
    width: 50%; }
  .split-form.third-width .split-item {
    width: 29.3%; }
  .split-form.fourth-width .split-item {
    width: 25%; }

.split-haspadding {
  padding: 0 5%; }

.dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border-radius: 0;
  border: none;
  background: #fafafa;
  margin: 0;
  padding: 0; }

.breadcrumb li {
  display: inline-block; }
  .breadcrumb li a {
    display: block; }

.highlight-title h4 {
  font-size: 16px;
  text-transform: uppercase;
  margin: 30px 0 0;
  font-weight: bold;
  color: #5c5c5c; }

.highlight-title h3 {
  color: #e4002b;
  font-size: 35px;
  color: #e4002b;
  margin-top: 4px;
  text-transform: uppercase; }

.circliful {
  overflow: visible !important; }
  .circliful .outer {
    fill: transparent;
    stroke: #333;
    stroke-width: 19.8;
    stroke-dasharray: 534;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    /* firefox bug fix - won't rotate at 90deg angles */
    -moz-transform: rotate(-89deg) translateX(-190px); }
  .circliful .inner {
    fill: transparent;
    stroke: orange;
    stroke-width: 20;
    stroke-dasharray: 534;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    /* firefox bug fix - won't rotate at 90deg angles */
    -moz-transform: rotate(-89deg) translateX(-190px);
    stroke-dashoffset: 0; }

/* full circle 25 empty 534 */
.svg-container {
  width: 100%;
  margin: 0 auto;
  overflow: visible; }

svg .icon {
  font-family: FontAwesome; }

.box-center {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  flex: 1; }
  .box-center .box-entry {
    align-self: center;
    text-align: center; }
    .box-center .box-entry h3 {
      margin: 35px auto 10px; }
    .box-center .box-entry h4 {
      color: #e0e0e0;
      font-weight: 300;
      font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
      margin-bottom: 20px; }
    .box-center .box-entry .btn.btn-primary {
      margin: 0 auto 35px;
      background: rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      text-transform: capitalize;
      line-height: 36px;
      padding: 0 30px; }
      .box-center .box-entry .btn.btn-primary:hover {
        background: rgba(255, 255, 255, 0.95);
        color: #e4002b;
        border-color: rgba(0, 0, 0, 0.25); }

.more-padding {
  padding: 55px 0; }

.feat-entry h5 {
  text-transform: uppercase;
  letter-spacing: .2px;
  font-weight: bold;
  margin-bottom: 0; }

.stats-legendz {
  background: transparent;
  border-radius: 2px;
  text-align: center;
  display: block;
  line-height: 37px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  border: 1px solid #e0e0e0; }

.canvasjs-chart-credit {
  display: none !important; }

.has-border {
  border-right: 1px solid #f0f0f0; }

.list-content {
  margin-bottom: 20px; }
  .list-content h4 {
    margin-bottom: 0;
    font-weight: bold; }

.state-wide .section-divider {
  margin: 0 auto; }

.form-divider {
  display: block;
  margin: 15px 0; }

.dual-box {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%; }
  .dual-box .box-left,
  .dual-box .box-right {
    display: table-cell;
    width: 50%;
    vertical-align: middle; }

.personal-badge {
  background: rgba(0, 0, 0, 0.2);
  padding: 0 10px;
  padding: 5px 15px;
  display: inline-block; }
  .personal-badge i {
    vertical-align: middle;
    margin-right: 6px; }
  .personal-badge h5 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    color: #fff; }

.content-description {
  overflow: hidden;
  position: relative; }
  .content-description.medium-text.smt-more {
    max-height: 140px; }
  .content-description.small-text.smt-more {
    max-height: 80px; }
  .content-description .btn.btn-more {
    color: #086e97;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    padding: 10px 20px;
    margin: 0;
    right: 0; }
    .content-description .btn.btn-more:hover {
      color: #e4002b;
      outline: none; }
    .content-description .btn.btn-more:focus {
      outline: none; }

.smt-more {
  overflow: hidden;
  position: relative; }
  .smt-more:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.8) 0%, white 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(0%, rgba(255, 255, 255, 0.8)), color-stop(100%, white));
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.8) 0%, white 100%);
    background: -o-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.8) 0%, white 100%);
    background: -ms-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.8) 0%, white 100%);
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.8) 0%, white 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
    width: 100%;
    text-align: right;
    padding: 10px;
    color: #086e97;
    height: 44px; }
  .smt-more:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

.btn-less {
  display: none;
  background: transparent;
  border-radius: none;
  color: #086e97;
  padding: 0;
  text-transform: uppercase; }

.smt-info {
  background: #f5f5f5;
  padding: 15px;
  position: relative; }
  .smt-info:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #f5f5f5 transparent;
    position: absolute;
    left: 15px;
    top: -8px; }

.btn.btn-map {
  display: block;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  line-height: 34px;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  z-index: 1; }
  .btn.btn-map:hover {
    background: black;
    color: #e4002b; }

.btn-map2 {
  background: rgba(0, 0, 0, 0.1);
  color: white;
  border-color: rgba(0, 0, 0, 0.1); }
  .btn-map2:hover {
    color: #333;
    background: rgba(255, 255, 255, 0.3); }

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table; }

.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap; }

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

.no-padding {
  padding: 0 !important; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.hidden {
  display: none !important; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.fit {
  max-width: 100%; }

.half-width {
  width: 50%; }

.full-width {
  width: 100%; }

.full-height {
  height: 100%; }

.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static; }

.pin-top-right {
  top: 0;
  right: 0; }

.pin-bottom-right {
  bottom: 0;
  right: 0; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.align-middle {
  vertical-align: middle; }

.hide-tablet,
.hide-mobile {
  display: block; }

.show-tablet,
.show-mobile {
  display: none; }

.show-mobile {
  display: none; }

.ell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.break-word {
  word-wrap: break-word; }

.no-wrap {
  white-space: nowrap; }

.scroll-h {
  overflow-x: auto; }

.scroll-v {
  overflow-y: auto; }

.z1 {
  z-index: 1; }

.z10 {
  z-index: 10; }

.z100 {
  z-index: 100; }

.hidden {
  display: none; }

.micro {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0; }

*[hidden] {
  display: none; }

.text-divider {
  display: block; }

.side-box .box-content {
  background: #e4002b;
  color: white;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .side-box .box-content:hover {
    background: white;
    color: #e4002b;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.12); }

.cities-inner.hidden {
  visibility: hidden;
  padding: 0; }

.slimScrollBar {
  z-index: 1 !important; }

.feed-item img {
  display: block;
  min-height: 170px; }

.main-header {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3; }
  .main-header .navbar-brand {
    height: auto;
    padding: 0; }
  .main-header .navbar-default .navbar-nav > .active > a,
  .main-header .navbar-default .navbar-nav > .active > a:hover,
  .main-header .navbar-default .navbar-nav > .active > a:focus {
    color: #e4002b; }
  .main-header .nav.navbar-nav {
    float: right; }
    .main-header .nav.navbar-navli > a {
      padding: 10px 15px; }
  .main-header .navbar-nav > li > a {
    padding: 5px 15px; }
  .main-header .navbar-default {
    background: transparent;
    border: none;
    border-radius: 0;
    margin-bottom: 0; }

.navbar-nav a.active {
  color: #e4002b; }

.main-nav .navbar-nav a.active {
  background: #e4002b;
  color: #fff;
  position: relative; }

.main-nav .navbar-nav > li > a.active {
  color: #fff; }

.top-nav {
  background: transparent;
  line-height: 28px; }
  .top-nav .btn-group {
    text-align: right;
    float: right;
    background: #e4002b;
    color: #fff; }
    .top-nav .btn-group a {
      color: #fff;
      padding: 4px 10px;
      cursor: pointer; }
      .top-nav .btn-group a:hover {
        color: white; }
    .top-nav .btn-group .dropdown {
      display: inline-block; }
      .top-nav .btn-group .dropdown button {
        font-weight: normal;
        background: transparent;
        border: none;
        color: white;
        box-shadow: none; }

.main-nav {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #ccc;
  background: white;
  z-index: 1;
  transition: transform 0.2s ease-in-out; }

.main-header.smt-stick .navbar-nav a {
  padding: 0 20px;
  line-height: 64px; }
  .main-header.smt-stick .navbar-nav a:hover {
    background: rgba(0, 0, 0, 0.025);
    color: #e4002b; }

.main-header .form-control {
  box-shadow: none; }

.search-container {
  background: #f9f9f9; }

.scrollToTop i {
  color: white; }

.main-nav .navbar-nav > li > a.active {
  color: white; }

.main-nav.smt-stick .navbar-nav > li > a.active:hover {
  color: #333;
  background: #f9f9f9; }

.main-nav .navbar-nav > li > a {
  padding: 0 20px; }

.main-nav .has-icon i {
  font-size: 28px; }

.has-icon i {
  font-size: 28px; }

.main-nav .navbar-nav a {
  padding: 0 20px;
  line-height: 60px; }

a.has-icon,
.main-nav a.has-icon {
  line-height: 55px;
  padding: 0 20px; }

.main-nav .navbar-nav > li > a {
  padding: 0 20px; }

.btn-account {
  background: transparent;
  border-radius: 0;
  padding: 0 20px;
  line-height: 55px;
  font-size: 24px;
  box-shadow: none;
  outline: none;
  border: none; }

.main-nav .dropdown-menu {
  margin: 6px 0 0; }
  .main-nav .dropdown-menu a {
    line-height: inherit; }

.cities-inner .form-title {
  padding: 10px 0; }
  .cities-inner .form-title h3 {
    font-size: 24px; }

.inner-side {
  background: #fafafa;
  padding: 20px;
  margin: 25px 0 55px; }
  .inner-side h3 {
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 0;
    display: block;
    padding-bottom: 10px; }

.side-link h4 {
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0;
  color: #2d2926; }
  .side-link h4 span {
    font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal; }

.side-link .mdi {
  margin-right: 10px; }

.side-list li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.025);
  padding-bottom: 10px;
  padding-top: 10px; }
  .side-list li:last-child {
    padding-top: 0;
    border-bottom: none; }

.side-box h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #2d2926;
  margin-top: 0; }

.number-box {
  font-size: 34px;
  padding: 15px;
  display: block; }

.right-box {
  margin-bottom: 15px;
  background: #FBFBFD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12); }
  .right-box h3 {
    padding: 10px 20px;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 0;
    color: #e4002b;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.015);
    display: block;
    position: relative; }

.side-updated li {
  display: block;
  border-bottom: 1px solid #f3f3f3;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }
  .side-updated li:hover {
    background: white; }
  .side-updated li:last-child {
    border-bottom: none; }

.people-place {
  padding: 10px 15px;
  display: block; }
  .people-place p {
    display: block;
    margin-bottom: 10px; }
  .people-place i {
    font-size: inherit;
    display: inline-block;
    vertical-align: middle; }

.people-place p + p {
  text-indent: inherit;
  margin-bottom: 0; }

.main-footer p {
  line-height: 18px;
  font-size: 12px; }

.main-footer a {
  color: #999; }
  .main-footer a:hover {
    color: #1283b1; }

.main-footer h3 {
  color: white;
  font-weight: normal;
  letter-spacing: .5px;
  margin: 20px 0 10px;
  font-size: 18px; }

.main-footer .footer-entry {
  margin-bottom: 30px; }

.copyright {
  background: rgba(0, 0, 0, 0.085); }
  .copyright p {
    line-height: 35px;
    text-align: center;
    margin-bottom: 0; }

.btn.btn-outline {
  background: transparent;
  box-shadow: none;
  border-radius: 55px;
  text-transform: capitalize; }
  .btn.btn-outline.white {
    border: 1px solid #fff;
    color: white; }
    .btn.btn-outline.white:hover {
      background: white;
      color: #2d2926; }
  .btn.btn-outline.red {
    border: 1px solid #e4002b;
    color: #e4002b; }
    .btn.btn-outline.red:hover {
      background: #e4002b;
      color: white; }
  .btn.btn-outline.blue {
    border: 1px solid #086e97;
    color: #086e97; }
    .btn.btn-outline.blue:hover {
      background: #1283b1;
      color: white; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

@-webkit-keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: none;
    transform: none; } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39063deg) skewY(0.39063deg);
    transform: skewX(0.39063deg) skewY(0.39063deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39063deg) skewY(0.39063deg);
    transform: skewX(0.39063deg) skewY(0.39063deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

.tagline-content .btn.btn-outline {
  line-height: 56px;
  margin: 0;
  padding: 0 35px;
  font-weight: bold;
  background: rgba(228, 0, 43, 0.7);
  border-color: rgba(228, 0, 43, 0.9); }
  .tagline-content .btn.btn-outline:hover {
    background: #e4002b;
    border-color: #e4002b;
    color: white; }

.btn.btn-red {
  background: #e4002b;
  color: white;
  text-transform: capitalize;
  text-align: center;
  padding: 0;
  line-height: 33px;
  padding: 0 15px;
  border-radius: 2px;
  margin: 0 auto;
  border-color: #e4002b; }
  .btn.btn-red:hover {
    background: white;
    color: #e4002b;
    border-color: #e4002b; }

.top-inner .btn.btn-red {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-transform: capitalize;
  text-align: center;
  padding: 0;
  line-height: 33px;
  padding: 0 15px;
  border-radius: 2px;
  margin: 0 auto;
  border-color: rgba(0, 0, 0, 0.1); }
  .top-inner .btn.btn-red:hover {
    background: white;
    color: #e4002b; }

.price-box .btn.btn-primary:hover {
  color: white; }

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
   * Header and footer styles
   */
  /*
   * Body styles
   */
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  table.dataTable thead th, table.dataTable tfoot th {
    font-weight: bold; }
  table.dataTable thead th, table.dataTable thead td {
    background: #2D2926;
    padding: 10px;
    border-bottom: 1px solid #121212;
    color: #fff;
    font-weight: normal;
    letter-spacing: .5px; }
  table.dataTable thead th:active, table.dataTable thead td:active {
    outline: none; }
  table.dataTable tfoot th, table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #f0f0f0; }
  table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
    cursor: pointer;
    *cursor: hand; }
  table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
    background-repeat: no-repeat;
    background-position: center right; }
  table.dataTable tbody tr {
    background-color: #ffffff; }
    table.dataTable tbody tr.selected {
      background-color: #f9f9f9; }
  table.dataTable tbody th, table.dataTable tbody td {
    padding: 8px 10px; }
  table.dataTable.row-border tbody th, table.dataTable.row-border tbody td {
    border-top: 1px solid #f3f3f3; }
  table.dataTable.display tbody th, table.dataTable.display tbody td {
    border-top: 1px solid #f3f3f3; }
  table.dataTable.row-border tbody tr:first-child th, table.dataTable.row-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.display tbody tr:first-child th, table.dataTable.display tbody tr:first-child td {
    border-top: none; }
  table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
    border-top: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3; }
  table.dataTable.cell-border tbody tr th:first-child, table.dataTable.cell-border tbody tr td:first-child {
    border-left: 1px solid #f3f3f3; }
  table.dataTable.cell-border tbody tr:first-child th, table.dataTable.cell-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9; }
  table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
    background-color: #e0e0e0; }
  table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
    background-color: #f5f5f5; }
  table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
    background-color: #e0e0e0; }
  table.dataTable.order-column tbody tr > .sorting_1, table.dataTable.order-column tbody tr > .sorting_2, table.dataTable.order-column tbody tr > .sorting_3 {
    background-color: #fafafa; }
  table.dataTable.display tbody tr > .sorting_1, table.dataTable.display tbody tr > .sorting_2, table.dataTable.display tbody tr > .sorting_3 {
    background-color: #fafafa; }
  table.dataTable.order-column tbody tr.selected > .sorting_1, table.dataTable.order-column tbody tr.selected > .sorting_2, table.dataTable.order-column tbody tr.selected > .sorting_3 {
    background-color: #f9f9f9; }
  table.dataTable.display tbody tr.selected > .sorting_1, table.dataTable.display tbody tr.selected > .sorting_2, table.dataTable.display tbody tr.selected > .sorting_3 {
    background-color: #f9f9f9; }
  table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke; }
  table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b4cd; }
  table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a8b5cf; }
  table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b7d1; }
  table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #fafafa; }
  table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fcfcfc; }
  table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fefefe; }
  table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad5; }
  table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #aebcd6; }
  table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8; }
  table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #f3f3f3; }
  table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #efefef; }
  table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #a2aec7; }
  table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #a3b0c9; }
  table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #a5b2cb; }
  table.dataTable.no-footer {
    border-bottom: 1px solid #2D2926; }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap; }
  table.dataTable.compact thead th, table.dataTable.compact thead td {
    padding: 4px 17px 4px 4px; }
  table.dataTable.compact tfoot th, table.dataTable.compact tfoot td {
    padding: 4px; }
  table.dataTable.compact tbody th, table.dataTable.compact tbody td {
    padding: 4px; }
  table.dataTable th.dt-left, table.dataTable td.dt-left {
    text-align: left; }
  table.dataTable th.dt-center {
    text-align: center; }
  table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
    text-align: center; }
  table.dataTable th.dt-right, table.dataTable td.dt-right {
    text-align: right; }
  table.dataTable th.dt-justify, table.dataTable td.dt-justify {
    text-align: justify; }
  table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
    white-space: nowrap; }
  table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left {
    text-align: left; }
  table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
    text-align: left; }
  table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center {
    text-align: center; }
  table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
    text-align: center; }
  table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right {
    text-align: right; }
  table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
    text-align: right; }
  table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify {
    text-align: justify; }
  table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
    text-align: justify; }
  table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap {
    white-space: nowrap; }
  table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap; }
  table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
    text-align: left; }
  table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
    text-align: center; }
  table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
    text-align: right; }
  table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
    text-align: justify; }
  table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap; }
  table.dataTable th, table.dataTable td {
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }

/*table.dataTable thead .sorting {
  background-image: url("../images/sort_both.png");
}
table.dataTable thead .sorting_asc {
  background-image: url("../images/sort_asc.png");
}
table.dataTable thead .sorting_desc {
  background-image: url("../images/sort_desc.png");
}
table.dataTable thead .sorting_asc_disabled {
  background-image: url("../images/sort_asc_disabled.png");
}
table.dataTable thead .sorting_desc_disabled {
  background-image: url("../images/sort_desc_disabled.png");
}*/
/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1; }
  .dataTables_wrapper .dataTables_length {
    float: left; }
  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right; }
    .dataTables_wrapper .dataTables_filter input {
      margin-left: 0.5em; }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em; }
  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em; }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1.5em;
      padding: 0.5em 1em;
      margin-left: 2px;
      text-align: center;
      text-decoration: none !important;
      cursor: pointer;
      *cursor: hand;
      color: #333 !important;
      border: 1px solid transparent;
      border-radius: 2px; }
      .dataTables_wrapper .dataTables_paginate .paginate_button.current {
        color: #fff !important;
        border: 1px solid #1283B1;
        background-color: #1283B1; }
        .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
          color: #fff !important;
          border: 1px solid #1283B1;
          background-color: #1283B1; }
      .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
        cursor: default;
        color: #666 !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none; }
        .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
          cursor: default;
          color: #666 !important;
          border: 1px solid transparent;
          background: transparent;
          box-shadow: none; }
      .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
        color: #fff !important; }
      .dataTables_wrapper .dataTables_paginate .paginate_button:active {
        outline: none; }
    .dataTables_wrapper .dataTables_paginate .ellipsis {
      padding: 0 1em; }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%); }
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
    color: #333; }
  .dataTables_wrapper .dataTables_scroll {
    clear: both; }
    .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
      *margin-top: -1px;
      -webkit-overflow-scrolling: touch; }
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td {
        vertical-align: middle; }
      .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #111; }
  .dataTables_wrapper.no-footer div.dataTables_scrollHead table, .dataTables_wrapper.no-footer div.dataTables_scrollBody table {
    border-bottom: none; }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
    margin-top: 0.5em; } }

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
    margin-top: 0.5em; } }

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important; }
  table.dataTable td, table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }
  table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
    text-align: center; }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }
  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left; }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap;
  background: #f0f0f0;
  padding: 10px 20px;
  border-radius: 55px;
  margin-top: 20px;
  font-size: 13px; }

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 10px 0;
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  padding-right: 30px; }

table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px; }

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none; }

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: block;
  font-family: 'Material Design Icons';
  opacity: 0.5; }

table.dataTable thead .sorting:after {
  opacity: 0.2;
  content: "\F4BF";
  /* sort */ }

table.dataTable thead .sorting_asc:after {
  content: "\F4BC";
  /* sort-by-attributes */ }

table.dataTable thead .sorting_desc:after {
  content: "\F4BD";
  /* sort-by-attributes-alt */ }

table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  color: #e0e0e0; }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none; }
  div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none; }

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none; }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: center; } }

table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px; }

table.dataTable.table-condensed .sorting:after, table.dataTable.table-condensed .sorting_asc:after, table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px; }

table.table-bordered.dataTable th, table.table-bordered.dataTable td {
  border-left-width: 0; }

table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child {
  border-right-width: 0; }

table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
  border-bottom-width: 0; }

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0; }

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
    padding-left: 0; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-right: 0; }

.pagination > li > a {
  border-radius: 35px; }

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-radius: 35px; }

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: 35px; }

.pagination > .active > a {
  background: #086E97;
  border-color: #086E97; }
  .pagination > .active > a:hover, .pagination > .active > a:focus {
    background: #086E97;
    border-color: #086E97; }

.pagination > .active > span {
  background: #086E97;
  border-color: #086E97; }
  .pagination > .active > span:hover, .pagination > .active > span:focus {
    background: #086E97;
    border-color: #086E97; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 36px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 10px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 10px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 10px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.022), 0px 1px 2px rgba(0, 0, 0, 0.1); }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #555;
    line-height: 34px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #808080; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 28px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #e0e0e0 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #f3f3f3;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #808080;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #f3f3f3;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #f3f3f3;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e0e0e0; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e0e0e0; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f3f3f3;
  color: #555; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #f3f3f3 100%);
  background-image: -o-linear-gradient(top, white 50%, #f3f3f3 100%);
  background-image: linear-gradient(to bottom, white 50%, #f3f3f3 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #086e97; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #555;
    line-height: 34px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #808080; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #086e97;
    border: none;
    border-left: 1px solid #086e97;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 34px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #f3f3f3 50%, #d5d5d5 100%);
    background-image: -o-linear-gradient(top, #f3f3f3 50%, #d5d5d5 100%);
    background-image: linear-gradient(to bottom, #f3f3f3 50%, #d5d5d5 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #808080 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #086e97;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #086e97; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #f3f3f3 50%);
  background-image: -o-linear-gradient(top, white 0%, #f3f3f3 50%);
  background-image: linear-gradient(to bottom, white 0%, #f3f3f3 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #f3f3f3 50%, white 100%);
  background-image: -o-linear-gradient(top, #f3f3f3 50%, white 100%);
  background-image: linear-gradient(to bottom, #f3f3f3 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #086e97; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #f3f3f3;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #086e97; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #e0e0e0;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #1283b1;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #086e97;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12); }

[data-aos][data-aos][data-aos-duration='50'], body[data-aos-duration='50'] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay='50'], body[data-aos-delay='50'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='50'].aos-animate, body[data-aos-delay='50'] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration='100'], body[data-aos-duration='100'] [data-aos] {
  transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay='100'], body[data-aos-delay='100'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='100'].aos-animate, body[data-aos-delay='100'] [data-aos].aos-animate {
  transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration='150'], body[data-aos-duration='150'] [data-aos] {
  transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay='150'], body[data-aos-delay='150'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='150'].aos-animate, body[data-aos-delay='150'] [data-aos].aos-animate {
  transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration='200'], body[data-aos-duration='200'] [data-aos] {
  transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay='200'], body[data-aos-delay='200'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='200'].aos-animate, body[data-aos-delay='200'] [data-aos].aos-animate {
  transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration='250'], body[data-aos-duration='250'] [data-aos] {
  transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay='250'], body[data-aos-delay='250'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='250'].aos-animate, body[data-aos-delay='250'] [data-aos].aos-animate {
  transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration='300'], body[data-aos-duration='300'] [data-aos] {
  transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay='300'], body[data-aos-delay='300'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='300'].aos-animate, body[data-aos-delay='300'] [data-aos].aos-animate {
  transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration='350'], body[data-aos-duration='350'] [data-aos] {
  transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay='350'], body[data-aos-delay='350'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='350'].aos-animate, body[data-aos-delay='350'] [data-aos].aos-animate {
  transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration='400'], body[data-aos-duration='400'] [data-aos] {
  transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay='400'], body[data-aos-delay='400'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='400'].aos-animate, body[data-aos-delay='400'] [data-aos].aos-animate {
  transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration='450'], body[data-aos-duration='450'] [data-aos] {
  transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay='450'], body[data-aos-delay='450'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='450'].aos-animate, body[data-aos-delay='450'] [data-aos].aos-animate {
  transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration='500'], body[data-aos-duration='500'] [data-aos] {
  transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay='500'], body[data-aos-delay='500'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='500'].aos-animate, body[data-aos-delay='500'] [data-aos].aos-animate {
  transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration='550'], body[data-aos-duration='550'] [data-aos] {
  transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay='550'], body[data-aos-delay='550'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='550'].aos-animate, body[data-aos-delay='550'] [data-aos].aos-animate {
  transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration='600'], body[data-aos-duration='600'] [data-aos] {
  transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay='600'], body[data-aos-delay='600'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='600'].aos-animate, body[data-aos-delay='600'] [data-aos].aos-animate {
  transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration='650'], body[data-aos-duration='650'] [data-aos] {
  transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay='650'], body[data-aos-delay='650'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='650'].aos-animate, body[data-aos-delay='650'] [data-aos].aos-animate {
  transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration='700'], body[data-aos-duration='700'] [data-aos] {
  transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay='700'], body[data-aos-delay='700'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='700'].aos-animate, body[data-aos-delay='700'] [data-aos].aos-animate {
  transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration='750'], body[data-aos-duration='750'] [data-aos] {
  transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay='750'], body[data-aos-delay='750'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='750'].aos-animate, body[data-aos-delay='750'] [data-aos].aos-animate {
  transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration='800'], body[data-aos-duration='800'] [data-aos] {
  transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay='800'], body[data-aos-delay='800'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='800'].aos-animate, body[data-aos-delay='800'] [data-aos].aos-animate {
  transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration='850'], body[data-aos-duration='850'] [data-aos] {
  transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay='850'], body[data-aos-delay='850'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='850'].aos-animate, body[data-aos-delay='850'] [data-aos].aos-animate {
  transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration='900'], body[data-aos-duration='900'] [data-aos] {
  transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay='900'], body[data-aos-delay='900'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='900'].aos-animate, body[data-aos-delay='900'] [data-aos].aos-animate {
  transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration='950'], body[data-aos-duration='950'] [data-aos] {
  transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay='950'], body[data-aos-delay='950'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='950'].aos-animate, body[data-aos-delay='950'] [data-aos].aos-animate {
  transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration='1000'], body[data-aos-duration='1000'] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay='1000'], body[data-aos-delay='1000'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1000'].aos-animate, body[data-aos-delay='1000'] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration='1050'], body[data-aos-duration='1050'] [data-aos] {
  transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay='1050'], body[data-aos-delay='1050'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1050'].aos-animate, body[data-aos-delay='1050'] [data-aos].aos-animate {
  transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration='1100'], body[data-aos-duration='1100'] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay='1100'], body[data-aos-delay='1100'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1100'].aos-animate, body[data-aos-delay='1100'] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration='1150'], body[data-aos-duration='1150'] [data-aos] {
  transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay='1150'], body[data-aos-delay='1150'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1150'].aos-animate, body[data-aos-delay='1150'] [data-aos].aos-animate {
  transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration='1200'], body[data-aos-duration='1200'] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay='1200'], body[data-aos-delay='1200'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1200'].aos-animate, body[data-aos-delay='1200'] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration='1250'], body[data-aos-duration='1250'] [data-aos] {
  transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay='1250'], body[data-aos-delay='1250'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1250'].aos-animate, body[data-aos-delay='1250'] [data-aos].aos-animate {
  transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration='1300'], body[data-aos-duration='1300'] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay='1300'], body[data-aos-delay='1300'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1300'].aos-animate, body[data-aos-delay='1300'] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration='1350'], body[data-aos-duration='1350'] [data-aos] {
  transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay='1350'], body[data-aos-delay='1350'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1350'].aos-animate, body[data-aos-delay='1350'] [data-aos].aos-animate {
  transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration='1400'], body[data-aos-duration='1400'] [data-aos] {
  transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay='1400'], body[data-aos-delay='1400'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1400'].aos-animate, body[data-aos-delay='1400'] [data-aos].aos-animate {
  transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration='1450'], body[data-aos-duration='1450'] [data-aos] {
  transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay='1450'], body[data-aos-delay='1450'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1450'].aos-animate, body[data-aos-delay='1450'] [data-aos].aos-animate {
  transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration='1500'], body[data-aos-duration='1500'] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay='1500'], body[data-aos-delay='1500'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1500'].aos-animate, body[data-aos-delay='1500'] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration='1550'], body[data-aos-duration='1550'] [data-aos] {
  transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay='1550'], body[data-aos-delay='1550'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1550'].aos-animate, body[data-aos-delay='1550'] [data-aos].aos-animate {
  transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration='1600'], body[data-aos-duration='1600'] [data-aos] {
  transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay='1600'], body[data-aos-delay='1600'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1600'].aos-animate, body[data-aos-delay='1600'] [data-aos].aos-animate {
  transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration='1650'], body[data-aos-duration='1650'] [data-aos] {
  transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay='1650'], body[data-aos-delay='1650'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1650'].aos-animate, body[data-aos-delay='1650'] [data-aos].aos-animate {
  transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration='1700'], body[data-aos-duration='1700'] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay='1700'], body[data-aos-delay='1700'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1700'].aos-animate, body[data-aos-delay='1700'] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration='1750'], body[data-aos-duration='1750'] [data-aos] {
  transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay='1750'], body[data-aos-delay='1750'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1750'].aos-animate, body[data-aos-delay='1750'] [data-aos].aos-animate {
  transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration='1800'], body[data-aos-duration='1800'] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay='1800'], body[data-aos-delay='1800'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1800'].aos-animate, body[data-aos-delay='1800'] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration='1850'], body[data-aos-duration='1850'] [data-aos] {
  transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay='1850'], body[data-aos-delay='1850'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1850'].aos-animate, body[data-aos-delay='1850'] [data-aos].aos-animate {
  transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration='1900'], body[data-aos-duration='1900'] [data-aos] {
  transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay='1900'], body[data-aos-delay='1900'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1900'].aos-animate, body[data-aos-delay='1900'] [data-aos].aos-animate {
  transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration='1950'], body[data-aos-duration='1950'] [data-aos] {
  transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay='1950'], body[data-aos-delay='1950'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1950'].aos-animate, body[data-aos-delay='1950'] [data-aos].aos-animate {
  transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration='2000'], body[data-aos-duration='2000'] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay='2000'], body[data-aos-delay='2000'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2000'].aos-animate, body[data-aos-delay='2000'] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration='2050'], body[data-aos-duration='2050'] [data-aos] {
  transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay='2050'], body[data-aos-delay='2050'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2050'].aos-animate, body[data-aos-delay='2050'] [data-aos].aos-animate {
  transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration='2100'], body[data-aos-duration='2100'] [data-aos] {
  transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay='2100'], body[data-aos-delay='2100'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2100'].aos-animate, body[data-aos-delay='2100'] [data-aos].aos-animate {
  transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration='2150'], body[data-aos-duration='2150'] [data-aos] {
  transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay='2150'], body[data-aos-delay='2150'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2150'].aos-animate, body[data-aos-delay='2150'] [data-aos].aos-animate {
  transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration='2200'], body[data-aos-duration='2200'] [data-aos] {
  transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay='2200'], body[data-aos-delay='2200'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2200'].aos-animate, body[data-aos-delay='2200'] [data-aos].aos-animate {
  transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration='2250'], body[data-aos-duration='2250'] [data-aos] {
  transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay='2250'], body[data-aos-delay='2250'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2250'].aos-animate, body[data-aos-delay='2250'] [data-aos].aos-animate {
  transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration='2300'], body[data-aos-duration='2300'] [data-aos] {
  transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay='2300'], body[data-aos-delay='2300'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2300'].aos-animate, body[data-aos-delay='2300'] [data-aos].aos-animate {
  transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration='2350'], body[data-aos-duration='2350'] [data-aos] {
  transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay='2350'], body[data-aos-delay='2350'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2350'].aos-animate, body[data-aos-delay='2350'] [data-aos].aos-animate {
  transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration='2400'], body[data-aos-duration='2400'] [data-aos] {
  transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay='2400'], body[data-aos-delay='2400'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2400'].aos-animate, body[data-aos-delay='2400'] [data-aos].aos-animate {
  transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration='2450'], body[data-aos-duration='2450'] [data-aos] {
  transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay='2450'], body[data-aos-delay='2450'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2450'].aos-animate, body[data-aos-delay='2450'] [data-aos].aos-animate {
  transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration='2500'], body[data-aos-duration='2500'] [data-aos] {
  transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay='2500'], body[data-aos-delay='2500'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2500'].aos-animate, body[data-aos-delay='2500'] [data-aos].aos-animate {
  transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration='2550'], body[data-aos-duration='2550'] [data-aos] {
  transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay='2550'], body[data-aos-delay='2550'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2550'].aos-animate, body[data-aos-delay='2550'] [data-aos].aos-animate {
  transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration='2600'], body[data-aos-duration='2600'] [data-aos] {
  transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay='2600'], body[data-aos-delay='2600'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2600'].aos-animate, body[data-aos-delay='2600'] [data-aos].aos-animate {
  transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration='2650'], body[data-aos-duration='2650'] [data-aos] {
  transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay='2650'], body[data-aos-delay='2650'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2650'].aos-animate, body[data-aos-delay='2650'] [data-aos].aos-animate {
  transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration='2700'], body[data-aos-duration='2700'] [data-aos] {
  transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay='2700'], body[data-aos-delay='2700'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2700'].aos-animate, body[data-aos-delay='2700'] [data-aos].aos-animate {
  transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration='2750'], body[data-aos-duration='2750'] [data-aos] {
  transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay='2750'], body[data-aos-delay='2750'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2750'].aos-animate, body[data-aos-delay='2750'] [data-aos].aos-animate {
  transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration='2800'], body[data-aos-duration='2800'] [data-aos] {
  transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay='2800'], body[data-aos-delay='2800'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2800'].aos-animate, body[data-aos-delay='2800'] [data-aos].aos-animate {
  transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration='2850'], body[data-aos-duration='2850'] [data-aos] {
  transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay='2850'], body[data-aos-delay='2850'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2850'].aos-animate, body[data-aos-delay='2850'] [data-aos].aos-animate {
  transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration='2900'], body[data-aos-duration='2900'] [data-aos] {
  transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay='2900'], body[data-aos-delay='2900'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2900'].aos-animate, body[data-aos-delay='2900'] [data-aos].aos-animate {
  transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration='2950'], body[data-aos-duration='2950'] [data-aos] {
  transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay='2950'], body[data-aos-delay='2950'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2950'].aos-animate, body[data-aos-delay='2950'] [data-aos].aos-animate {
  transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration='3000'], body[data-aos-duration='3000'] [data-aos] {
  transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay='3000'], body[data-aos-delay='3000'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='3000'].aos-animate, body[data-aos-delay='3000'] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform; }
  [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translate(0); }

[data-aos=fade-up] {
  transform: translateY(100px); }

[data-aos=fade-down] {
  transform: translateY(-100px); }

[data-aos=fade-right] {
  transform: translate(-100px); }

[data-aos=fade-left] {
  transform: translate(100px); }

[data-aos=fade-up-right] {
  transform: translate(-100px, 100px); }

[data-aos=fade-up-left] {
  transform: translate(100px, 100px); }

[data-aos=fade-down-right] {
  transform: translate(-100px, -100px); }

[data-aos=fade-down-left] {
  transform: translate(100px, -100px); }

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform; }
  [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translate(0) scale(1); }

[data-aos=zoom-in] {
  transform: scale(0.6); }

[data-aos=zoom-in-up] {
  transform: translateY(100px) scale(0.6); }

[data-aos=zoom-in-down] {
  transform: translateY(-100px) scale(0.6); }

[data-aos=zoom-in-right] {
  transform: translate(-100px) scale(0.6); }

[data-aos=zoom-in-left] {
  transform: translate(100px) scale(0.6); }

[data-aos=zoom-out] {
  transform: scale(1.2); }

[data-aos=zoom-out-up] {
  transform: translateY(100px) scale(1.2); }

[data-aos=zoom-out-down] {
  transform: translateY(-100px) scale(1.2); }

[data-aos=zoom-out-right] {
  transform: translate(-100px) scale(1.2); }

[data-aos=zoom-out-left] {
  transform: translate(100px) scale(1.2); }

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform; }
  [data-aos^=slide][data-aos^=slide].aos-animate {
    transform: translate(0); }

[data-aos=slide-up] {
  transform: translateY(100%); }

[data-aos=slide-down] {
  transform: translateY(-100%); }

[data-aos=slide-right] {
  transform: translateX(-100%); }

[data-aos=slide-left] {
  transform: translateX(100%); }

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */
/* clearfix */
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y; }
  .owl-carousel .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px); }
    .owl-carousel .owl-wrapper:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  .owl-carousel .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%; }
    .owl-carousel .owl-wrapper-outer.autoHeight {
      -webkit-transition: height 500ms ease-in-out;
      -moz-transition: height 500ms ease-in-out;
      -ms-transition: height 500ms ease-in-out;
      -o-transition: height 500ms ease-in-out;
      transition: height 500ms ease-in-out; }
  .owl-carousel .owl-item {
    float: left; }

/* display none until init */
.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }
  .owl-controls .owl-page, .owl-controls .owl-buttons div {
    cursor: pointer; }

/* mouse grab icon */
.grabbing {
  cursor: url(../images/grabbing.png) 8 8, move; }

/* fix */
.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

/*
* 	Owl Carousel Owl Demo Theme
*	v1.3.3
*/
.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center; }
  .owl-theme .owl-controls .owl-buttons div {
    color: #FFF;
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
    margin: 5px;
    padding: 3px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: #869791;
    filter: Alpha(Opacity=50);
    /*IE7 fix*/
    opacity: 0.5; }
  .owl-theme .owl-controls.clickable .owl-buttons div:hover {
    filter: Alpha(Opacity=100);
    /*IE7 fix*/
    opacity: 1;
    text-decoration: none; }
  .owl-theme .owl-controls .owl-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */ }
    .owl-theme .owl-controls .owl-page span {
      display: block;
      width: 12px;
      height: 12px;
      margin: 5px 7px;
      filter: Alpha(Opacity=50);
      /*IE7 fix*/
      opacity: 0.5;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      background: #869791; }
    .owl-theme .owl-controls .owl-page.active span {
      filter: Alpha(Opacity=100);
      /*IE7 fix*/
      opacity: 1; }
  .owl-theme .owl-controls.clickable .owl-page:hover span {
    filter: Alpha(Opacity=100);
    /*IE7 fix*/
    opacity: 1; }
  .owl-theme .owl-controls .owl-page span.owl-numbers {
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px; }

/* Styling Next and Prev buttons */
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
/* Styling Pagination*/
/* If PaginationNumbers is true */
/* preloading images */
.owl-item.loading {
  min-height: 150px;
  background: url(../images/AjaxLoader.gif) no-repeat center center; }

/*
 *  Owl Carousel CSS3 Transitions
 *  v1.3.2
 */
.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective: 1200px;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
  perspective: 1200px; }

/* fade */
.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease; }

.owl-fade-in {
  -webkit-animation: fadeIn .7s both ease;
  -moz-animation: fadeIn .7s both ease;
  animation: fadeIn .7s both ease; }

/* backSlide */
.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease; }

.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease; }

/* goDown */
.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both; }

.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both; }

/* scaleUp */
.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both; }

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both; }

/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
  0% {
    opacity: 1; } }

@-moz-keyframes empty {
  0% {
    opacity: 1; } }

@keyframes empty {
  0% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px); }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%); } }

@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px); }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%); } }

@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-500px); }
  75% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%); } }

@-webkit-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px); }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0); } }

@-moz-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px); }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0); } }

@keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    transform: translateZ(-500px); }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0); } }

@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(0.8); } }

@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(0.8); } }

@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(0.8); } }

@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%); } }

@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%); } }

@keyframes goDown {
  from {
    transform: translateY(-100%); } }

@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5); } }

@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5); } }

@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5); } }

@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5); } }

@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5); } }

@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5); } }

.top-cities-entry {
  margin-top: 55px; }
  .top-cities-entry .page-title {
    margin-top: 35px; }

.top-detail {
  margin-top: 55px; }

.side-link .mdi {
  margin-right: 4px; }

.side-list {
  margin-bottom: 20px; }
  .side-list h3 {
    margin-top: 0;
    color: #333; }

.inner-side h3 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.inner-title h3, h3, h2, h1, h4, h5 {
  color: #333; }

.state-proportion h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold; }

.smt-list-item {
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative; }

.smt-list-entry {
  display: table-cell;
  vertical-align: middle; }
  .smt-list-entry.small {
    width: 25%; }

.side-by-side {
  display: block; }
  .side-by-side.for-half > .side-block {
    display: inline-block;
    width: 48%;
    vertical-align: top; }

.chart-icon i {
  font-size: 110px;
  text-align: center;
  margin: 0 auto;
  display: block; }

.state-proportion-text h4, .chart-icon i {
  color: #E4002B; }

.state-proportion-text h4 {
  text-transform: capitalize;
  margin-top: 0; }

.side-block h3 {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 20px;
  border-radius: 35px; }

.line-divider {
  height: 1px;
  display: block;
  background: rgba(0, 0, 0, 0.1);
  margin: 5px 0; }

.entry-has-table {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.2); }

.ted-table th {
  text-transform: uppercase;
  color: #333; }

.ted-table tr:nth-of-type(1) {
  background: #f9f9f9;
  border: none; }

.ted-table p {
  margin-bottom: 0; }

.ted-table em {
  font-style: normal;
  display: block; }

.republic {
  color: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: inline-block;
  text-align: center;
  line-height: 28px; }

.democrat {
  color: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  background: #086E97; }

.republic {
  background: #E4002B; }

.text-has-icon {
  display: inline-block; }

/* HONEPAGE style ----------------------------- */
.top-nav {
  border-top: 2px solid #E4002B; }

.main-nav .nav.navbar-nav {
  float: right; }

.main-nav .navbar-brand > img {
  height: 55px; }

.main-nav .navbar-nav > li {
  font-size: 16px; }
  .main-nav .navbar-nav > li > a {
    padding: 0 20px;
    font-size: 1.6rem;
    display: block; }
    .main-nav .navbar-nav > li > a:hover, .main-nav .navbar-nav > li > a:focus {
      background: rgba(0, 0, 0, 0.055);
      color: #086E97; }

.main-header .navbar-default .navbar-nav > .active > a:hover, .main-header .navbar-default .navbar-nav > .active > a:focus {
  color: #086E97; }

.main-nav .navbar-nav > li > a:focus {
  outline: none; }

/*.main-header .navbar-default .navbar-nav > .active > a {
  color: #E4002B;
  background: transparent;
}*/
.navbar-nav > a.active {
  color: #E4002B;
  background: transparent; }

.search-container {
  background: #FBFBFD; }

.search-form {
  position: relative;
  padding: 8px 0; }
  .search-form input[type="text"] {
    background: transparent;
    border-radius: 0;
    height: 48px;
    width: 88%;
    color: #222;
    border: 1px solid #f0f0f0; }
    .search-form input[type="text"]:focus {
      background: white;
      box-shadow: none;
      outline: none;
      color: #222;
      border: 1px solid #fafafa; }
  .search-form .form-group {
    margin-bottom: 0; }
  .search-form .btn.btn-default {
    background: transparent;
    border: 1px solid #E4002B;
    border-radius: 2px;
    position: absolute;
    top: 10px;
    right: 0;
    color: #E4002B;
    padding: 12px 30px; }
    .search-form .btn.btn-default:hover {
      background: #E4002B;
      color: #fff;
      border: 1px solid #E4002B; }

.feature {
  height: 640px;
  background: #1283B1 url("../images/top-bg.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  /*background: #E4002B;
  background: -webkit-linear-gradient(to top, #E4002B , #CF0026);
  background: linear-gradient(to top, #E4002B , #CF0026); */ }

.main-tagline {
  display: flex;
  -ms-display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  height: 650px;
  max-width: 75%;
  margin: 0 auto; }

.tagline-content {
  align-self: center;
  color: #fff;
  text-align: center; }
  .tagline-content h1 {
    font-size: 60px;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 35px;
    font-weight: bold; }
  .tagline-content h3 {
    font-size: 18px;
    color: #f9f9f9;
    font-weight: 600;
    font-family: 'open sans', sans-serif;
    max-width: 85%;
    margin: 20px auto 35px; }

.left-tagline {
  display: flex;
  flex-flow: column wrap;
  height: 650px;
  max-width: 45%;
  align-content: center;
  justify-content: center; }

.left-tag-content {
  align-self: center; }
  .left-tag-content h1 {
    color: #fff;
    font-weight: bold; }
  .left-tag-content h3 {
    font-size: 18px;
    font-family: 'open sans', sans-serif;
    font-weight: normal;
    color: #e0e0e0; }
  .left-tag-content .btn.btn-default {
    background: #E4002B;
    color: #fff;
    border-color: #E4002B;
    text-transform: capitalize;
    margin-top: 35px; }

.center-tagline {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  text-align: center; }

.page-tagline {
  align-self: center; }

.feature-content {
  display: flex;
  flex-flow: column wrap;
  align-content: stretch;
  justify-content: space-around;
  padding: 20px;
  text-align: center; }
  .feature-content:hover {
    background: rgba(0, 0, 0, 0.035); }

.top-feature, .bottom-feature {
  align-self: center; }

.feature-content i {
  font-size: 75px;
  color: #2D2926; }

.bottom-feature h3 {
  text-align: center;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
  font-size: 18px; }

.insider {
  background: #1283B1; }

.big-search {
  color: #fff;
  position: relative;
  max-width: 80%;
  margin: 0 auto; }
  .big-search input {
    height: 55px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;
    background: transparent;
    color: white; }
    .big-search input:focus {
      color: rgba(0, 0, 0, 0.8);
      background: rgba(255, 255, 255, 0.4); }
  .big-search .btn.btn-primary {
    background: #064A67;
    color: #fff;
    text-transform: capitalize;
    border-radius: 3px;
    border: 1px solid #064A67;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 10px 40px;
    margin-top: 10px; }
    .big-search .btn.btn-primary:hover {
      background: #086e97;
      border-color: #086e97;
      box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2); }

.feat-insider {
  background: #FBFBFD; }

.two-side {
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative; }

.side-element {
  display: table-cell;
  vertical-align: middle; }
  .side-element.half {
    width: 48%; }

.feat-short {
  margin: 55px auto 75px; }

.feat-insider .nav-tabs {
  border: none; }
  .feat-insider .nav-tabs > li.active > a {
    border: none;
    background-color: transparent; }
    .feat-insider .nav-tabs > li.active > a:hover, .feat-insider .nav-tabs > li.active > a:focus {
      border: none;
      background-color: transparent; }
  .feat-insider .nav-tabs > li > a {
    border: none;
    background-color: transparent; }
    .feat-insider .nav-tabs > li > a:hover, .feat-insider .nav-tabs > li > a:focus {
      border: none;
      background-color: transparent; }

.graphic-content {
  padding: 30px; }

.feat-insider .nav-tabs li {
  min-width: 20%;
  text-align: center;
  font-weight: 600; }

.feat-insider .nav-tabs > li > a {
  border: none;
  border-radius: 0;
  margin-right: 0;
  padding: 20px 10px;
  background: rgba(0, 0, 0, 0.025);
  color: rgba(0, 0, 0, 0.3);
  position: relative; }

.feat-insider .nav-tabs > li.active > a {
  color: #E4002B; }

.home-action {
  border: 1px solid #076C94;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  display: block;
  margin: 20px auto;
  background: #086e97;
  color: white; }

.action-description {
  display: inline-block;
  font-size: 18px;
  line-height: 44px;
  color: #2d2926;
  font-weight: 600; }

.action-button {
  display: inline-block;
  float: right; }

.action .btn.btn-default {
  background: #086E97;
  color: #fff;
  border-radius: 2px;
  line-height: 34px;
  float: right; }

.action .btn.btn-default:hover {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  border-color: rgba(0, 0, 0, 0.3); }

.people-search-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch; }

.people-thumb {
  align-content: center;
  border: 2px solid transparent;
  width: 10%;
  position: relative;
  cursor: pointer;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  overflow: hidden; }
  .people-thumb:hover {
    border: 2px solid #086E97; }

.thumb-overlay {
  background: rgba(228, 0, 43, 0.95);
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  transform: translateY(100%);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }
  .thumb-overlay h3 {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: bold;
    text-align: center; }
  .thumb-overlay i {
    font-size: 36px;
    text-align: center;
    display: block;
    margin: 20px auto 0;
    color: #fff; }

.people-thumb:hover .thumb-overlay {
  display: block;
  transform: translateY(0); }

.mid-section img {
  margin: 35px auto; }

.news-title {
  position: relative; }
  .news-title h3 {
    font-size: 18px;
    color: #2d2926; }
  .news-title:after {
    height: 4px;
    background: #e4002b;
    position: absolute;
    width: 50%;
    right: 0;
    top: 0; }

.author-thumb {
  border-radius: 50%;
  display: block;
  background: white; }
  .author-thumb img {
    width: 60px;
    height: 60px;
    padding: 2px;
    float: left; }

.author p {
  margin-bottom: 0;
  text-transform: capitalize;
  padding-left: 70px; }

.small-list {
  display: block;
  margin-bottom: 20px; }
  .small-list .news-list {
    float: left;
    width: 47%; }
    .small-list .news-list:nth-of-type(1) {
      margin-right: 6%; }
  .small-list h3 {
    font-size: 16px;
    margin: 0;
    font-weight: bold; }

.news-list h3 {
  font-size: 16px;
  margin: 0;
  font-weight: bold; }

.last-profile .center-tagline {
  margin-bottom: 0;
  background: #fafafa;
  padding: 30px 0; }
  .last-profile .center-tagline h3 {
    color: #e4002b;
    letter-spacing: 2px;
    margin-bottom: 10px; }
  .last-profile .center-tagline h4 {
    color: #5c5c5c;
    font-weight: bold;
    max-width: 100%; }

.pg-action {
  background: #086e97;
  color: #fff;
  position: relative; }
  .pg-action:before {
    content: '';
    background: rgba(0, 0, 0, 0.025);
    height: 100%;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block; }

.bottom-offer,
.bottom-button {
  padding: 20px; }

.bottom-button h3 {
  color: #fff;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 1px; }

.bottom-button .btn-outline {
  margin: 20px 0; }

.main-title h3 {
  text-transform: capitalize;
  background: transparent;
  padding: 0;
  color: #e4002b;
  margin-bottom: 0; }

.side-block .section-divider {
  margin: 30px 0; }

.bottom-section {
  background: white; }

.feat-insider {
  padding-bottom: 30px; }

.side-block {
  padding-top: 35px; }

.latest-update {
  border-top: 1px solid #f3f3f3; }
  .latest-update .center-tagline {
    margin: 35px auto; }

.feat-insider .nav-tabs > li i {
  display: block;
  font-size: 36px;
  text-align: center;
  margin: 0 auto; }

.feat-insider .nav-tabs > li {
  font-size: 14px; }

.feat-description h3 {
  color: #e4002b;
  text-transform: capitalize;
  font-weight: bold; }

.feat-short .btn.btn-primary {
  background: white;
  border: 1px solid #086e97;
  color: #086e97;
  border-radius: 3px;
  text-align: center;
  text-transform: capitalize; }

.feat-short .btn:hover {
  background: #086e97;
  color: white;
  border-color: #086e97; }

.main-nav .navbar-nav > li > a:hover,
.main-nav .navbar-nav > li > a:focus {
  background: transparent; }

.bottom-feature h3 {
  margin: 20px 0 0; }

.feat-insider .nav-tabs > li > a,
.feat-insider .nav-tabs > li.active {
  position: relative; }

.feat-insider .nav-tabs > li.active > a:after {
  content: '';
  height: 2px;
  background: #e4002b;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.feat-short .btn.btn-primary {
  margin-right: 20px;
  min-width: 90px; }

.main-title h4 {
  font-size: 16px;
  margin-bottom: 20px; }

.center-tagline h3 {
  letter-spacing: .2px;
  color: #e4002b;
  margin-bottom: 35px; }

.center-tagline h4 {
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #2D2926;
  font-size: 18px;
  max-width: 75%;
  margin: 0 auto;
  line-height: 1.3; }

.our-partners {
  background: #f3f3f3;
  padding: 10px; }
  .our-partners .item {
    height: 103px;
    text-align: center; }
    .our-partners .item img {
      margin: 0 auto;
      max-width: 100%;
      height: auto;
      display: block; }

.partner-title {
  background: #f3f3f3;
  border-bottom: 1px solid #e0e0e0;
  font-size: 2.4rem;
  padding: 20px 0;
  margin: 0;
  text-align: center; }

.business-feature {
  margin: 35px auto; }
  .business-feature .btn.btn-primary {
    line-height: 36px;
    padding: 5px 30px;
    margin-top: 30px; }

.mid-section .page-title {
  text-transform: capitalize; }

.center-tagline h3 {
  margin-bottom: 20px; }

.feat-icon {
  display: inline-block;
  float: left;
  font-size: 18px;
  margin-right: 8px; }

.feat-overview {
  background: white;
  padding: 20px; }

.feat-insider .nav-tabs > li.active > a {
  background: white; }

.graphic-content {
  padding: 0; }

.side-block h3 {
  background: transparent;
  border-radius: 0; }

.feat-box {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%; }
  .feat-box.third-of-one .feat-box-item {
    width: 33.3%; }
  .feat-box.half-of-one .feat-box-item {
    width: 50%; }
  .feat-box .feat-box-item {
    display: table-cell;
    vertical-align: middle; }

.feat-entry p {
  padding-left: 26px; }

.side-feat {
  border: 1px solid #f0f0f0;
  padding: 20px;
  position: relative; }
  .side-feat:after {
    content: '';
    height: 4px;
    background: #e4002b;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .side-feat:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #e4002b transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 30px; }
  .side-feat h3 {
    color: #2D2926;
    margin: 0 0 30px; }

.news-list h3 {
  margin: 10px 0; }

.author {
  padding: 20px; }

.overlay-show .btn.btn-default {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border-color: rgba(0, 0, 0, 0.4); }
  .overlay-show .btn.btn-default:hover {
    background: white;
    border-color: white; }

.news-list {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12); }

.short-description {
  background: white;
  padding: 0 20px; }

.top-feature {
  margin-top: 20px; }

.our-partners {
  padding: 20px 0; }

.list-bordered li {
  margin-bottom: 10px; }
  .list-bordered li:last-child {
    border: none;
    margin-bottom: 0; }

.center-tagline h4 {
  line-height: inherit;
  font-size: 16px; }

#smtChart {
  margin: 0 auto;
  width: 250px !important;
  height: 250px !important; }

.chart-holder {
  width: 250px;
  height: 250px; }

.state-wide p {
  margin-bottom: 0; }

.election-trend {
  margin-top: 20px; }

.election-geo {
  background: #086E97;
  color: #fff; }
  .election-geo h3 {
    color: white; }
  .election-geo h4 {
    color: white;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    max-width: 100%; }
  .election-geo .center-tagline {
    margin: 35px auto; }

.geo-content {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;
  max-width: 740px;
  margin: 0 auto 55px; }
  .geo-content .geo-list {
    display: table-cell;
    width: 33.3%;
    vertical-align: top; }

.press-content {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 0; }

#mapDiv {
  height: 480px !important;
  overflow: hidden; }

.main-content.fit-page {
  overflow: hidden; }

.login-page {
  background: #FBFBFD; }

.login-page,
.register-page {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  height: 100%; }

.register-page {
  background: #fff; }
  .register-page .login-content {
    background: #fff; }
  .register-page .login-form {
    width: 65%;
    box-shadow: none; }
  .register-page .login-bottom {
    text-align: center; }

.login-bottom.custom {
  text-align: center; }

.login-content,
.login-side {
  width: 50%;
  align-self: stretch;
  height: 100vh; }

.login-content {
  background: #FBFBFD; }

.login-side {
  background: #fff url("../../images/login-bg.jpg") 0 0 no-repeat;
  background-size: cover; }

.site-login {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 100%; }

.login-form,
.user-choose {
  align-self: stretch;
  width: 48%;
  max-width: 100%;
  margin: 0 auto; }

.login-form {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding: 20px 40px;
  background: white; }
  .login-form .form-group {
    margin-left: 0;
    margin-right: 0; }
  .login-form .btn.btn-primary {
    padding: 14px 30px;
    display: block;
    width: 100%;
    text-transform: capitalize;
    font-weight: bold;
    margin-top: 30px;
    background: #e4002b;
    color: white;
    border-color: #e4002b; }
    .login-form .btn.btn-primary:hover {
      background: #1283b1;
      border-color: #1283b1;
      color: white; }
    .login-form .btn.btn-primary:focus {
      outline: none; }
  .login-form label {
    text-transform: capitalize;
    color: #333;
    font-weight: normal; }
  .login-form input {
    border-radius: 2px;
    border: 1px solid #f0f0f0;
    box-shadow: 0;
    background: #FBFBFD;
    -webkit-transition-property: box-shadow, background;
    transition-property: box-shadow, background;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    color: #5c5c5c;
    margin-bottom: 20px;
    height: 46px; }
    .login-form input:hover {
      background: white;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12);
      border-color: white; }
    .login-form input:focus {
      outline: none;
      background: white;
      border-color: #f4f4f4; }
    .login-form input .help-text {
      margin-top: 5px;
      font-size: 1.3rem;
      color: #808080;
      box-shadow: none; }

.login-title {
  color: #2d2d2d;
  font-size: 28px;
  margin: 20px auto; }

.user-choose {
  margin-top: 35px; }

.login-left,
.login-right {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 48%;
  font-size: 16px;
  text-transform: capitalize; }
  .login-left a:hover,
  .login-right a:hover {
    background: #1283b1;
    color: white;
    border-color: #1283b1; }

.login-left a {
  line-height: 48px;
  border-radius: 3px;
  background: white;
  border: 1px solid #1283b1;
  color: #1283b1;
  display: block; }

.login-right a {
  line-height: 48px;
  border-radius: 3px;
  background: white;
  border: 1px solid #1283b1;
  color: #1283b1;
  display: block; }

.login-top img {
  margin: 0 auto; }

.form-control {
  box-shadow: none;
  border-radius: 2px;
  border-color: #e0e0e0; }

.smt-form .select2-search--dropdown {
  display: none; }

.smt-form .form-horizontal .control-label {
  margin-bottom: 0;
  padding: 0 15px;
  text-align: left;
  margin: 6px 0;
  text-transform: uppercase;
  color: #333; }

.masking-input {
  position: relative;
  margin: 0;
  cursor: pointer;
  margin: 0 0 4px 0;
  min-width: 75px;
  text-align: center; }
  .masking-input input[type="checkbox"],
  .masking-input input[type="radio"] {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }

.masking-input {
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  background: #fcfcfc;
  padding: 5px 15px;
  font-weight: normal;
  position: relative;
  box-shadow: none;
  -webkit-transition-property: background, border-color;
  transition-property: background, border-color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }
  .masking-input:hover {
    background: white;
    color: #086e97;
    border-color: #086e97;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18), 0 2px 5px 0 rgba(0, 0, 0, 0.15); }
  .masking-input.active {
    border-color: #00CC71;
    color: #00CC71; }
    .masking-input.active:after {
      content: "\F5E0";
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      vertical-align: middle;
      display: inline-block;
      float: left;
      line-height: 24px;
      margin-right: 4px; }

.inner-side.logged-user {
  border: 1px solid rgba(0, 0, 0, 0.055);
  background: #f9f9f9; }

.side-title {
  position: relative; }
  .side-title h3 {
    color: #e4002b; }
  .side-title:after {
    content: '';
    height: 2px;
    background: #e4002b;
    width: 65%;
    right: 0;
    top: 34%;
    position: absolute; }

.box-search {
  position: relative; }
  .box-search .btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 28px;
    z-index: 1;
    background: #086e97;
    line-height: 32px;
    padding: 0 6px;
    border: 1px solid #086e97; }
    .box-search .btn.btn-default {
      border-radius: 0;
      box-shadow: none; }
      .box-search .btn.btn-default:hover {
        background: #1283b1;
        color: white;
        border-color: #1283b1; }

.form-group.inner-bottom > button {
  margin: 35px auto;
  background: #086e97;
  color: white;
  border-color: #086e97;
  border-radius: 2px; }
  .form-group.inner-bottom > button:hover {
    background: #1283b1;
    color: white;
    border-color: #1283b1; }

.form-title h3 {
  font-size: 36px;
  color: #fff;
  letter-spacing: 1px;
  font-weight: bold;
  margin: 10px 0; }

.form-title a {
  color: #e4002b; }
  .form-title a:hover {
    color: white; }

.form-title p {
  margin-bottom: 0; }

.inner-title {
  margin: 30px auto; }

.cities-inner .split-item {
  vertical-align: middle; }

.form-group.inner-bottom {
  margin: 35px auto 0; }

.pg-front {
  position: relative;
  background: white; }

.side-icon {
  float: left;
  width: 18%;
  margin-right: 15px; }

.right-inner {
  margin: 30px auto; }

.media-listing li {
  display: block; }
  .media-listing li > a {
    padding: 10px 20px;
    display: block; }
    .media-listing li > a:hover {
      background: rgba(0, 0, 0, 0.045);
      color: #e4002b; }

.form-group.inner-bottom .btn.btn-default {
  min-width: 100px; }

.right-box.has-icon .side-media {
  padding-left: 55px; }

.smt-form .form-group {
  padding-bottom: 15px;
  border-bottom: 1px solid #f3f3f3; }
  .smt-form .form-group.clean {
    border-bottom: none; }

.title-icon {
  float: left;
  background: #fff;
  width: 55px;
  height: 55px;
  color: #e4002b;
  line-height: 55px;
  font-size: 34px;
  text-align: center;
  margin-right: 15px; }

.people-thumb {
  width: 20%;
  height: 315px;
  background: #086e97; }
  .people-thumb img {
    width: 100%; }

.category-title h3 {
  color: #2D2926; }

.category-title:after {
  background: #2D2926; }

.overlay-show {
  background: rgba(228, 0, 43, 0.9);
  color: white; }

.bottom-offer, .bottom-button {
  padding: 55px 20px; }

.thumb-overlay i {
  font-size: 55px;
  margin: 25% auto 0; }

.action-description {
  color: white; }

.smt-form .form-group {
  margin: 0;
  padding: 10px 0; }
  .smt-form .form-group:nth-child(2n+1) {
    background: #f9f9f9; }

.smt-form .inner-bottom.clean .btn.btn-default {
  min-width: 115px;
  margin-top: 35px; }

.grid-custom {
  max-width: 100%;
  margin: 0 auto; }
  .grid-custom .smt-list.half .smt-half {
    width: 48%; }

.center-tagline.no-margin {
  margin: 0 auto 35px; }

.research-content {
  text-align: center;
  margin-bottom: 30px; }

.research-icon {
  display: block;
  text-align: center;
  font-size: 48px;
  margin: 0 auto;
  background: #e0e0e0;
  width: 110px;
  height: 110px;
  border-radius: 50%; }
  .research-icon i {
    line-height: 110px;
    color: #5c5c5c; }

.research-content h4 {
  text-align: center;
  margin-top: 30px; }

.demograph-content {
  background: #f9f9f9;
  padding-bottom: 55px; }

.state-wide .center-tagline h4 {
  max-width: 100%; }

.chart-holder {
  margin: 0 auto;
  text-align: center;
  position: relative;
  width: 300px; }

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px; }

.chart-legend li {
  display: block;
  margin-bottom: 15px; }

.chart-legend p {
  display: inline-block;
  padding-left: 10px;
  margin: 0; }

.chart-legend .box-count {
  display: inline-block;
  vertical-align: middle;
  width: 55px;
  height: 55px;
  text-align: center;
  font-size: 18px;
  line-height: 55px;
  background: #e0e0e0;
  border-radius: 2px;
  color: #333; }
  .chart-legend .box-count.other {
    background: #4D5360;
    color: #fff; }
  .chart-legend .box-count.dark {
    background: #2D2926;
    color: #fff; }
  .chart-legend .box-count.democrat {
    background: #086E97;
    color: #fff; }
  .chart-legend .box-count.republic {
    background: #e4002b;
    color: #fff; }

.middle-inner h4.panel-title {
  font-size: initial; }

.middle-inner .panel-default {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.12); }

.faq-section {
  margin: 55px auto 0;
  margin-bottom: 0; }
  .faq-section .panel-group .panel + .panel {
    margin-top: 20px; }

.help-start {
  background: #fafafa; }

.top-cities-entry .page-title {
  font-size: 36px; }

.short-description {
  height: 54px; }

.infeature-content .fourth-section {
  margin-bottom: 30px; }

.data-list.clearfix h6 {
  margin-top: 3px; }

.gridCustom .detail-city {
  margin-bottom: 10px; }

.btn-mobile {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-transform: capitalize;
  text-align: center;
  padding: 0;
  line-height: 33px;
  padding: 0 15px;
  border-radius: 2px;
  margin: 0 auto;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 0 25px;
  margin-top: 20px;
  margin-bottom: 35px; }

.btn-mobile-show:hover {
  background: white;
  color: #e4002b; }

.btn-people-show {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-transform: capitalize;
  text-align: center;
  padding: 0;
  line-height: 33px;
  padding: 0 15px;
  border-radius: 2px;
  margin: 0 auto;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 0 25px;
  margin-top: 20px; }

.btn-people-show:hover {
  background: white;
  color: #e4002b; }

.description-box .right-description a {
  color: #fff; }

.description-box .right-description a:hover {
  color: #2D2926; }

.chevron-mobile {
  display: none; }

.search-mobile,
.login-mobile,
.faq-mobile,
.btn-people-show,
.gridCustom.is-actived,
.top-cities-entry .btn-mobile {
  display: none; }

.plan-description .ted-table th {
  text-align: center; }

.plan-description .ted-table td i {
  display: block;
  text-align: center; }

.plan-description .ted-table td:first-child:before,
.plan-description .ted-table td:nth-of-type(2):before {
  display: none; }

.plan-description .ted-table tr:nth-of-type(2n+1) {
  background: #fafafa;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.cities-inner {
  background: #2d2926;
  color: #f9f9f9;
  text-transform: capitalize; }

.top-cities-entry {
  text-transform: capitalize; }
  .top-cities-entry a {
    color: #f9f9f9; }
  .top-cities-entry h3 {
    color: white;
    text-transform: uppercase;
    margin-top: 0; }
  .top-cities-entry ul {
    text-transform: uppercase; }

.top-country li {
  display: inline-block;
  border-right: 1px solid #e0e0e0;
  padding-right: 10px; }
  .top-country li:last-child {
    border-right: none;
    padding-left: 10px; }
  .top-country li a {
    display: block; }

.count-date {
  text-transform: capitalize;
  background: rgba(0, 0, 0, 0.1);
  display: block;
  padding: 10px;
  margin: 10px 0; }
  .count-date li {
    border-right: none;
    display: inline-block;
    margin-right: 10px; }
    .count-date li:last-child {
      margin-right: 0; }
    .count-date li sup {
      text-transform: uppercase;
      font-size: 10px; }
    .count-date li p {
      display: inline-block;
      margin-bottom: 0; }

.top-social {
  font-size: 24px;
  margin-bottom: 10px;
  display: block; }

.top-detail {
  margin-top: 28px; }
  .top-detail a {
    color: #fff; }
    .top-detail a:hover {
      color: #e4002b; }

.data-list h6 {
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 15px;
  color: #e4002b;
  font-weight: bold;
  margin: 0;
  float: left;
  max-width: 33%; }

.data-list p {
  font-weight: normal;
  margin-bottom: 0;
  padding-left: 33%; }

.country-map {
  padding: 20px;
  position: relative; }

.top-country {
  margin: 20px 0; }

.city-official {
  margin: 10px 0 55px; }
  .city-official .smt-list .smd-item {
    width: 22%;
    margin: 1%;
    text-align: center;
    background: #fafafa;
    box-shadow: none;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .city-official .smt-list .smd-item:hover {
      background: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.city-person {
  display: block;
  text-transform: capitalize;
  position: relative; }
  .city-person.has-blue img {
    border: 2px solid #086e97; }
  .city-person.has-red img {
    border: 2px solid #e4002b; }
  .city-person .person-badge {
    position: absolute;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: #fff;
    right: -15px;
    bottom: -15px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 35px; }
  .city-person h4 {
    text-transform: uppercase;
    color: #2d2926; }
  .city-person a {
    display: block; }

.has-blue {
  background: #086e97; }

.has-red {
  background: #e4002b; }

.person-detail {
  text-align: center;
  text-transform: capitalize;
  padding-bottom: 20px; }
  .person-detail h4 {
    text-transform: uppercase;
    height: 48px;
    overflow: hidden; }
  .person-detail a {
    display: block; }

.city-person.has-blue .person-badge {
  background: #086e97; }

.city-person.has-red .person-badge {
  background: #e4002b; }

.bottom-stats {
  border-top: 1px solid #e0e0e0; }

.inner-title h3 {
  color: #e4002b;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 24px; }

.inner-title h5 {
  font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-transform: capitalize;
  margin-top: 0; }

.bottom-tabs {
  background: #fafafa; }

.icon-list i {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin-right: 5px; }

.icon-list p {
  display: inline-block; }

.bottom-tabs .nav-tabs > li > a {
  font-size: 14px;
  font-family: "Libertad", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  padding: 14px 30px; }

.bottom-tabs .tab-entry {
  padding: 20px;
  background: white;
  border-bottom: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.1); }

.side-list.clean li {
  border: none; }

.bottom-tabs .nav-tabs {
  margin: 35px auto 0; }

.note-section {
  margin: 35px auto 55px; }
  .note-section h3 {
    margin-bottom: 30px; }

.dropdown-menu > li > a {
  color: #086e97 !important;
  padding: 12px !important; }
  .dropdown-menu > li > a:hover {
    background: white;
    color: #5c5c5c; }

.login-page.fit-content {
  background: #FBFBFD; }
  .login-page.fit-content .login-bottom {
    background: rgba(0, 0, 0, 0.025);
    padding: 15px; }
    .login-page.fit-content .login-bottom h3 {
      font-weight: normal;
      letter-spacing: .5px; }
  .login-page.fit-content .login-content {
    width: 100%;
    background: transparent; }
  .login-page.fit-content .login-form,
  .login-page.fit-content .user-choose {
    width: 26%; }
  .login-page.fit-content .login-left, .login-page.fit-content .login-right {
    width: 49%; }
  .login-page.fit-content .login-left a:hover,
  .login-page.fit-content .login-right a:hover {
    background: #086e97;
    color: white;
    border-color: #086e97; }

.cities-inner {
  background: #fe002f;
  background: -moz-linear-gradient(top, #fe002f 0%, #e3002d 44%, #e4002a 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fe002f), color-stop(44%, #e3002d), color-stop(100%, #e4002a));
  background: -webkit-linear-gradient(top, #fe002f 0%, #e3002d 44%, #e4002a 100%);
  background: -o-linear-gradient(top, #fe002f 0%, #e3002d 44%, #e4002a 100%);
  background: -ms-linear-gradient(top, #fe002f 0%, #e3002d 44%, #e4002a 100%);
  background: linear-gradient(to bottom, #fe002f 0%, #e3002d 44%, #e4002a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe002f', endColorstr='#e4002a', GradientType=0 );
  padding: 20px 0; }
  .cities-inner h3 {
    margin: 0; }

.inner-title.non-subtitle h3 {
  line-height: 55px; }

.display.dataTable {
  font-size: inherit; }

.table-head i {
  display: inline-block;
  vertical-align: middle; }

.feed-grid {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  margin: 10px 0; }

.feed-item {
  align-self: stretch;
  width: 31.3%;
  margin: 1%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }
  .feed-item:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12), 0px 1px 6px rgba(0, 0, 0, 0.12);
    border: 1px solid transparent; }
  .feed-item img {
    margin-bottom: 10px; }

.top-feed h3 {
  font-size: 18px;
  line-height: initial;
  font-weight: bold;
  display: block;
  margin: 0; }

.feed-date {
  font-size: 12px;
  color: #808080;
  margin-bottom: 10px; }
  .feed-date i {
    display: inline-block;
    vertical-align: middle; }

.feed-text p {
  display: -webkit-box;
  max-width: 100%;
  height: 285px;
  margin: 0 auto;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }

.act-feed .right-inner {
  margin: 20px auto; }

.act-feed .side-media {
  padding: 0 15px; }
  .act-feed .side-media p {
    margin-bottom: 0; }

.act-feed .media-listing li {
  margin: 0 0 15px 0;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px; }
  .act-feed .media-listing li:last-child {
    border-bottom: none; }

.top-inner {
  background: #fafafa;
  padding: 70px 0 0;
  height: 115px; }
  .top-inner .breadcrumb {
    background: transparent;
    margin: 0;
    padding: 8px 0; }
  .top-inner .btn.btn-red {
    float: right; }

.content-title {
  text-align: center;
  position: relative; }
  .content-title h1 {
    margin-top: 35px;
    color: #e4002b; }
  .content-title h3 {
    color: #2D2926;
    font-family: "Open Sans", Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-transform: capitalize;
    max-width: 65%;
    margin: 0 auto;
    position: relative; }
    .content-title h3:after {
      background: #e4002b;
      content: '';
      height: 4px;
      width: 15%;
      bottom: 0;
      left: 0;
      right: 0;
      bottom: -90px;
      position: absolute;
      top: 0;
      margin: auto; }

.red-block {
  background: #e4002b;
  color: white; }

.first-section {
  margin-top: 90px; }

.first-section {
  background: url("../../images/desktop-preview.jpg") no-repeat top left;
  display: block;
  height: 610px; }

.third-section {
  background: url("../../images/app-preview2.jpg") no-repeat top left;
  display: block;
  height: 610px; }

.second-section {
  background: url("../../images/app-preview.jpg") no-repeat top right;
  display: block;
  height: 461px; }

.fourth-section {
  background: #fafafa; }

.bottom-inner {
  background: #e4002b;
  color: #fff;
  position: relative;
  position: relative; }
  .bottom-inner h3 {
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 1.2;
    text-transform: capitalize;
    font-weight: bold; }
    .bottom-inner h3 span {
      font-weight: normal; }

.big-title .btn.btn-primary {
  line-height: 34px;
  padding: 0 15px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 0 2px 2px; }

.big-title input[type=text],
.big-title input[type=email] {
  position: relative;
  height: 36px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.85); }
  .big-title input[type=text]:hover,
  .big-title input[type=email]:hover {
    background: white;
    box-shadow: none; }
  .big-title input[type=text]:focus,
  .big-title input[type=email]:focus {
    outline: none;
    background: white;
    box-shadow: none; }

.price-option {
  text-align: center;
  padding: 10px 0 35px; }
  .price-option h4 {
    text-transform: uppercase;
    font-weight: bold;
    color: #333;
    margin-top: 0; }
  .price-option .price-description {
    text-transform: capitalize; }
  .price-option .smtlist-item {
    width: 23%;
    margin: 1%;
    background: #fafafa;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    cursor: pointer; }
    .price-option .smtlist-item:hover {
      background: white;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.12); }

.price-box {
  position: relative;
  padding: 20px;
  transition: background, box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .price-box .btn.btn-primary {
    margin: 20px 0;
    text-transform: capitalize;
    border-color: #086e97;
    color: white; }
    .price-box .btn.btn-primary:hover {
      background: #086e97;
      color: #086e97; }
  .price-box i {
    font-size: 55px;
    color: inherit;
    line-height: 90px; }
  .price-box .plan-checked {
    display: none; }
  .price-box .mark-icon {
    border-radius: 50%;
    border: 2px solid #e4002b;
    color: #e4002b;
    width: 90px;
    height: 90px;
    margin: 0 auto 20px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .price-box.has-picked {
    background: rgba(0, 0, 0, 0.27);
    box-shadow: none;
    height: 100%; }
    .price-box.has-picked .btn.btn-primary {
      display: none; }
    .price-box.has-picked:hover {
      box-shadow: none; }
    .price-box.has-picked .mark-icon {
      background: #00CC71;
      color: white;
      border-color: rgba(255, 255, 255, 0.7); }
      .price-box.has-picked .mark-icon > i {
        color: white; }
      .price-box.has-picked .mark-icon .packed-icon {
        display: none; }
      .price-box.has-picked .mark-icon .plan-checked {
        display: block; }

.price-number {
  font-size: 28px;
  line-height: 85px; }
  .price-number sup {
    font-size: 55%;
    top: -10px;
    left: 6px; }

.feature-plan {
  background: #f9f9f9;
  padding: 0 0 35px; }

.plan-description {
  background: white;
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12); }
  .plan-description .smtlist-item {
    min-height: 48px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center; }
  .plan-description .smt-list {
    cursor: pointer; }
    .plan-description .smt-list:nth-of-type(2n+2) {
      background: #fafafa;
      border-top: 1px solid #f5f5f5;
      border-bottom: 1px solid #f5f5f5;
      box-shadow: none;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .plan-description .smt-list:hover {
      background: white;
      color: #086e97;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.022), 0px 1px 2px rgba(0, 0, 0, 0.1); }

.contact-form {
  margin: 35px auto 90px; }

.map-container {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 480px; }

.office-box {
  background: #fafafa;
  padding: 20px;
  border-radius: 2px;
  border: 1px solid #f3f3f3; }
  .office-box i {
    display: inline-block;
    float: left;
    margin-right: 5px; }
  .office-box p {
    margin-bottom: 0;
    text-transform: capitalize; }

.bottom.inner {
  height: 480px; }

.middle-inner .smt-list {
  max-width: 75%;
  margin: 90px auto 35px; }

.middle-inner .panel-title {
  font-size: 14px;
  font-weight: bold; }

.middle-inner .panel-default > .panel-heading {
  color: #1283b1;
  background-color: #fff;
  border-color: #f3f3f3;
  border-radius: 2px;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  box-shadow: none; }
  .middle-inner .panel-default > .panel-heading:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    color: #086e97; }

.middle-inner .panel-default {
  border-color: #f9f9f9; }

.middle-inner .collapse.in {
  background: #f9f9f9; }

.middle-inner .smt-quarter {
  text-align: center; }
  .middle-inner .smt-quarter h3 {
    color: #086e97; }
  .middle-inner .smt-quarter .box-border {
    margin: 0 auto;
    text-align: center; }

.middle-inner .smt-quarter {
  text-align: center; }

.box-icon i {
  font-size: 55px;
  text-align: center;
  display: block; }

.faq-section {
  margin-bottom: 90px; }

.help-icon i {
  font-size: 55px;
  text-align: center;
  display: block;
  color: #ccc;
  margin-top: 35px; }

.help-icon h1 {
  margin-top: 0; }

.help-index h6 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px; }

.help-index.smt-list {
  margin: 1%;
  background: #fafafa;
  border: 1px solid transparent;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12); }
  .help-index.smt-list:hover {
    background: white;
    border: 1px solid #f3f3f3;
    box-shadow: none; }

.middle-inner .help-index .smt-list {
  margin: 1%;
  max-width: 100%; }

.help-index .smt-quarter {
  text-align: center; }

.top-overview h3,
.top-overview figure {
  margin-top: 30px; }

.company-point {
  background: #086e97;
  color: #fff; }

.grid-custom .smt-third {
  margin: 0; }

.custom-line h2,
.custom-line h3 {
  color: white;
  text-align: center; }

.custom-line h3 {
  font-weight: normal;
  letter-spacing: .5px;
  margin-bottom: 35px; }

.custom-line h2 {
  font-size: 37px;
  font-weight: bold;
  margin: 35px auto 10px; }

.custom-line .smt-list {
  max-width: 100%;
  margin: 20px auto; }

.company-misson {
  background: #15a9e6;
  position: relative;
  color: #fff; }
  .company-misson .smt-list {
    max-width: 100%;
    margin: 0 auto; }

.company-misson h3 {
  text-align: center;
  color: #fff;
  max-width: 84%;
  font-weight: bold;
  letter-spacing: .2px;
  font-size: 24px;
  text-transform: capitalize;
  margin: 30px auto 35px;
  line-height: 1.3; }

.company-misson img {
  margin: 0 auto 35px; }

.company-misson .awward {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: rgba(0, 0, 0, 0.045);
  margin: 30px auto 0;
  color: #333; }
  .company-misson .awward h3 {
    font-size: 28px;
    letter-spacing: .5px; }

.bottom-stats .ted-table th, .bottom-stats .ted-table td {
  padding: 6px !important; }

.bottom-stats .ted-table th {
  color: #fff; }

.bottom-stats .ted-table tr {
  background: #f9f9f9; }
  .bottom-stats .ted-table tr:nth-of-type(1) {
    background: #e4002b;
    color: #fff; }

.pie-legend {
  list-style: none;
  margin: 0;
  padding: 0; }

.pie-legend span {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  margin-right: 16px;
  margin-bottom: -2px; }

.pie-legend li {
  margin-bottom: 10px; }

.cities-inner {
  padding: 60px 0 0; }

.smt-form {
  margin-bottom: 30px; }

.pg-front {
  margin-bottom: 55px; }

.people-detail {
  padding: 35px 0; }
  .people-detail .person-badge {
    text-align: center;
    color: #fff;
    right: -10px;
    bottom: -10px; }

.people-detail img {
  width: 100%; }

.people-description h3,
.people-description h4,
.people-description h5 {
  color: white; }

.people-description h3 {
  margin: 10px 0 0; }

.people-description h4,
.people-description h5 {
  font-weight: bold; }

.people-highlight {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  margin: 0 0 10px; }

.people-ext {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px; }
  .people-ext a {
    color: white; }
  .people-ext li {
    display: block;
    margin-right: 20px; }
    .people-ext li:last-child {
      margin-right: 0; }

.left-description,
.right-description {
  display: inline-block; }

.left-description {
  text-transform: uppercase;
  margin-right: 10px;
  min-width: 70px; }

.right-description {
  text-transform: capitalize; }

.social-list li {
  display: inline-block;
  margin-right: 0; }

.people-text {
  padding: 20px 0; }

.people-related {
  border-left: 1px solid #f3f3f3;
  display: block;
  padding: 0 20px;
  margin: 0 15px; }

.title-has-icon {
  margin-bottom: 20px; }
  .title-has-icon h3 {
    margin: 0 0 5px 0;
    display: inline-block;
    color: #e4002b; }
  .title-has-icon .btn.btn-default {
    background: #f9f9f9;
    border: 1px solid #f0f0f0;
    border-radius: 35px;
    padding: 0 15px;
    margin: 0;
    float: right;
    color: #086e97;
    font-size: 14px; }
    .title-has-icon .btn.btn-default:hover {
      background: #086e97;
      color: white; }
    .title-has-icon .btn.btn-default:hover, .title-has-icon .btn.btn-default:focus {
      outline: none; }

.horizontal-info .city-person {
  width: 28%;
  float: left;
  display: inline-block;
  margin-right: 15px; }

.horizontal-info .person-badge {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #fff;
  right: -10px;
  bottom: -5px;
  line-height: 25px;
  text-align: center; }

.person-badge {
  text-align: center; }

.info-text p + p {
  text-indent: 0; }

.top-cities-entry {
  margin-top: 0; }

.detail-city {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }
  .detail-city .top-detail,
  .detail-city .country-map {
    min-width: 50%;
    align-self: stretch; }
  .detail-city .country-map {
    position: relative;
    padding: 0; }

.data-list h6,
.detail-city h6 {
  color: #fff; }

.detail-city.block {
  display: block; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  line-height: 35px;
  padding: 0 10px; }

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  bottom: 0; }

table.dataTable tbody th, table.dataTable tbody td {
  padding: 0 10px; }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 0; }

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0 4px; }

.dataTables_paginate.paging_simple_numbers .pagination,
div.dataTables_wrapper div.dataTables_info {
  font-size: 12px; }

div.dataTables_wrapper div.dataTables_info {
  margin-top: 0; }

.dashboard-content {
  margin-bottom: 55px; }

.top-cities-entry a:hover,
.top-detail a:hover {
  color: #2D2926; }

.city-official .smd-item {
  align-self: flex-start; }

.tab-entry .side-block {
  padding-top: 0; }

.title-has-icon i {
  line-height: 28px; }

.infeature-content .fourth-section {
  margin-bottom: 30px; }

.plan-description {
  padding: 0; }

.infeature-content .fourth-section {
  padding-bottom: 30px;
  margin-bottom: 0; }

.price-box .btn.btn-primary:hover {
  color: #fff; }
