// $Base
// -------------------------------------//
// General base styles for type


$typl8-serif-boolean: true !default;
$typl8-custom-font: false !default;
$typl8-font-family: if($typl8-serif-boolean, serif, sans-serif) !default;
$typl8-font-weight: normal !default;
$typl8-line-height: 1.65 !default;
$typl8-font-size: 112.5 !default;
$typl8-font-base: 14 * ($typl8-font-size/100) !default;
$typl8-measure: $typl8-font-base * $typl8-line-height !default;
$typl8-prefixes: -webkit-, -moz-, -ms-, '' !default;
$typl8-typescale-unit: em !default;


// $Paragraphs
// -------------------------------------//


$typl8-paragraph-spacing: false !default;
$typl8-indent-val: 1.5rem !default;



$typl8-small-print-size: 65% !default;



$typl8-heading-color: #333 !default;
$typl8-copy-color: #5c5c5c !default;




$typl8-amp-fontface: 'Open Sans' !default;
$typl8-amp-fontfamily: 'Open Sans', sans-serif !default;
$typl8-amp-src: local('Open Sans'), local('Open Sans'), local('Open Sans'), local('Open Sans') !default;
$typl8-amp-fallback: local('Open Sans') !default;


// $Tscale
// -------------------------------------//


$typl8-scale: (
  typl8-tera: 117,
  typl8-giga: 90,
  typl8-mega: 72,
  typl8-alpha: 60,
  typl8-beta: 48,
  typl8-gamma: 36,
  typl8-delta: 24,
  typl8-epsilon: 21,
  typl8-zeta: 18,
) !default;

$typl8-headings: (
  h1: typl8-mega,
  h2: typl8-alpha,
  h3: typl8-beta,
  h4: typl8-gamma,
  h5: typl8-delta,
  h6: typl8-zeta
) !default;


// $Pull-Quotes
// -------------------------------------//

$typl8-pull-quote-fontsize: 4em !default;
$typl8-pull-quote-opacity: 0.5 !default;
$typl8-pull-quote-color: #dc976e !default;


// $Citations
// -------------------------------------//

$typl8-cite-display: block !default;
$typl8-cite-align: right !default;
$typl8-cite-font-size: inherit !default;


// $Small-Caps
// -------------------------------------//

$typl8-smcps-color: gray !default;
$typl8-smcps-weight: 600 !default;


// $DropCap
// -------------------------------------//

$typl8-dropcap-float-position: left !default;
$typl8-dropcap-font-size: 4em !default;
$typl8-dropcap-font-family: inherit !default;
$typl8-dropcap-txt-indent: 0 !default;
$typl8-dropcap-margin: 10px 10px 0 0 !default;
$typl8-dropcap-padding: 0 20px !default;
$typl8-dropcap-color: inherit !default;
$typl8-dropcap-line-height: 1 !default;
$typl8-dropcap-bg: transparent !default;
