// -----------------------------------------------------------------------------
// This file contains styles that are specific to the inner pages.
// -----------------------------------------------------------------------------


// login page


.main-content {
  &.fit-page {
    overflow: hidden;
  }

}

.login-page {
  background: $lite-grey;
}
.login-page,
.register-page {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  height: 100%;
}

.register-page {
  .login-content {
    background: #fff;
  }

  .login-form {
    width: 65%;
    box-shadow: none;
  }

  .login-bottom {
    text-align: center;
  }
  background: #fff;
}

.login-bottom.custom {
  text-align: center;
}

.login-content,
.login-side {
  width: 50%;
  align-self: stretch;
  height: 100vh;
}

.login-content {
  background: $lite-grey;
}

.login-side {
  background: #fff url("../../images/login-bg.jpg") 0 0 no-repeat;
  background-size: cover;

}

.site-login {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-content: center;
  height: 100%;
}

.login-form,
.user-choose {
  align-self: stretch;
  width: 48%;
  max-width: 100%;
  margin: 0 auto;

}

.login-form {

  box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 2px 0 rgba(0,0,0,.12);
  padding: 20px 40px;
  background: $clean;

    .form-group {
      margin-left: 0;
      margin-right: 0;
    }


  .btn.btn-primary {

    padding: 14px 30px;
    display: block;
    width: 100%;
    text-transform: capitalize;
    font-weight: bold;
    margin-top: 30px;
    background: $red-brand;
    color: $clean;
    border-color: $red-brand;


    &:hover {
      background: $lite-blue;
      border-color: $lite-blue;
      color: $clean;
    }

    &:focus {
      outline: none;
    }
  }

  label {
    text-transform: capitalize;
    color: #333;
    font-weight: normal;
  }

  input {
    border-radius: 2px;
    border: 1px solid #f0f0f0;
    box-shadow: 0;
    background: $lite-grey;
    -webkit-transition-property: box-shadow, background;
    transition-property: box-shadow, background;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    color: $text-color;
    margin-bottom: 20px;
    height: 46px;

    &:hover {
      background: $clean;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12);
      border-color: $clean;
    }

    &:focus {
      outline: none;
      background: $clean;
      border-color: #f4f4f4;
    }

    .help-text {
      margin-top: 5px;
      font-size: 1.3rem;
      color: #808080;
      box-shadow: none;
    }






  }
}

.login-title {
  color: #2d2d2d;
  font-size: 28px;
  margin: 20px auto;
}

.user-choose {
  margin-top: 35px;
}

.login-left,
.login-right {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 48%;
  font-size: 16px;
  text-transform: capitalize;

  a {
    &:hover {
      background: $lite-blue;
      color: $clean;
      border-color: $lite-blue;
    }
  }

}

.login-left {
  a {
    line-height: 48px;
    border-radius: 3px;
    background: $clean;
    border: 1px solid $lite-blue;
    color: $lite-blue;
    display: block;
  }

}

.login-right {
  a {
    line-height: 48px;
    border-radius: 3px;
    background: $clean;
    border: 1px solid $lite-blue;
    color: $lite-blue;
    display: block;
  }
}


.login-top {
  img {
    margin: 0 auto;
  }
}


.form-control {
  box-shadow: none;
  border-radius: 2px;
  border-color: $border-color;
}

.smt-form {
  .select2-search--dropdown {
    display: none;
  }

  .form-horizontal .control-label {
    margin-bottom: 0;
    padding: 0 15px;
    text-align: left;
    margin: 6px 0;
    text-transform: uppercase;
    color: #333;
  }
}

.masking-input {
  position: relative;
  margin: 0;
  cursor: pointer;
  margin: 0 0 4px 0;
  min-width: 75px;
  text-align: center;

  input[type="checkbox"],
  input[type="radio"]{
     clip: rect(1px,1px,1px,1px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}


.masking-input {
    display: inline-block;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    background: #fcfcfc;
    padding: 5px 15px;
    font-weight: normal;
  position: relative;
  box-shadow: none;
    -webkit-transition-property: background, border-color;
    transition-property: background, border-color;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;

  &:hover {
    background: $clean;
    color: $bold-blue;
    border-color: $bold-blue;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.18),0 2px 5px 0 rgba(0,0,0,0.15);
  }

  &.active {
    border-color: $green;
    color: $green;
    &:after {
      content: "\F5E0";
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      vertical-align: middle;
      display: inline-block;
      float: left;
      line-height: 24px;
      margin-right: 4px;
    }
  }
}



.inner-side {
  &.logged-user {
    border: 1px solid rgba(0,0,0,.055);
    background: #f9f9f9;
  }
}

.side-title {
  position: relative;

  h3 {
    color: $red-brand;
  }

  &:after {
    content:'';
    height: 2px;
    background: $red-brand;
    width: 65%;
    right: 0;
    top: 34%;
    position: absolute;
  }
}

.box-search {
  position: relative;
  .btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 28px;
    z-index: 1;
    background: $bold-blue;
    line-height: 32px;
    padding: 0 6px;
    border: 1px solid $bold-blue;

    &.btn-default {
      border-radius: 0;
      box-shadow: none;

      &:hover {
        background: $lite-blue;
        color: $clean;
        border-color: $lite-blue;
      }

    }
  }
}

.form-group.inner-bottom {
  > button {
    margin: 35px auto;
      background: $bold-blue;
      color: $clean;
      border-color: $bold-blue;
      border-radius: 2px;

      &:hover {
        background: $lite-blue;
        color: $clean;
        border-color: $lite-blue;
      }


  }
}

.form-title {
  h3 {
    font-size: 36px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 10px 0;
  }

  a {
    color: $red-brand;

    &:hover {
      color: $clean;
    }
  }

  p {
    margin-bottom: 0;
  }



}

.inner-title {
  margin: 30px auto;
}

.cities-inner {
  .split-item {
    vertical-align: middle;
  }
}

.form-group {
  &.inner-bottom {
    margin: 35px auto 0;
  }
}



.pg-front {
  position: relative;
  background: $clean;
}


.side-icon {
  float: left;
  width: 18%;
  margin-right: 15px;
}

.right-inner {
  margin: 30px auto;
}

.media-listing {
   li {
     display: block;

     > a {
       padding: 10px 20px;
       display: block;

       &:hover {
         background: rgba(0,0,0, .045);
         color: $red-brand;
       }
     }
   }
}




.form-group.inner-bottom {
    .btn.btn-default {
      min-width: 100px;
    }
}

.right-box {
  &.has-icon {
    .side-media {
      padding-left: 55px;
    }
  }

}

.smt-form {
  .form-group {
    padding-bottom: 15px;
    border-bottom: 1px solid #f3f3f3;

    &.clean {
      border-bottom: none;
    }
  }
}

.title-icon {
  float: left;
  background: #fff;
  width: 55px;
  height: 55px;
  color: $red-brand;
  line-height: 55px;
  font-size: 34px;
  text-align: center;
  margin-right: 15px;
}

.people-thumb {
  width: 20%;
  height: 315px;
  background: #086e97;

  img {
    width: 100%;
  }
}

.category-title h3 {
  color: $dark-brand;
}

.category-title:after {
  background: $dark-brand;
}

.overlay-show {
  background: rgba(228,0,43, .9);
  color: $clean;
}

.bottom-offer, .bottom-button {
  padding: 55px 20px;
}

.thumb-overlay i {
  font-size: 55px;
  margin: 25% auto 0;
}

.action-description {
  color: $clean;
}

.smt-form .form-group {
  margin: 0;
  padding: 10px 0;

  &:nth-child(2n+1) {
    background: #f9f9f9;
  }
}

.smt-form {
  .inner-bottom {
    &.clean {
      .btn.btn-default {
        min-width: 115px;
        margin-top: 35px;
      }
    }
  }
}


.grid-custom {
  max-width: 100%;
  margin: 0 auto;

  .smt-list.half .smt-half {
    width: 48%;
  }
}

.center-tagline {
  &.no-margin {
    margin: 0 auto 35px;
  }
}

.research-content {
  text-align: center;
  margin-bottom: 30px;
}

.research-icon {
  display: block;
  text-align: center;
  font-size: 48px;
  margin: 0 auto;
  background: #e0e0e0;
  width: 110px;
  height: 110px;
  border-radius: 50%;

  i {
    line-height: 110px;
    color: $text-color;
  }
}

.research-content h4 {
  text-align: center;
  margin-top: 30px;
}

.demograph-content {
  background: #f9f9f9;
  padding-bottom: 55px;
}

.state-wide {
  .center-tagline h4 {
    max-width: 100%;
  }
}

.chart-holder  {
  margin: 0 auto;
  text-align: center;
  position: relative;
  width: 300px;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
}

.chart-legend {

  li {
    display: block;
    margin-bottom: 15px;
  }

  p {
    display: inline-block;
    padding-left: 10px;
    margin: 0;
  }

  .box-count {
    display: inline-block;
    vertical-align: middle;
    width: 55px;
    height: 55px;
    text-align: center;
    font-size: 18px;
    line-height: 55px;
    background: #e0e0e0;
    border-radius: 2px;
    color: #333;

    &.other {
      background: #4D5360;
      color: #fff;
    }



    &.dark {
      background: #2D2926;
      color: #fff;
    }
    &.democrat {
      background: #086E97;
      color: #fff;
    }

    &.republic {
      background: $red-brand;
      color: #fff;
    }
  }


}

.middle-inner {
  h4.panel-title {
    font-size: initial;
  }
}

.middle-inner {
  .panel-default {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.faq-section {
  margin: 55px auto 0;
  margin-bottom: 0;

  .panel-group {
  .panel + .panel {
    margin-top: 20px;
  }
  }
}

.help-start {
  background: #fafafa;
}

.top-cities-entry .page-title {
  font-size: 36px;
}

.short-description {
  height: 54px;
}



.infeature-content .fourth-section {
  margin-bottom: 30px;
}

.data-list.clearfix h6 {
  margin-top: 3px;
}

.gridCustom .detail-city {
  margin-bottom: 10px;
}

.btn-mobile {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-transform: capitalize;
  text-align: center;
  padding: 0;
  line-height: 33px;
  padding: 0 15px;
  border-radius: 2px;
  margin: 0 auto;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 0 25px;
  margin-top: 20px;
  margin-bottom: 35px;
}

.btn-mobile-show:hover {
  background: white;
  color: #e4002b;
}

.btn-people-show {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-transform: capitalize;
  text-align: center;
  padding: 0;
  line-height: 33px;
  padding: 0 15px;
  border-radius: 2px;
  margin: 0 auto;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 0 25px;
  margin-top: 20px;
}

.btn-people-show:hover {
  background: white;
  color: #e4002b;
}

.description-box .right-description a {
  color: #fff;
}

.description-box .right-description a:hover {
  color: #2D2926;
}

.chevron-mobile {
  display: none;
}

.search-mobile,
.login-mobile,
.faq-mobile,
.btn-people-show,
.gridCustom.is-actived,
.top-cities-entry .btn-mobile {
  display: none;
}


.plan-description .ted-table th {
  text-align: center;
}

.plan-description .ted-table td i {
  display: block;
  text-align: center;
}

.plan-description .ted-table td:first-child:before,
.plan-description .ted-table td:nth-of-type(2):before{
  display: none;
}

.plan-description .ted-table tr:nth-of-type(2n+1) {
  background: #fafafa;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}






