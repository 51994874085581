/**
 * Basic typography style for copy text
 */

html {
  text-rendering: optimizeLegibility;
  font-size: 62.5%;
}



body {
  font: normal 1.4rem / 24px $body-text;
  color: $text-color;

}

p strong {
  font-family: $body-text;
  font-weight: 600;
}

a {
  color: $bold-blue;
}
a:hover,
a:focus {
  color: $lite-blue;
}
a:focus,
.btn:focus,
button:focus {
  outline: none;

}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
.btn,
.btn-default {
  -webkit-transition-property: color, background;
 -webkit-transition-duration: .1s, .1s;
 -webkit-transition-timing-function: linear, ease-in;
 -moz-transition-property: color, background;
 -moz-transition-duration: .1s, .1s;
 -moz-transition-timing-function: linear, ease-in;
 transition-property: color, background;
 transition-duration: .1s, .1s;
 transition-timing-function: linear, ease-in;
}

.page-title {
  color: $title-color;
  font-family: $title-text;
}

.big-display {
  font-family: $text-display;
  font-size: 11.2rem;
  line-height: 11.2rem;
}

.medium-display {
  font-family: $text-display;
  font-size: 5.6rem;
  line-height: 5.6rem;
}

.display {
  font-family: $text-display;
  font-size: 4.5rem;
  line-height: 4.8rem;
}

.headline,
h1 {
  font-family: $text-display;
  font-size: 3.4rem;
  line-height: 4rem;
}

.title,
h2 {
  font-family: $text-display;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.sub-title,
h3 {
  font-family: $text-display;
  font-size: 2rem;
  line-height: 2.8rem;
}

h4 {
  font-family: $text-display;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

h5 {
   font-family: $text-display;
  font-size: 1.4rem;
  line-height: 2.4rem;
}

h6 {
   font-family: $text-display;
  font-size: 1.2rem;
  line-height: 1.8rem;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $text-display;
  font-weight: 800;
  color: inherit;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span,
.h1 span,
.h2 span,
.h3 span,
.h4 span,
.h5 span,
.h6 span {
  font-family: $text-display;
  font-weight: bold;

  color: inherit;
}

input,
button,
select,
textarea,
input[type=password],
input[type=text],
input[type=date],
input[type=email],
input[type=number]{
  font-family: $body-text;
  font-weight: 300;
  font-size: inherit;
  line-height: inherit;
}

input,
button,
select,
textarea,
body {  -webkit-font-smoothing: antialiased; }

input,
select,
textarea {

  font-family: $body-text;
  font-weight: 300;

}
