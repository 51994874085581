// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.main-header {
  background: $clean;
  position:fixed;
  top:0;
  width: 100%;
  z-index: 3;





  .navbar-brand {
    height: auto;
    padding: 0;
  }

  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    color: $red-brand;
  }



  .nav {
    &.navbar-nav {

      float: right;

      &li > a {
        padding: 10px 15px;
      }
    }
  }

  .navbar-nav > li > a {
    padding: 5px 15px;
  }

  .navbar-default {
    background: transparent;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }

}

.navbar-nav a.active {
  color: $red-brand;
}

.main-nav .navbar-nav a.active {
  background: $red-brand;
  color: #fff;
  position: relative;
}

.main-nav .navbar-nav > li > a.active {
  color: #fff;
}

.top-nav {
  background: transparent;
  line-height: 28px;

  .btn-group {
    text-align: right;
    float: right;
    background: $red-brand;
    color: #fff;

    a {
      color: #fff;
      padding: 4px 10px;
      cursor: pointer;

      &:hover {
        color: $clean;

      }
    }

    .dropdown {
      display: inline-block;

      button {
        font-weight: normal;
        background: transparent;
        border: none;
        color: $clean;
        box-shadow: none;
      }
    }
  }
}

.main-nav {
  box-shadow: 0 4px 4px rgba(0,0,0,0.06);
  border-bottom: 1px solid #ccc;
  background: $clean;
  z-index: 1;
  transition: transform 0.2s ease-in-out;


}


.main-header.smt-stick {
  .navbar-nav {
    a {
      padding: 0 20px;
      line-height: 64px;

      &:hover {
        background: rgba(0,0,0, .025);
        color: $red-brand;
      }

    }
  }
}

.main-header {
  .form-control {
    box-shadow: none;
  }
}

.search-container {
  background: #f9f9f9;
}

.scrollToTop i {
  color: $clean;
}

.main-nav .navbar-nav > li > a.active {
  color: $clean;
}

.main-nav.smt-stick .navbar-nav > li > a.active:hover {
  color: #333;
  background: #f9f9f9;
}

.main-nav .navbar-nav > li > a {
  padding: 0 20px;
}




.main-nav {
  .has-icon {

    i {
      font-size: 28px;
    }
  }
}
.has-icon {

  i {
    font-size: 28px;
  }
}

.main-nav .navbar-nav a {
  padding: 0 20px;
  line-height: 60px;
}

a.has-icon,
.main-nav a.has-icon{
  line-height: 55px;
  padding: 0 20px;
}

.main-nav .navbar-nav > li > a {
  padding: 0 20px;
}

.btn-account {
  background: transparent;
  border-radius: 0;
  padding: 0 20px;
  line-height: 55px;
  font-size: 24px;
  box-shadow: none;
  outline: none;
  border: none;
}

.main-nav {
  .dropdown-menu {
    margin: 6px 0 0;

    a {
      line-height: inherit;
    }
  }

}

.cities-inner {
  .form-title {
    padding: 10px 0;
    h3 {
      font-size: 24px;
    }
  }
}







