

%typl8-amp-placeholder {
  @include typl8-ampersand($typl8-amp-fontface, $typl8-amp-fontfamily);
}



%typl8-cite {
  @include typl8-cite-style($typl8-cite-display, $typl8-cite-align, $typl8-cite-font-size);
}




%typl8-breakword {
  word-wrap: break-word;
}

%typl8-normal-wrap {
  word-wrap: normal;
}

%typl8-inherit-wrap {
  word-wrap: auto;
}




%typl8-ot--smcp {
  @each $prefix in $typl8-prefixes {
    #{$prefix}font-feature-settings: "smcp";
  }
}

%typl8-ot--onum {
  @each $prefix in $typl8-prefixes {
    #{$prefix}font-feature-settings: 'onum';
  }
}
