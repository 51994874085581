// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}


.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.no-padding {
  padding: 0 !important;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}


fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}


textarea {
  resize: vertical;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.left { float: left; }
.right { float: right; }
.fit { max-width: 100%; }
.half-width { width: 50% }
.full-width { width: 100%; }
.full-height { height: 100%; }
.fixed { position: fixed; }
.relative { position: relative; }
.absolute { position: absolute; }
.static { position: static; }
.pin-top-right {
  top: 0;
  right: 0;
}

.pin-bottom-right {
  bottom: 0;
  right: 0;
}

.align-top { vertical-align: top; }
.align-bottom { vertical-align: bottom; }
.align-middle { vertical-align: middle; }

.hide-tablet,
.hide-mobile { display:block; }
.show-tablet,
.show-mobile { display:none; }
.show-mobile { display:none; }

.ell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-word { word-wrap: break-word; }
.no-wrap    { white-space: nowrap; }

.scroll-h { overflow-x:auto; }
.scroll-v { overflow-y:auto; }
.z1   { z-index:1; }
.z10  { z-index:10; }
.z100 { z-index:100; }

.hidden { display: none;}

.micro {
  font-size:10px;
  line-height:20px;
  letter-spacing:0;
}

*[hidden] { display: none; }

.text-divider {
  display: block;
}

.side-box {
  .box-content {
    background: $brand-color;
    color: $clean;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &:hover {
      background: $clean;
      color: $brand-color;
      box-shadow: 0 3px 8px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.12);
    }
  }
}

.cities-inner {
  &.hidden {
    visibility: hidden;
    padding: 0;
  }
}

.slimScrollBar {
  z-index: 1 !important;
}

.feed-item img {
  display: block;
  min-height: 170px;
}





