// --------------------------
// it for third party styles
// --------------------------
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
   * Header and footer styles
   */
  /*
   * Body styles
   */
  thead th, tfoot th {
    font-weight: bold;
  }
  thead {
    th, td {
      background: #2D2926;
      padding: 10px;
      border-bottom: 1px solid #121212;
      color: #fff;
      font-weight: normal;
      letter-spacing: .5px;
    }
    th:active, td:active {
      outline: none;
    }
  }
  tfoot {
    th, td {
      padding: 10px 18px 6px 18px;
      border-top: 1px solid #f0f0f0;
    }
  }
  thead {
    .sorting, .sorting_asc, .sorting_desc {
      cursor: pointer;
      *cursor: hand;
    }
    .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
      background-repeat: no-repeat;
      background-position: center right;
    }
  }
  tbody {
    tr {
      background-color: #ffffff;
      &.selected {
        background-color: #f9f9f9;
      }
    }
    th, td {
      padding: 8px 10px;
    }
  }
  &.row-border tbody {
    th, td {
      border-top: 1px solid #f3f3f3;
    }
  }
  &.display tbody {
    th, td {
      border-top: 1px solid #f3f3f3;
    }
  }
  &.row-border tbody tr:first-child {
    th, td {
      border-top: none;
    }
  }
  &.display tbody tr:first-child {
    th, td {
      border-top: none;
    }
  }
  &.cell-border tbody {
    th, td {
      border-top: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
    }
    tr {
      th:first-child, td:first-child {
        border-left: 1px solid #f3f3f3;
      }
      &:first-child {
        th, td {
          border-top: none;
        }
      }
    }
  }
  &.stripe tbody tr.odd, &.display tbody tr.odd {
    background-color: #f9f9f9;
  }
  &.stripe tbody tr.odd.selected, &.display tbody tr.odd.selected {
    background-color: #e0e0e0;
  }
  &.hover tbody tr:hover, &.display tbody tr:hover {
    background-color: #f5f5f5;
  }
  &.hover tbody tr:hover.selected, &.display tbody tr:hover.selected {
    background-color: #e0e0e0;
  }
  &.order-column tbody tr > {
    .sorting_1, .sorting_2, .sorting_3 {
      background-color: #fafafa;
    }
  }
  &.display tbody tr > {
    .sorting_1, .sorting_2, .sorting_3 {
      background-color: #fafafa;
    }
  }
  &.order-column tbody tr.selected > {
    .sorting_1, .sorting_2, .sorting_3 {
      background-color: #f9f9f9;
    }
  }
  &.display tbody tr {
    &.selected > {
      .sorting_1, .sorting_2, .sorting_3 {
        background-color: #f9f9f9;
      }
    }
    &.odd > .sorting_1 {}
  }
  &.order-column.stripe tbody tr.odd > .sorting_1, &.display tbody tr.odd > .sorting_2, &.order-column.stripe tbody tr.odd > .sorting_2 {}
  &.display tbody tr.odd > .sorting_3, &.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke;
  }
  &.display tbody tr.odd.selected > .sorting_1, &.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b4cd;
  }
  &.display tbody tr.odd.selected > .sorting_2, &.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a8b5cf;
  }
  &.display tbody tr.odd.selected > .sorting_3, &.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b7d1;
  }
  &.display tbody tr.even > .sorting_1, &.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #fafafa;
  }
  &.display tbody tr.even > .sorting_2, &.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fcfcfc;
  }
  &.display tbody tr.even > .sorting_3, &.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fefefe;
  }
  &.display tbody tr.even.selected > .sorting_1, &.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad5;
  }
  &.display tbody tr.even.selected > .sorting_2, &.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #aebcd6;
  }
  &.display tbody tr.even.selected > .sorting_3, &.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8;
  }
  &.display tbody tr:hover > .sorting_1, &.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #f3f3f3;
  }
  &.display tbody tr:hover > .sorting_2, &.order-column.hover tbody tr:hover > .sorting_2 {}
  &.display tbody tr:hover > .sorting_3, &.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #efefef;
  }
  &.display tbody tr:hover.selected > .sorting_1, &.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #a2aec7;
  }
  &.display tbody tr:hover.selected > .sorting_2, &.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #a3b0c9;
  }
  &.display tbody tr:hover.selected > .sorting_3, &.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #a5b2cb;
  }
  &.no-footer {
    border-bottom: 1px solid #2D2926;
  }
  &.nowrap {
    th, td {
      white-space: nowrap;
    }
  }
  &.compact {
    thead {
      th, td {
        padding: 4px 17px 4px 4px;
      }
    }
    tfoot {
      th, td {
        padding: 4px;
      }
    }
    tbody {
      th, td {
        padding: 4px;
      }
    }
  }
  th.dt-left, td.dt-left {
    text-align: left;
  }
  th.dt-center {
    text-align: center;
  }
  td {
    &.dt-center, &.dataTables_empty {
      text-align: center;
    }
  }
  th.dt-right, td.dt-right {
    text-align: right;
  }
  th.dt-justify, td.dt-justify {
    text-align: justify;
  }
  th.dt-nowrap, td.dt-nowrap {
    white-space: nowrap;
  }
  thead {
    th.dt-head-left, td.dt-head-left {
      text-align: left;
    }
  }
  tfoot {
    th.dt-head-left, td.dt-head-left {
      text-align: left;
    }
  }
  thead {
    th.dt-head-center, td.dt-head-center {
      text-align: center;
    }
  }
  tfoot {
    th.dt-head-center, td.dt-head-center {
      text-align: center;
    }
  }
  thead {
    th.dt-head-right, td.dt-head-right {
      text-align: right;
    }
  }
  tfoot {
    th.dt-head-right, td.dt-head-right {
      text-align: right;
    }
  }
  thead {
    th.dt-head-justify, td.dt-head-justify {
      text-align: justify;
    }
  }
  tfoot {
    th.dt-head-justify, td.dt-head-justify {
      text-align: justify;
    }
  }
  thead {
    th.dt-head-nowrap, td.dt-head-nowrap {
      white-space: nowrap;
    }
  }
  tfoot {
    th.dt-head-nowrap, td.dt-head-nowrap {
      white-space: nowrap;
    }
  }
  tbody {
    th.dt-body-left, td.dt-body-left {
      text-align: left;
    }
    th.dt-body-center, td.dt-body-center {
      text-align: center;
    }
    th.dt-body-right, td.dt-body-right {
      text-align: right;
    }
    th.dt-body-justify, td.dt-body-justify {
      text-align: justify;
    }
    th.dt-body-nowrap, td.dt-body-nowrap {
      white-space: nowrap;
    }
  }
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  th, td {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

/*table.dataTable thead .sorting {
  background-image: url("../images/sort_both.png");
}
table.dataTable thead .sorting_asc {
  background-image: url("../images/sort_asc.png");
}
table.dataTable thead .sorting_desc {
  background-image: url("../images/sort_desc.png");
}
table.dataTable thead .sorting_asc_disabled {
  background-image: url("../images/sort_asc_disabled.png");
}
table.dataTable thead .sorting_desc_disabled {
  background-image: url("../images/sort_desc_disabled.png");
}*/

/*
 * Control feature layout
 */

.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
  .dataTables_length {
    float: left;
  }
  .dataTables_filter {
    float: right;
    text-align: right;
    input {
      margin-left: 0.5em;
    }
  }
  .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
  }
  .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
    .paginate_button {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1.5em;
      padding: 0.5em 1em;
      margin-left: 2px;
      text-align: center;
      text-decoration: none !important;
      cursor: pointer;
      *cursor: hand;
      color: #333 !important;
      border: 1px solid transparent;
      border-radius: 2px;
      &.current {
        color: #fff !important;
        border: 1px solid #1283B1;
        background-color: #1283B1;
        &:hover {
          color: #fff !important;
          border: 1px solid #1283B1;
          background-color: #1283B1;
        }
      }
      &.disabled {
        cursor: default;
        color: #666 !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none;
        &:hover, &:active {
          cursor: default;
          color: #666 !important;
          border: 1px solid transparent;
          background: transparent;
          box-shadow: none;
        }
      }
      &:hover {
        color: #fff !important;
      }
      &:active {
        outline: none;
      }
    }
    .ellipsis {
      padding: 0 1em;
    }
  }
  .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  }
  .dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_processing, .dataTables_paginate {
    color: #333;
  }
  .dataTables_scroll {
    clear: both;
    div.dataTables_scrollBody {
      *margin-top: -1px;
      -webkit-overflow-scrolling: touch;
      th, td {
        vertical-align: middle;
      }
      th > div.dataTables_sizing, td > div.dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  &.no-footer {
    .dataTables_scrollBody {
      border-bottom: 1px solid #111;
    }
    div {
      &.dataTables_scrollHead table, &.dataTables_scrollBody table {
        border-bottom: none;
      }
    }
  }
  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper {
    .dataTables_info {
      float: none;
      text-align: center;
    }
    .dataTables_paginate {
      float: none;
      text-align: center;
      margin-top: 0.5em;
    }
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper {
    .dataTables_length {
      float: none;
      text-align: center;
    }
    .dataTables_filter {
      float: none;
      text-align: center;
      margin-top: 0.5em;
    }
  }
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  td, th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  td.dataTables_empty, th.dataTables_empty {
    text-align: center;
  }
  &.nowrap {
    th, td {
      white-space: nowrap;
    }
  }
}

div.dataTables_wrapper div {
  &.dataTables_length {
    label {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
    }
    select {
      width: 75px;
      display: inline-block;
    }
  }
  &.dataTables_filter {
    text-align: right;
    label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
    }
    input {
      margin-left: 0.5em;
      display: inline-block;
      width: auto;
    }
  }
  &.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
    background: #f0f0f0;
    padding: 10px 20px;
    border-radius: 55px;
    margin-top: 20px;
    font-size: 13px;
  }
  &.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
    ul.pagination {
      margin: 10px 0;
      white-space: nowrap;
    }
  }
  &.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }
}

table.dataTable thead {
  > tr > {
    th {
      &.sorting_asc, &.sorting_desc, &.sorting {
        padding-right: 30px;
      }
    }
    td {
      &.sorting_asc, &.sorting_desc, &.sorting {
        padding-right: 30px;
      }
    }
    th:active, td:active {
      outline: none;
    }
  }
  .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
  }
  .sorting:after, .sorting_asc:after, .sorting_desc:after, .sorting_asc_disabled:after, .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: 'Material Design Icons';
    opacity: 0.5;
  }
  .sorting:after {
    opacity: 0.2;
    content: "\F4BF";
    /* sort */
  }
  .sorting_asc:after {
    content: "\F4BC";
    /* sort-by-attributes */
  }
  .sorting_desc:after {
    content: "\F4BD";
    /* sort-by-attributes-alt */
  }
  .sorting_asc_disabled:after, .sorting_desc_disabled:after {
    color: #e0e0e0;
  }
}

div {
  &.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
  }
  &.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    thead {
      .sorting:after, .sorting_asc:after, .sorting_desc:after {
        display: none;
      }
    }
    tbody tr:first-child {
      th, td {
        border-top: none;
      }
    }
  }
  &.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div {
    &.dataTables_length, &.dataTables_filter, &.dataTables_info, &.dataTables_paginate {
      text-align: center;
    }
  }
}

table {
  &.dataTable.table-condensed {
    > thead > tr > th {
      padding-right: 20px;
    }
    .sorting:after, .sorting_asc:after, .sorting_desc:after {
      top: 6px;
      right: 6px;
    }
  }
  &.table-bordered.dataTable {
    th, td {
      border-left-width: 0;
    }
    th:last-child, td:last-child {
      border-right-width: 0;
    }
    tbody {
      th, td {
        border-bottom-width: 0;
      }
    }
  }
}

div {
  &.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
  }
  &.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0;
    > div[class^="col-"] {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.pagination > {
  li {
    > a {
      border-radius: 35px;
    }
    &:first-child > {
      a, span {
        border-radius: 35px;
      }
    }
    &:last-child > {
      a, span {
        border-radius: 35px;
      }
    }
  }
  .active > {
    a {
      background: #086E97;
      border-color: #086E97;
      &:hover, &:focus {
        background: #086E97;
        border-color: #086E97;
      }
    }
    span {
      background: #086E97;
      border-color: #086E97;
      &:hover, &:focus {
        background: #086E97;
        border-color: #086E97;
      }
    }
  }
}


// select2 style overide

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 36px;
    user-select: none;
    -webkit-user-select: none;
    .select2-selection__rendered {
      display: block;
      padding-left: 10px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
    .select2-selection__clear {
      position: relative;
    }
  }
  &[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 10px;
    padding-left: 20px;
  }
  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .select2-search--inline {
    float: left;
    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0;
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }
}

.select2-dropdown {
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 2px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open {
  .select2-dropdown {
    left: 0;
  }
  .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default {
  .select2-selection--single {
    background-color: #fff;
    border: 1px solid #f3f3f3;
    border-radius: 2px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.022), 0px 1px 2px rgba(0, 0, 0, 0.1);
    .select2-selection__rendered {
      color: #555;
      line-height: 34px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }
    .select2-selection__placeholder {
      color: #808080;
    }
    .select2-selection__arrow {
      height: 30px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 28px;
      b {
        border-color: $border-color transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
  &[dir="rtl"] .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
  &.select2-container--disabled .select2-selection--single {
    background-color: #f3f3f3;
    cursor: default;
    .select2-selection__clear {
      display: none;
    }
  }
  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
  }
  .select2-selection--multiple {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 2px;
    cursor: text;
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
      li {
        list-style: none;
      }
    }
    .select2-selection__placeholder {
      color: #808080;
      margin-top: 5px;
      float: left;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: 5px;
      margin-right: 10px;
    }
    .select2-selection__choice {
      background-color: #f3f3f3;
      border: 1px solid $border-color;
      border-radius: 2px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }
    .select2-selection__choice__remove {
      color: #999;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        color: #333;
      }
    }
  }
  &[dir="rtl"] .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: right;
    }
    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
  &.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
  }
  &.select2-container--disabled {
    .select2-selection--multiple {
      background-color: #f3f3f3;
      cursor: default;
    }
    .select2-selection__choice__remove {
      display: none;
    }
  }
  &.select2-container--open {
    &.select2-container--above {
      .select2-selection--single, .select2-selection--multiple {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &.select2-container--below {
      .select2-selection--single, .select2-selection--multiple {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid $border-color;
  }
  .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield;
  }
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  .select2-results__option {
    &[role=group] {
      padding: 0;
    }
    &[aria-disabled=true] {
      color: #999;
    }
    &[aria-selected=true] {
      background-color: $border-color;
    }
    .select2-results__option {
      padding-left: 1em;
      .select2-results__group {
        padding-left: 0;
      }
      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;
        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;
          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;
            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;
              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #f3f3f3;
    color: #555;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}

.select2-container--classic {
  .select2-selection--single {
    background-color: #f9f9f9;
    border: 1px solid $border-color;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, white 50%, #f3f3f3 100%);
    background-image: -o-linear-gradient(top, white 50%, #f3f3f3 100%);
    background-image: linear-gradient(to bottom, white 50%, #f3f3f3 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
    &:focus {
      border: 1px solid $bold-blue;
    }
    .select2-selection__rendered {
      color: #555;
      line-height: 34px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px;
    }
    .select2-selection__placeholder {
      color: #808080;
    }
    .select2-selection__arrow {
      background-color: $bold-blue;
      border: none;
      border-left: 1px solid $bold-blue;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 34px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      background-image: -webkit-linear-gradient(top, #f3f3f3 50%, #d5d5d5 100%);
      background-image: -o-linear-gradient(top, #f3f3f3 50%, #d5d5d5 100%);
      background-image: linear-gradient(to bottom, #f3f3f3 50%, #d5d5d5 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
      b {
        border-color: #808080 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
  &[dir="rtl"] .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      border: none;
      border-right: 1px solid $bold-blue;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 1px;
      right: auto;
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      border: 1px solid $bold-blue;
      .select2-selection__arrow {
        background: transparent;
        border: none;
        b {
          border-color: transparent transparent #888 transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }
    &.select2-container--above .select2-selection--single {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-image: -webkit-linear-gradient(top, white 0%, #f3f3f3 50%);
      background-image: -o-linear-gradient(top, white 0%, #f3f3f3 50%);
      background-image: linear-gradient(to bottom, white 0%, #f3f3f3 50%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
    }
    &.select2-container--below .select2-selection--single {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-image: -webkit-linear-gradient(top, #f3f3f3 50%, white 100%);
      background-image: -o-linear-gradient(top, #f3f3f3 50%, white 100%);
      background-image: linear-gradient(to bottom, #f3f3f3 50%, white 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
    }
  }
  .select2-selection--multiple {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 4px;
    cursor: text;
    outline: 0;
    &:focus {
      border: 1px solid $bold-blue;
    }
    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px;
    }
    .select2-selection__clear {
      display: none;
    }
    .select2-selection__choice {
      background-color: #f3f3f3;
      border: 1px solid $border-color;
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }
    .select2-selection__choice__remove {
      color: #888;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        color: #555;
      }
    }
  }
  &[dir="rtl"] .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
  &.select2-container--open {
    .select2-selection--multiple {
      border: 1px solid $bold-blue;
    }
    &.select2-container--above .select2-selection--multiple {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &.select2-container--below .select2-selection--multiple {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid $border-color;
    outline: 0;
  }
  .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none;
  }
  .select2-dropdown {
    background-color: white;
    border: 1px solid transparent;
  }
  .select2-dropdown--above {
    border-bottom: none;
  }
  .select2-dropdown--below {
    border-top: none;
  }
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  .select2-results__option {
    &[role=group] {
      padding: 0;
    }
    &[aria-disabled=true] {
      color: grey;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $lite-blue;
    color: white;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
  &.select2-container--open .select2-dropdown {
    border-color: $bold-blue;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12);
  }
}

[data-aos][data-aos][data-aos-duration='50'], body[data-aos-duration='50'] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay='50'], body[data-aos-delay='50'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='50'].aos-animate, body[data-aos-delay='50'] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration='100'], body[data-aos-duration='100'] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay='100'], body[data-aos-delay='100'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='100'].aos-animate, body[data-aos-delay='100'] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration='150'], body[data-aos-duration='150'] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay='150'], body[data-aos-delay='150'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='150'].aos-animate, body[data-aos-delay='150'] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration='200'], body[data-aos-duration='200'] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay='200'], body[data-aos-delay='200'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='200'].aos-animate, body[data-aos-delay='200'] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration='250'], body[data-aos-duration='250'] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay='250'], body[data-aos-delay='250'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='250'].aos-animate, body[data-aos-delay='250'] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration='300'], body[data-aos-duration='300'] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay='300'], body[data-aos-delay='300'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='300'].aos-animate, body[data-aos-delay='300'] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration='350'], body[data-aos-duration='350'] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay='350'], body[data-aos-delay='350'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='350'].aos-animate, body[data-aos-delay='350'] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration='400'], body[data-aos-duration='400'] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay='400'], body[data-aos-delay='400'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='400'].aos-animate, body[data-aos-delay='400'] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration='450'], body[data-aos-duration='450'] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay='450'], body[data-aos-delay='450'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='450'].aos-animate, body[data-aos-delay='450'] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration='500'], body[data-aos-duration='500'] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay='500'], body[data-aos-delay='500'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='500'].aos-animate, body[data-aos-delay='500'] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration='550'], body[data-aos-duration='550'] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay='550'], body[data-aos-delay='550'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='550'].aos-animate, body[data-aos-delay='550'] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration='600'], body[data-aos-duration='600'] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay='600'], body[data-aos-delay='600'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='600'].aos-animate, body[data-aos-delay='600'] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration='650'], body[data-aos-duration='650'] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay='650'], body[data-aos-delay='650'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='650'].aos-animate, body[data-aos-delay='650'] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration='700'], body[data-aos-duration='700'] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay='700'], body[data-aos-delay='700'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='700'].aos-animate, body[data-aos-delay='700'] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration='750'], body[data-aos-duration='750'] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay='750'], body[data-aos-delay='750'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='750'].aos-animate, body[data-aos-delay='750'] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration='800'], body[data-aos-duration='800'] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay='800'], body[data-aos-delay='800'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='800'].aos-animate, body[data-aos-delay='800'] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration='850'], body[data-aos-duration='850'] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay='850'], body[data-aos-delay='850'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='850'].aos-animate, body[data-aos-delay='850'] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration='900'], body[data-aos-duration='900'] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay='900'], body[data-aos-delay='900'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='900'].aos-animate, body[data-aos-delay='900'] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration='950'], body[data-aos-duration='950'] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay='950'], body[data-aos-delay='950'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='950'].aos-animate, body[data-aos-delay='950'] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration='1000'], body[data-aos-duration='1000'] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay='1000'], body[data-aos-delay='1000'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1000'].aos-animate, body[data-aos-delay='1000'] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration='1050'], body[data-aos-duration='1050'] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay='1050'], body[data-aos-delay='1050'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1050'].aos-animate, body[data-aos-delay='1050'] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration='1100'], body[data-aos-duration='1100'] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay='1100'], body[data-aos-delay='1100'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1100'].aos-animate, body[data-aos-delay='1100'] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration='1150'], body[data-aos-duration='1150'] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay='1150'], body[data-aos-delay='1150'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1150'].aos-animate, body[data-aos-delay='1150'] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration='1200'], body[data-aos-duration='1200'] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay='1200'], body[data-aos-delay='1200'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1200'].aos-animate, body[data-aos-delay='1200'] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration='1250'], body[data-aos-duration='1250'] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay='1250'], body[data-aos-delay='1250'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1250'].aos-animate, body[data-aos-delay='1250'] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration='1300'], body[data-aos-duration='1300'] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay='1300'], body[data-aos-delay='1300'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1300'].aos-animate, body[data-aos-delay='1300'] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration='1350'], body[data-aos-duration='1350'] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay='1350'], body[data-aos-delay='1350'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1350'].aos-animate, body[data-aos-delay='1350'] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration='1400'], body[data-aos-duration='1400'] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay='1400'], body[data-aos-delay='1400'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1400'].aos-animate, body[data-aos-delay='1400'] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration='1450'], body[data-aos-duration='1450'] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay='1450'], body[data-aos-delay='1450'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1450'].aos-animate, body[data-aos-delay='1450'] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration='1500'], body[data-aos-duration='1500'] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay='1500'], body[data-aos-delay='1500'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1500'].aos-animate, body[data-aos-delay='1500'] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration='1550'], body[data-aos-duration='1550'] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay='1550'], body[data-aos-delay='1550'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1550'].aos-animate, body[data-aos-delay='1550'] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration='1600'], body[data-aos-duration='1600'] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay='1600'], body[data-aos-delay='1600'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1600'].aos-animate, body[data-aos-delay='1600'] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration='1650'], body[data-aos-duration='1650'] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay='1650'], body[data-aos-delay='1650'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1650'].aos-animate, body[data-aos-delay='1650'] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration='1700'], body[data-aos-duration='1700'] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay='1700'], body[data-aos-delay='1700'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1700'].aos-animate, body[data-aos-delay='1700'] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration='1750'], body[data-aos-duration='1750'] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay='1750'], body[data-aos-delay='1750'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1750'].aos-animate, body[data-aos-delay='1750'] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration='1800'], body[data-aos-duration='1800'] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay='1800'], body[data-aos-delay='1800'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1800'].aos-animate, body[data-aos-delay='1800'] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration='1850'], body[data-aos-duration='1850'] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay='1850'], body[data-aos-delay='1850'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1850'].aos-animate, body[data-aos-delay='1850'] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration='1900'], body[data-aos-duration='1900'] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay='1900'], body[data-aos-delay='1900'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1900'].aos-animate, body[data-aos-delay='1900'] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration='1950'], body[data-aos-duration='1950'] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay='1950'], body[data-aos-delay='1950'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='1950'].aos-animate, body[data-aos-delay='1950'] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration='2000'], body[data-aos-duration='2000'] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay='2000'], body[data-aos-delay='2000'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2000'].aos-animate, body[data-aos-delay='2000'] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration='2050'], body[data-aos-duration='2050'] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay='2050'], body[data-aos-delay='2050'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2050'].aos-animate, body[data-aos-delay='2050'] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration='2100'], body[data-aos-duration='2100'] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay='2100'], body[data-aos-delay='2100'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2100'].aos-animate, body[data-aos-delay='2100'] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration='2150'], body[data-aos-duration='2150'] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay='2150'], body[data-aos-delay='2150'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2150'].aos-animate, body[data-aos-delay='2150'] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration='2200'], body[data-aos-duration='2200'] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay='2200'], body[data-aos-delay='2200'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2200'].aos-animate, body[data-aos-delay='2200'] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration='2250'], body[data-aos-duration='2250'] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay='2250'], body[data-aos-delay='2250'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2250'].aos-animate, body[data-aos-delay='2250'] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration='2300'], body[data-aos-duration='2300'] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay='2300'], body[data-aos-delay='2300'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2300'].aos-animate, body[data-aos-delay='2300'] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration='2350'], body[data-aos-duration='2350'] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay='2350'], body[data-aos-delay='2350'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2350'].aos-animate, body[data-aos-delay='2350'] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration='2400'], body[data-aos-duration='2400'] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay='2400'], body[data-aos-delay='2400'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2400'].aos-animate, body[data-aos-delay='2400'] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration='2450'], body[data-aos-duration='2450'] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay='2450'], body[data-aos-delay='2450'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2450'].aos-animate, body[data-aos-delay='2450'] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration='2500'], body[data-aos-duration='2500'] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay='2500'], body[data-aos-delay='2500'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2500'].aos-animate, body[data-aos-delay='2500'] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration='2550'], body[data-aos-duration='2550'] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay='2550'], body[data-aos-delay='2550'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2550'].aos-animate, body[data-aos-delay='2550'] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration='2600'], body[data-aos-duration='2600'] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay='2600'], body[data-aos-delay='2600'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2600'].aos-animate, body[data-aos-delay='2600'] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration='2650'], body[data-aos-duration='2650'] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay='2650'], body[data-aos-delay='2650'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2650'].aos-animate, body[data-aos-delay='2650'] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration='2700'], body[data-aos-duration='2700'] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay='2700'], body[data-aos-delay='2700'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2700'].aos-animate, body[data-aos-delay='2700'] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration='2750'], body[data-aos-duration='2750'] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay='2750'], body[data-aos-delay='2750'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2750'].aos-animate, body[data-aos-delay='2750'] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration='2800'], body[data-aos-duration='2800'] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay='2800'], body[data-aos-delay='2800'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2800'].aos-animate, body[data-aos-delay='2800'] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration='2850'], body[data-aos-duration='2850'] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay='2850'], body[data-aos-delay='2850'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2850'].aos-animate, body[data-aos-delay='2850'] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration='2900'], body[data-aos-duration='2900'] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay='2900'], body[data-aos-delay='2900'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2900'].aos-animate, body[data-aos-delay='2900'] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration='2950'], body[data-aos-duration='2950'] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay='2950'], body[data-aos-delay='2950'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='2950'].aos-animate, body[data-aos-delay='2950'] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration='3000'], body[data-aos-duration='3000'] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay='3000'], body[data-aos-delay='3000'] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay='3000'].aos-animate, body[data-aos-delay='3000'] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
  &.aos-animate {
    opacity: 1;
    transform: translate(0);
  }
}

[data-aos=fade-up] {
  transform: translateY(100px);
}

[data-aos=fade-down] {
  transform: translateY(-100px);
}

[data-aos=fade-right] {
  transform: translate(-100px);
}

[data-aos=fade-left] {
  transform: translate(100px);
}

[data-aos=fade-up-right] {
  transform: translate(-100px, 100px);
}

[data-aos=fade-up-left] {
  transform: translate(100px, 100px);
}

[data-aos=fade-down-right] {
  transform: translate(-100px, -100px);
}

[data-aos=fade-down-left] {
  transform: translate(100px, -100px);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform;
  &.aos-animate {
    opacity: 1;
    transform: translate(0) scale(1);
  }
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translateY(100px) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translateY(-100px) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate(-100px) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate(100px) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translateY(100px) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translateY(-100px) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate(-100px) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate(100px) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
  &.aos-animate {
    transform: translate(0);
  }
}

[data-aos=slide-up] {
  transform: translateY(100%);
}

[data-aos=slide-down] {
  transform: translateY(-100%);
}

[data-aos=slide-right] {
  transform: translateX(-100%);
}

[data-aos=slide-left] {
  transform: translateX(100%);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
  &.aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
  &.aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
  &.aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
  &.aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}

/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */

.owl-carousel {
  .owl-wrapper {
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
  .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
    &.autoHeight {
      -webkit-transition: height 500ms ease-in-out;
      -moz-transition: height 500ms ease-in-out;
      -ms-transition: height 500ms ease-in-out;
      -o-transition: height 500ms ease-in-out;
      transition: height 500ms ease-in-out;
    }
  }
  .owl-item {
    float: left;
  }
}

/* display none until init */

.owl-controls {
  .owl-page, .owl-buttons div {
    cursor: pointer;
  }
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */

.grabbing {
  cursor: url(../images/grabbing.png) 8 8, move;
}

/* fix */

.owl-carousel {
  .owl-wrapper, .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }
}

/*
* 	Owl Carousel Owl Demo Theme
*	v1.3.3
*/

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center;
  .owl-buttons div {
    color: #FFF;
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
    margin: 5px;
    padding: 3px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: #869791;
    filter: Alpha(Opacity = 50);
    /*IE7 fix*/
    opacity: 0.5;
  }
  &.clickable .owl-buttons div:hover {
    filter: Alpha(Opacity = 100);
    /*IE7 fix*/
    opacity: 1;
    text-decoration: none;
  }
  .owl-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
    span {
      display: block;
      width: 12px;
      height: 12px;
      margin: 5px 7px;
      filter: Alpha(Opacity = 50);
      /*IE7 fix*/
      opacity: 0.5;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      background: #869791;
    }
    &.active span {
      filter: Alpha(Opacity = 100);
      /*IE7 fix*/
      opacity: 1;
    }
  }
  &.clickable .owl-page:hover span {
    filter: Alpha(Opacity = 100);
    /*IE7 fix*/
    opacity: 1;
  }
  .owl-page span.owl-numbers {
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }
}

/* Styling Next and Prev buttons */

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */

/* Styling Pagination*/

/* If PaginationNumbers is true */

/* preloading images */

.owl-item.loading {
  min-height: 150px;
  background: url(../images/AjaxLoader.gif) no-repeat center center;
}

/*
 *  Owl Carousel CSS3 Transitions
 *  v1.3.2
 */

.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective: 1200px;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
  perspective: 1200px;
}

/* fade */

.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease;
}

.owl-fade-in {
  -webkit-animation: fadeIn .7s both ease;
  -moz-animation: fadeIn .7s both ease;
  animation: fadeIn .7s both ease;
}

/* backSlide */

.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}

.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}

/* goDown */

.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both;
}

.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both;
}

/* scaleUp */

.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both;
}

/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
  0% {
    opacity: 1;
  }
}


@-moz-keyframes empty {
  0% {
    opacity: 1;
  }
}


@keyframes empty {
  0% {
    opacity: 1;
  }
}


@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }

  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }

  100% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
}


@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }

  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }

  100% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }
}


@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-500px);
  }

  75% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%);
  }

  100% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%);
  }
}


@-webkit-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(200%);
  }

  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
  }
}


@-moz-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(200%);
  }

  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0);
  }
}


@keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    transform: translateZ(-500px) translateX(200%);
  }

  75% {
    opacity: .5;
    transform: translateZ(-500px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}


@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}


@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(0.8);
  }
}


@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}


@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%);
  }
}


@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%);
  }
}


@keyframes goDown {
  from {
    transform: translateY(-100%);
  }
}


@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}


@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}


@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5);
  }
}


@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}


@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}


@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}
